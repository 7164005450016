import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIMailGruppi {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }



  async insertMailGruppi(mail_gruppi, id_group)
  {

    let formData = new FormData();

    formData.append("id_group",  id_group);

    for ( var key in mail_gruppi ) {
       formData.append(key, mail_gruppi[key]);
    }

    return axios.post(this.getUrlDomain("insMailGruppiApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getMailGruppi(id)
  {


    return axios.get(this.getUrlDomain("getMailGruppiApi") + "/" + id + "?api=1",{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updateMailGruppi(mail_gruppi, id)
  {

    let formData = new FormData();

    for ( var key in mail_gruppi ) {
       formData.append(key, mail_gruppi[key]);
    }

    return axios.post(this.getUrlDomain("updMailGruppiApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteMailGruppi(id, id_grp_sel)
  {


    return axios.get(this.getUrlDomain("delMailGruppiApi") + "?v_id=" + id + "&id_grp_sel=" + id_grp_sel,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getGruppi(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("getGruppi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




}

export default new APIMailGruppi({
  url: "http://emtool.local/public/api/smartbook/"
})
