<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_eccezzioni_ristorazioni">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'>Da</th>
                                        <th class='all'>A</th>
                                        <th class='desktop' style="text-align: center;">Pranzo</th>
                                        <th class='all'>Descrizione</th>
                                        <th class='desktop' style="text-align: center;">Cena</th>
                                        <th class='desktop'>Descrizione</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="8">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/smartbook_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_smartbook.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_smartbook.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

    </div>
</template>

<script>
import apibookingrsa from "../utils/bookingrsa/apibookingrsa";
import apieccezioni from "../utils/eccezioni/apieccezioni";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        $("body").off();

        window.curSrcText = "";

        var b = window.$cookies.get("b");
        var $rotta = "";
        if (b == "S" || b == 'Y') {
            $rotta = "/listBookingRsa";

        } else {
            $rotta = "/bookingRsa";

            //  alert ( $rotta );

            setTimeout(() => {
                router.push({
                    path: $rotta
                });

            }, 300);

            //     this.$router.go( $rotta );
            //   return false ;
        }
        console.log($rotta);

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            var ids = "";

            console.log(data);
            switch (data) {


                case "btn_delete":

                    // if (window.id_par_sel == 0) {

                    //     this.$swal({
                    //         icon: "error",
                    //         text: "Selezionare la riga da eliminare",
                    //         showConfirmButton: false,
                    //         timer: 2000
                    //     });

                    // }

                    // if (window.id_par_sel > 0) {

                    //     this.deleteRow();

                    // }

                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editEccezioneRistorazioni/0"
                    });
                    break;

                case "btn_edit":


                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editEccezioneRistorazioni/" + ids
                    });



                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        var pointerVue = this;

        this.setupGrid();
        this.hideShowButtons(false);

    },
    data: () => ({

        textSearch: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        size: 30,
                        title: "Aggiungi"
                    });


                }


                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-edit-smartbook.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        size: 30,
                        title: "Modifica"
                    });

                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-delete-smartbook.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        size: 25,
                        title: "Elimina"
                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                    size: 35,
                    title: "Indietro"
                });





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },



        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            $(".spin").show();

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);


        },


        deleteRow: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_smartbook.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_smartbook.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    },
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apieccezioni.deleteEccezione(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteEccezioneRistorazione", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                // window.table._fnDraw();
                                // that.hideShowButtons(false);

                                window.location.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            //return result + "public/api/smartbook/" + nome_metodo;

            // alert(result + nome_metodo);

            return result + nome_metodo;

        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;

            var pathLocal = pointerVue.$withBase('/');

            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

            

                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 30,

                    processing: false,
                    paging: true,
                    serverSide: true,

                    ajax: {
                        url: pointerVue.getUrlDomain("getEccezioniApi") + '?username=' + v_token,


                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;

                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: [

                        {
                            data: 'sel_row',
                            orderable: false,
                            searchable: false,
                            name: '',
                            width: '1%',
                        },
                        {
                            data: 'DT_RowIndex',
                            orderable: false,
                            searchable: false,
                            name: '',
                            width: '1%',
                        },

                        {
                            data: 'from',
                            orderable: false,
                            name: 'from',
                            width: '3%'
                        },
                        {
                            data: 'to',
                            orderable: false,
                            name: 'to',
                            width: '3%'
                        },

                        {
                            data: 'is_pranzo',
                            orderable: false,
                            name: 'is_pranzo',
                            width: '2%'
                        },

                        {
                            data: 'description',
                            orderable: false,
                            name: 'description',
                            width: '6%',
                        },

                        {
                            data: 'is_cena',
                            orderable: false,
                            name: 'is_cena',
                            width: '2%'
                        },

                        {
                            data: 'description2',
                            orderable: false,
                            name: 'description2',
                            width: '6%',
                        },

                    ],

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);



                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Eccezioni</div>

                </div>
                <div><img src='` + pathLocal + `ricerca-smartbook.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                //new $.fn.dataTable.FixedHeader(window.table);

                console.log("EVENT: ", window.mediciAddedEvents);

                //if (window.mediciAddedEvents === undefined) {

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_eccezzioni_ristorazioni tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);





                });






                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });
                    window.currDay = date;
                    window.currDayTo = date;



                }, 600);

                window.mediciAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {


            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            size: 30,
                            title: "Aggiungi"
                        },

                    ];

                }

                if (v_enable == true) {


                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            size: 30,
                            title: "Aggiungi"

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon-edit-smartbook.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            size: 30,
                            title: "Modifica"

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon-delete-smartbook.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            size: 25,
                            title: "Elimina"

                        },

                    ];


                }

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        size: 30,
                        title: "Aggiungi"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-edit-smartbook.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        size: 30,
                        title: "Modifica"

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-delete-smartbook.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        size: 25,
                        title: "Elimina"

                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },


        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        createPdf: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            // valori.currDayTo = window.currDayTo;
            // valori.curStatus = window.curStatus;
            // valori.curPiano = window.curPiano;

            var response = await apibookingrsa.getPdfPrenotazioni(
                "ZmFnb3N0b25p",
                3165,
                window.currDay,
                window.currDayTo,
                window.curStatus,
                window.curPiano,
                ""
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPdfPrenotazioni", res);

                try {

                    console.log(res.data.Result);

                    window.open(res.data.Result, '_blank');

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        }

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/_lib/img/btn_cancel3_smartbook.png")!important;
    background-size: 34px!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    text-indent: 1px!important;
    min-width: 40px!important;
    max-width: 34px!important;
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/public/_lib/img/btn_confirm3_smartbook.png")!important;
    background-size: 32px!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    text-indent: 1px!important;
    min-width: 40px!important;
    max-width: 34px!important;
}



.cont_eccezzioni_ristorazioni .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_eccezzioni_ristorazioni .val_status {
    width: 10px !important;
}


.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.cont_eccezzioni_ristorazioni #myTable {
    margin-right: 3px;
    width: 685px !important;
    margin-top: 3px;
}

.cont_eccezzioni_ristorazioni table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_eccezzioni_ristorazioni table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 20px !important;
}

.cont_eccezzioni_ristorazioni th {
    padding-left: 5px !important;
}

.cont_eccezzioni_ristorazioni ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_eccezzioni_ristorazioni .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_eccezzioni_ristorazioni #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background: #ffffff !important;
    border-color: #de7d37 !important;
    top: 64px;
}

.cont_eccezzioni_ristorazioni div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}


/* .cont_eccezzioni_ristorazioni #myTable_wrapper {
    padding-top: 0px;
    max-width: 700px !important;
} */

.cont_eccezzioni_ristorazioni #myTable_wrapper {
    min-width: initial;
    min-width: 700px;
    max-width: 700px;
    width: 700px;
}



.cont_eccezzioni_ristorazioni .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_eccezzioni_ristorazioni .text_title {
    background-color: white !important;
    color: black !important;
    font-weight: bold !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 0px !important;
    font-size: 10px !important;
    min-width: 82vw !important;
}


.cont_eccezzioni_ristorazioni ion-checkbox {
    --size: 10px !important;
    max-width: 10px !important;
    min-width: 10px !important;
}

.cont_eccezzioni_ristorazioni .div_date {
    width: 60px;
}

.cont_eccezzioni_ristorazioni .div_icon_pranzo {
    text-align: center;
    margin-left: 4px
}

.cont_eccezzioni_ristorazioni .div_icon_cena {
    text-align: center;
    margin-left: 8px
}

@media screen and (max-width: 960px) {

    .cont_eccezzioni_ristorazioni #myTable {
        /* margin-top:54px; */
    }

    .cont_eccezzioni_ristorazioni #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_eccezzioni_ristorazioni .cl_num {
        margin-left: -2px !important;
    }

    .cont_eccezzioni_ristorazioni .cl_checkall {
        padding-left: 14px;
        padding-top: 2px;
    }

    .cont_eccezzioni_ristorazioni #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        width: 100vw !important;
        margin-top: 7px !important;

    }

    .cont_eccezzioni_ristorazioni .div_date {
        width: 58px;
    }

    .cont_eccezzioni_ristorazioni .div_icon_pranzo {
        display: contents;
    }

    .cont_eccezzioni_ristorazioni .div_icon_cena {
        display: contents;
    }


}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_eccezzioni_ristorazioni .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;

    }

    .cont_eccezzioni_ristorazioni #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        /*   left: 12px; */
        width: 100vw !important;



    }

}
</style>
