import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITipologiePrenotazioni {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/smartbook/" + nome_metodo;


  }


  async setTipologiaPrenotazione(tipologia_prenotazione, id, user, gruppi)
  {

    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);
    formData.append("v_gruppi",  gruppi);

    for ( var key in tipologia_prenotazione ) {
       formData.append(key, tipologia_prenotazione[key]);
    }

    return axios.post(this.getUrlDomain("setTipologiaPrenotazione") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getTipologiaPrenotazione(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("getTipologiaPrenotazione") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async deleteTipologiaPrenotazione(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("deleteTipologiaPrenotazione") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }





}

export default new APITipologiePrenotazioni({
  url: "http://emtool.local/public/api/smartbook/"
})
