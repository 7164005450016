<template>
    <v-container class="w-container v-overflow editEccezioneRistorazioni"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#de7d37">{{ titolo }}</div>


            </v-col>

        </v-row>


        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="eccezione.from" label="Da" id="dateFrom" persistent-hint readonly
                                v-on="on" :rules="dataDaRules"></v-text-field>
                        </template>
                        <v-date-picker v-model="eccezione.from" no-title @input="showPicker = false" locale="it"
                            @change="changeDateFrom"></v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker1" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="eccezione.to" label="A" id="dateTo" persistent-hint readonly v-on="on"
                                :rules="dataARules"></v-text-field>
                        </template>
                        <v-date-picker v-model="eccezione.to" no-title @input="showPicker1 = false" locale="it"
                            @change="changeDateTo"></v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>

            <v-row v-if="viewSingleDescription">

                <v-col cols="12" md="12">

                    <v-textarea v-model="eccezione.description" label="Descrizione"></v-textarea>

                </v-col>

            </v-row>

            <v-row v-if="viewPranzoCena" style="margin-bottom: 0px!important;padding-bottom: 0px!important;">

                <v-col cols="6" md="6" style="margin-bottom: 0px!important;padding-bottom: 0px!important;">

                    <v-checkbox label="Pranzo" true-value="S" false-value="N" v-model="eccezione.is_pranzo"
                        @click="changeCheckPranzo"></v-checkbox>

                </v-col>

                <v-col cols="6" md="6" style="margin-bottom: 0px!important;padding-bottom: 0px!important;">

                    <v-checkbox label="Cena" true-value="S" false-value="N" v-model="eccezione.is_cena"
                        @click="changeCheckCena"></v-checkbox>

                </v-col>

            </v-row>

            <v-row v-if="viewPranzoCena" style="margin-top: 0px!important;padding-top: 0px!important;">

                <v-col cols="6" md="6" style="margin-top: 0px!important;padding-top: 0px!important;">

                    <v-textarea v-if="viewDescPranzo" v-model="eccezione.description" label="Descrizione"></v-textarea>

                </v-col>

                <v-col cols="6" md="6" style="margin-top: 0px!important;padding-top: 0px!important;">

                    <v-textarea v-if="viewDescCena" v-model="eccezione.description2" label="Descrizione"></v-textarea>

                </v-col>

            </v-row>


        </v-form>

    </v-container>
</template>

<script>
import apieccezioni from "../utils/eccezioni/apieccezioni";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        //var pointerVue = this;

        console.log("PARAMS: ", this.$route.params.id);


        this.viewPranzoCena = true;
        this.viewSingleDescription = false;


        this.cur_id = this.$route.params.id;

        this.setupButtons();

        if (this.$route.params.id > 0) {

            setTimeout(() => {
                this.syncEccezione();
            }, 100);

        }


        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Eccezione";

        }


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":

                    router.push({
                        path: "/eccezioniRistorazioni"
                    });

                    break;

                default:
                    break;
            }
        });



        $(document).ready(function () {





            // $('body').on('blur', '#dateFrom', function (event) {

            //     // console.log("DATE FROM EVENT: ", event);

            //     // console.log("VAL DATE FROM: ", pointerVue.eccezione.from);

            //     // console.log("VAL DATE FROM 2: ", $("#dateFrom").val());

            //     // pointerVue.eccezione.to = pointerVue.eccezione.from;


            //     // pointerVue.viewSingleDescription = false;

            //     // pointerVue.viewPranzoCena = true;

            //     setTimeout(() => {

            //         console.log("DATE FROM EVENT: ", event);

            //         console.log("VAL DATE FROM: ", pointerVue.eccezione.from);

            //         console.log("VAL DATE FROM 2: ", $("#dateFrom").val());

            //     }, 200);



            //     // setTimeout(() => {


            //     //     console.log("DATE FROM EVENT: ", event);

            //     //     console.log("VAL DATE FROM: ", pointerVue.eccezione.from);

            //     //     console.log("VAL DATE FROM 2: ", $("#dateFrom").val());

            //     //     pointerVue.eccezione.to = pointerVue.eccezione.from;


            //     //     pointerVue.viewSingleDescription = false;

            //     //     pointerVue.viewPranzoCena = true;

            //     // }, 300);



            // });


            // $('body').on('blur', '#dateTo', function (event) {


            //     setTimeout(() => {


            //         console.log("DATE TO EVENT: ", event);

            //         console.log("VAL DATE TO: ", pointerVue.eccezione.to);

            //         console.log("VAL DATE TO 2: ", $("#dateTo").val());

            //         console.log("VAL DATE FROM: ", pointerVue.eccezione.from);

            //         console.log("VAL DATE TO: ", pointerVue.eccezione.to);

            //         if (pointerVue.eccezione.to != pointerVue.eccezione.from) {

            //             pointerVue.eccezione.is_pranzo = "N";

            //             pointerVue.eccezione.is_cena = "N";

            //             pointerVue.eccezione.description = "";

            //             pointerVue.eccezione.description2 = "";

            //             pointerVue.viewSingleDescription = true;

            //             pointerVue.viewPranzoCena = false;


            //         }

            //         if (pointerVue.eccezione.to == pointerVue.eccezione.from) {

            //             pointerVue.eccezione.is_pranzo = "N";

            //             pointerVue.eccezione.is_cena = "N";

            //             pointerVue.eccezione.description = "";

            //             pointerVue.eccezione.description2 = "";

            //             pointerVue.viewSingleDescription = false;

            //             pointerVue.viewPranzoCena = true;


            //         }


            //     }, 300);



            // });


        });




    },

    data: () => ({


        viewDescPranzo: false,
        viewDescCena: false,


        valorePranzo: "N",
        valoreCena: "N",

        viewPranzoCena: false,

        viewSingleDescription: false,

        titolo: "Aggiungi Eccezione",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,

        eccezione: { from: new Date().toISOString().substr(0, 10), to: new Date().toISOString().substr(0, 10), description: "", description2: "", is_pranzo: "N", is_cena: "N" },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        dataDaRules: [
            (v) => !!v || "Data Da richiesta",
        ],

        dataARules: [
            (v) => !!v || "Data A richiesta",
        ],

        select: null,
        items: window.$cookies.get("gen_settings").nuclei,
        checkbox: false,
    }),

    methods: {


        changeDateFrom: function () {

            console.log("VALUE: ", this.eccezione.from);

            this.eccezione.to = this.eccezione.from;

            this.eccezione.description = "";

            this.eccezione.description2 = "";

            this.eccezione.is_pranzo = "N";

            this.eccezione.is_cena = "N";

            this.viewSingleDescription = false;

            this.viewPranzoCena = true;


        },

        changeDateTo: function () {

            console.log("VALUE: ", this.eccezione.to);

            if (this.eccezione.to != this.eccezione.from) {

                this.eccezione.is_pranzo = "N";

                this.eccezione.is_cena = "N";

                this.eccezione.description = "";

                this.eccezione.description2 = "";

                this.viewSingleDescription = true;

                this.viewPranzoCena = false;


            }

            if (this.eccezione.to == this.eccezione.from) {

                this.eccezione.is_pranzo = "N";

                this.eccezione.is_cena = "N";

                this.eccezione.description = "";

                this.eccezione.description2 = "";

                this.viewSingleDescription = false;

                this.viewPranzoCena = true;


            }

        },


        changeCheckPranzo: function () {

            console.log("VALORE PRANZO: ", this.valorePranzo);

            if (this.eccezione.is_pranzo == "S") {

                this.viewDescPranzo = true;

            }

            if (this.eccezione.is_pranzo == "N") {

                this.eccezione.description = "";

                this.viewDescPranzo = false;

            }

        },

        changeCheckCena: function () {

            console.log("VALORE CENA: ", this.valoreCena);

            if (this.eccezione.is_cena == "S") {

                this.viewDescCena = true;

            }

            if (this.eccezione.is_cena == "N") {

                this.eccezione.description2 = "";

                this.viewDescCena = false;

            }

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-save-smartbook.png",
                    size: 30
                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                    size: 35
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var that = this;

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                that.saveData();



            }
        },

        saveData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apieccezioni.insertEccezione(
                    that.eccezione,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertEccezione", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/eccezioniRistorazioni"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {



                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apieccezioni.updateEccezione(
                    that.eccezione,
                    that.$route.params.id,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateEccezione", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/eccezioniRistorazioni"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );





            }



        },

        syncEccezione: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apieccezioni.getEccezione(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getEccezione", res);

                try {

                    this.eccezione = res.data.eccezione;

                    if (res.data.eccezione.is_pranzo == "S") {

                        this.viewDescPranzo = true;

                    }

                    if (res.data.eccezione.is_cena == "S") {

                        this.viewDescCena = true;

                    }

                    if ((res.data.eccezione.is_pranzo == "N") && (res.data.eccezione.is_cena == "N")) {

                        this.viewPranzoCena = false;
                        this.viewSingleDescription = true;


                    }



                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editEccezioneRistorazioni .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editEccezioneRistorazioni .v-input {
    font-size: 1.2em;
}

.editEccezioneRistorazioni .v-select {
    font-size: 1.2em;
}


.editEccezioneRistorazioni .v-label {
    font-size: 1em;
}


@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
