<template>
    <v-container class="w-container v-overflow editMailgruppoSb"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">


                <div class="mainTitle">{{ titolo }}</div>

            </v-col>

        </v-row>


        <v-form style="max-width:500px" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">


                    <v-text-field v-model="mail_gruppo.address_mail" :rules="emailRules" label="E-mail" required>
                    </v-text-field>

                </v-col>

            </v-row>


        </v-form>

    </v-container>
</template>

<script>
/*eslint-disable no-undef*/
//import apiparenti from "../utils/profile/apiparenti";

//import apitipologieprenotazione from "../utils/tipologieprenotazione/apitipologieprenotazione";

import apimailgruppi from "../utils/mailgruppi/apimailgruppi";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        this.$root.$children[0].checkLogout();


        console.log("GEN SETTINGS: ", window.$cookies.get("gen_settings").gruppi);

        this.cur_id = this.$route.params.id;

        //this.mail_gruppo.email = "m.cipolla@lc-service.it";
        window.viewPointer = this;

        this.setupButtons();

        // if (this.$route.params.id > 0) {

        //     setTimeout(() => {
        //         this.syncMailGruppi();
        //     }, 100);

        // }


        if (this.$route.params.id != "0_0") {

            setTimeout(() => {
                this.syncMailGruppi();
            }, 100);

        }


        // if (this.$route.params.id > 0) {

        //     this.titolo = "Modifica Mail Gruppo";

        // }

        if (this.$route.params.id != "0_0") {

            this.titolo = "Modifica Mail Gruppo";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/mailGruppi"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    data: () => ({
        titolo: "Aggiungi Mail Gruppo",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        //gruppi: [],
        currAzienda: "",
        selGroups: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        mail_gruppo: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        descRules: [
            (v) => !!v || "Descrizione richiesta",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        select: null,
        items: window.$cookies.get("gen_settings").nuclei,

        gruppi: window.$cookies.get("gen_settings").gruppi,


        checkbox: false,
    }),

    methods: {

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-save-smartbook.png",
                    size: 30,
                    title: "Salva"
                },
                {
                    text: "Tipologia Prenotazione",
                    icon: "mdi-arrow-left",
                    link: "/tipologieprenotazione",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                    size: 35,
                    title: "Indietro"

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var that = this;

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                that.saveData();



            }
        },

        saveData: async function () {

            var v_id_group = window.$cookies.get("sel_gruppo_mail_gruppi");

            var that = this;

            var response = null;

            if (that.$route.params.id == "0_0") {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apimailgruppi.insertMailGruppi(
                    that.mail_gruppo,
                    v_id_group
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertMailGruppi", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/mailGruppi"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id != "0_0") {


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apimailgruppi.updateMailGruppi(
                    that.mail_gruppo,
                    that.$route.params.id,
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateMailGruppi", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/mailGruppi"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );



            }





        },

        syncMailGruppi: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimailgruppi.getMailGruppi(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getMailGruppi", res);

                try {

                    that.mail_gruppo = res.data.mail;


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.editMailgruppoSb .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editMailgruppoSb .mainTitle {
    color: black !important;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 100vw;
    border-color: #de7d37;
    background-color: white;
    top: 64px;
}

.editMailgruppoSb .v-input {
    font-size: 1.2em;
}

.editMailgruppoSb .v-select {
    font-size: 1.2em;
}


.editMailgruppoSb .v-label {
    font-size: 1em;
}


@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .editMailgruppoSb .mainTitle {
        color: black !important;
        font-weight: bold;
        padding: 4px;
        font-size: 10px;
        min-width: 100vw;
        border-color: #de7d37;
        background-color: white;
        top: 56px;
    }


}
</style>
