<template>
  <div id="logins">



    <link
      href="https://fonts.googleapis.com/css?family=Roboto:100,400,800"
      rel="stylesheet"
    />
    <!--  <h1>Login</h1>
        <input type="text" name="username" v-model="input.username" placeholder="Username" />
        <input type="password" name="password" v-model="input.password" placeholder="Password" />
        <button type="button" v-on:click="login()">Login</button> -->

    <div class="fs-container">
      <div class="fs-container__bg"></div>
      <div class="content" >
        <div class="content-login" >
          <div class="content__pane init2">
            <img class="logo" src="@/assets/emt-logo-small.png" style="max-width: 60px" />

            <form
              name="login"
              id="login-form"
              novalidate=""
              method="POST"
              v-on:submit.prevent
              style="max-width: 286px;"
            >
              <div class="flex-container">
                <input
                  type="hidden"
                  id="t_login"
                  name="t_login"
                  value="NO_QRCODE"
                />

                <div id="classic-login">

             <div class="group filled">


                    <input
                      v-model="input.name"
                      id="name"
                      class="name"
                      required=""
                      placeholder="Name"
                      name="name"
                      type="text"
                      value=""
                    />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden"
                        >Username</span
                      >
                    </label>
                  </div>


                <div class="group filled">
                    <input
                      v-model="input.surname"
                      id="surname"
                      class="surname"
                      required=""
                      placeholder="Surname"
                      name="surname"
                      type="text"
                      value=""
                    />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Surname</span>
                    </label>
                  </div>



                <div class="group filled">
                    <input
                      v-model="input.email"
                      id="email"
                      class="email"
                      required=""
                      placeholder="Email"
                      name="Email"
                      type="email"
                      value=""
                    />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Surname</span>
                    </label>
                  </div>




                <div class="group filled">
                    <input
                      v-model="input.phone"
                      id="phone"
                      class="phone"
                      required=""
                      placeholder="Phone"
                      name="text"
                      type="phone"
                      value=""
                    />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Phone</span>
                    </label>
                  </div>




                  <div class="group filled">


                    <input
                      v-model="input.username"
                      id="user"
                      class="user"
                      required=""
                      name="user"
                      type="text"
                      placeholder="Username"
                      value=""
                    />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden"
                        >Username</span
                      >
                    </label>
                  </div>

                  <div class="group filled" style="margin-bottom: 0px">
                    <input
                      v-model="input.password"
                      id="password"
                      class="password"
                      placeholder="Password"
                      required=""
                      name="password"
                      type="password"
                      value=""
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <span class="label__text label__text--hidden"
                        >Password</span
                      >
                      <img src="@/assets/loginstart/img/pwd.png" />
                    </label>

                   <!--   <a href=" " id="forgotPass" :to="register">
                      Sign up</a> -->
                     <a   id="forgotPass"  >

                     <router-link id="forgotPass" tag="tr" :to="'/'"> Login</router-link>

                     </a>


                  </div>
                </div>

                <div id="qr-code-wrapper">
                  <button type="button" id="qr-code-btn">
                    <span class="tooltiptext">Login with QR-Code</span>

                    <img
                      src="@/assets/loginstart/img/qr-btn-alpha.png"
                      alt="submit"
                    />
                  </button>
                </div>

                <!-- added code -->

                <div class="container" id="qr-login" style="display: none">
                  <div>
                    <a class="button" id="resetButton">Back</a>
                  </div>

                  <div>
                    <video
                      id="video"
                      width="300"
                      height="200"
                      style="border: 1px solid gray"
                    ></video>
                  </div>

                  <div id="sourceSelectPanel" style="display: none">
                    <label for="sourceSelect">Change video source:</label>
                    <select id="sourceSelect" style="max-width: 400px"></select>
                  </div>

                  <div style="text-align: left; display: none">
                    <label>Result:</label>
                    <pre><code id="result"></code></pre>
                  </div>
                </div>

                <!-- end added code -->
              </div>

              <div id="lang-select" class="group select">
                <div class="lang__field">
                  <span class="lang__selected"
                    ><img src="@/assets/loginstart/img/uk.png" class="flag-img"
                  /></span>
                  <label class="select__label">
                    <img src="@/assets/loginstart/img/language.png" />
                  </label>
                  <div class="lang__options">
                    <div data-value="EN" class="lang__option">
                      <img
                        src="@/assets/loginstart/img/uk.png"
                        class="flag-img"
                      />
                    </div>
                    <div data-value="IT" class="lang__option">
                      <img
                        src="@/assets/loginstart/img/italy.png"
                        class="flag-img"
                      />
                    </div>
                  </div>
                </div>
                <span class="highlight"></span>
                <span class="bar"></span>
              </div>

              <div id="otp-select" class="group select">
                <div class="otp__field">
                  <span class="otp__selected">
                    <div class="otp-text">Email</div>
                    <img
                      src="@/assets/loginstart/img/mail.png"
                      class="otp-img"
                    />
                  </span>
                  <label class="select__label">
                    <div class="text-label">OTP</div>
                    <img src="@/assets/loginstart/img/token.png" />
                  </label>
                  <div class="otp__options">
                    <div data-value="MAIL" class="otp__option">
                      <div class="otp-text">Email</div>
                      <img
                        src="@/assets/loginstart/img/mail.png"
                        class="otp-img"
                      />
                    </div>
                    <div data-value="SMS" class="otp__option">
                      <div class="otp-text">Sms</div>
                      <img
                        src="@/assets/loginstart/img/sms.png"
                        class="otp-img"
                      />
                    </div>

                    <div data-value="Auth" class="otp__option">
                      <div class="otp-text">Auth</div>
                      <img
                        src="@/assets/loginstart/img/auth.png"
                        class="otp-img"
                      />
                    </div>
                  </div>
                </div>
                <span class="highlight"></span>
                <span class="bar"></span>
              </div>

              <button
                class="
                  button button--block button--outline button--primary
                  modal-trigger
                "
                id="login-button"
                value="login"
                v-on:click="register()"
              >
                <div class="button--text">Sign up</div>
                <div class="spinner hidden">
                  <div class="holder">
                    <div class="preloader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </button>

              <rise-loader
                v-if="showSpinner"
                :size="'20px'"
                :color="'#f77002'"
              />
              <!-- <div class="info info--error info--hidden">
                Devi inserire sia il nome utente sia la password
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>












  </div>








</template>








<style scoped lang="css">
@import "./../assets/loginstart/style.css";
</style>

<script>
//@import "@/assets/loginstart/style.css"
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import RiseLoader from "vue-spinner/src/RiseLoader.vue";

import router from ".././router";

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {
  components: {
    /* PulseLoader, */
    RiseLoader,
  },

  updated() {},

  mountedOLD: function () {
    console.log(this.$route.path);
    if (this.$route.path == "/") {
      // console.log( this.$parent );
      //  window.App.ok = 1 ;
      //   App.hideToolbars = true ;
      // Vue.set(this.$root , 'hideToolbars', true)
    } else {
      // window.App.ok = 0 ;
      //  App.hideToolbars = false ;
      // this.$root.hideToolbars = false ;
      // Vue.set(this.$root , 'hideToolbars', false)
    }
  },
  mounted: function () {
      var loggedIn = this.$cookies.get('token') ;

    if (loggedIn) {
      router.push({ path: "/dash" });
    }
    console.log("Ciao: " + this.userLogged);

    //        this.$cookie.get('jwt_token')
    //console.log ( this.$cookie.getAll() );
    //$cookie.set('cookie_name', 'cookie_value');
    //window.$cookie.set('test', 'Hello world!', 1);
    //  console.log (window.$cookies.getCookie()  );
    // const username = this.$cookies.get("username");
    //console.log(username);
    //        this.$cookie.getAll()
  },
  name: "Login",
  data() {
    return {
        dialogRegister:true ,

        notifications: false,
        sound: true,
        widgets: false,

      showSpinner: false,
      input: {
        username: "",
        password: "",
      },
    };
  },
  methods: {

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
}
,
    getCookie() {
      this.$cookies.set("cookie-consent-performance", "yes");

      //  this.$swal('You are logged in!!!');

      //   console.log(Vue.cookie.get('token'));

      // it gets the cookie called `username`
      /* const username = this.$cookies.get("username");
      console.log(username); */
    },

    registerAction: async function () {
      //const auth = { user: this.username, password: this.password };
      // Correct username is 'foo' and password is 'bar'
      //const url = "https://app.emtool.eu/api/emt/register";
      const url = "https://app.emtool.eu/registerUserForCompanyPost";
      this.success = false;
      this.error = null;

      let formData = new FormData();

      formData.append("context", "emt");

    if (this.input.username != undefined)
      formData.append("login", this.input.username);

    if (  this.input.password != undefined)
      formData.append("password", this.input.password);

      formData.append("companyname", "7"  );

    if (  this.input.name != undefined)
      formData.append("name", this.input.name);

    if (  this.input.surname != undefined)
      formData.append("surname", this.input.surname);

    if (  this.input.email != undefined)
      formData.append("email", this.input.email);


    if (  this.input.phone != undefined)
      formData.append("phone", this.input.phone);


      formData.append("app", "emt");


      try {
        await this.axios
          .post(url, formData, { "content-type": "text/json" })
          .then((result) => {
            var respo = result.data;
            this.success = true;
            // console.log( respo   );

            setTimeout(() => {
              this.showSpinner = false;

                var msgError ="" ;
                console.log ("re",respo.errors);



              if ( respo.errors && !this.isEmpty(respo.errors)  )
              {
                        console.log ( "Errori", respo.errors );
                        var err =     respo.errors ;

                    Object.entries(err).forEach(([key, value]) => {
                        console.log(`${key} ${value}`);
                        msgError+= `${value}\r\n `;
                    });

                    if ( msgError)
                        {
                            this.$swal({ icon: "error", text: msgError });

                        }


              }
              else
              {
                   this.$swal({ icon: "success", text: "Please check your email" });
              }
              //this.$swal("You are logged in!!!");

          /*     window.$cookies.set("token", respo.token , "9y");
              window.$cookies.set("system", respo.system,"9y");
              window.$cookies.set("token_system", respo.token_system,"9y");
              window.$cookies.set("username", respo.username,"9y");
              window.$cookies.set("user_id", respo.user_id,"9y");
              window.$cookies.set("namesurname", respo.namesurname,"9y"); */

              this.$root.$children[0].curDipTop = respo.namesurname ;

              //router.push({ path: "/" });

            }, 1);
          });
      } catch (err) {
        this.success = false;
        console.log(err.message);
        this.$swal({ icon: "error", text: "Please check your data" });

        this.showSpinner = false;
        //  console.log(   response);

        //   this.error = err.message;
      }
    },

    register() {
      this.showSpinner = true;
      this.getCookie();

      this.registerAction();
      /*     if (this.input.username != "" && this.input.password != "") {
        if (
          this.input.username == this.$parent.mockAccount.username &&
          this.input.password == this.$parent.mockAccount.password
        ) {
          this.$emit("authenticated", true);
          this.$router.replace({ name: "secure" });
        } else {
          console.log("The username and / or password is incorrect");
        }
      } else {
        console.log("A username and password must be present");
      } */
    },
  },
};
</script>

<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
  display: none;
}
#login {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  /*   margin-top: 200px; */
  padding: 20px;
}
#nav,
.v-sheet {
  display: none;
}
</style>


