import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import parenti from './components/parenti.vue'
import editParente from './components/editParente.vue'
import bookingrsa from './components/bookingrsa.vue'
import bookinggen from './components/bookinggen.vue'
import listBookingRsa from './components/listBookingRsa.vue'
import listBookingGen from './components/listBookingGen.vue'
import qrconfirm from './components/qrconfirm.vue';
import tipologieprenotazione from './components/tipologieprenotazione.vue'
import editTipologiaPrenotazione from './components/editTipologiaPrenotazione.vue'
import medici from './components/medici.vue'
import editMedico from './components/editMedico.vue'
import eccezioni from './components/eccezioni.vue'
import editEccezione from './components/editEccezione.vue'
import confOrariChoice from './components/confOrariChoice.vue'
import confOrari from './components/confOrari.vue'
import confOrariMedici from './components/confOrariMedici.vue'
import editConfOrari from './components/editConfOrari.vue'
import editConfOrariMedici from './components/editConfOrariMedici.vue'
import settings from './components/settings.vue'
import mailGruppiChoice from './components/mailGruppiChoice.vue'
import mailGruppiChoicePdf from './components/mailGruppiChoicePdf.vue'
import mailGruppi from './components/mailGruppi.vue'
import editMailGruppi from './components/editMailGruppi.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import reservate from './components/reservate.vue'
import assocOspiteSelNucleo from './components/assocOspiteSelNucleo.vue'
import summary from './components/summary.vue'
import assocOspitiAssocia from './components/assocOspitiAssocia.vue'
import cancelBookingGen from './components/cancelBookingGen.vue'
import reservateForCancel from './components/reservateForCancel.vue'
import editBookingGen from './components/editBookingGen.vue'
import reservateForEditBookingGen from './components/reservateForEditBookingGen.vue'
import eccezioniRistorazioni from './components/eccezioniRistorazioni.vue'
import initEccezioni from './components/initEccezioni.vue'
import editEccezioneRistorazioni from './components/editEccezioneRistorazioni.vue'
import confOrariRistorazione from './components/confOrariRistorazione.vue'
import editConfOrariRistorazione from './components/editConfOrariRistorazione.vue'
import reservateAuto from './components/reservateAuto.vue'
import reservateManageBookingGen from './components/reservateManageBookingGen.vue'

import manageBookingGen from './components/manageBookingGen.vue'


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login,
            props:true
        },


        {
            path: '/summary',
            name: 'home',
            component: summary,
            props:true
        },


        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },


        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

       
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,

        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepasswordadmin,
            props:true
        },


        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/listBookingRsa',
            name: 'listBookingRsa',
            component: listBookingRsa
        }

        ,
        {
            path: '/parenti',
            name: 'parenti',
            component: parenti
        }

        ,
        {
            path: '/editParente/:id',
            name: 'editParente',
            component: editParente,
            props: true
        }

        ,
        {
            path: '/bookingrsa',
            name: 'bookingrsa',
            component: bookingrsa,
            props: true
        }
        ,
        {
            path: '/bookinggen',
            name: 'bookinggen',
            component: bookinggen,
            props: true
        }
        ,
        {
            path: '/listBookingGen',
            name: 'listBookingGen',
            component: listBookingGen,
            props: true
        }
        ,
        {
            path: '/qrconfirm',
            name: 'qrconfirm',
            component: qrconfirm
        }

        ,
        {
            path: '/tipologieprenotazione',
            name: 'tipologieprenotazione',
            component: tipologieprenotazione,
            props: true
        }


        ,
        {
            path: '/editTipologiaPrenotazione/:id',
            name: 'editTipologiaPrenotazione',
            component: editTipologiaPrenotazione,
            props: true
        }

        ,
        {
            path: '/medici',
            name: 'medici',
            component: medici
        }
        ,
        {
            path: '/settings',
            name: 'settings',
            component: settings
        }

        ,
        {
            path: '/editMedico/:id',
            name: 'editMedico',
            component: editMedico,
            props: true
        }


        ,
        {
            path: '/eccezioni',
            name: 'eccezioni',
            component: eccezioni
        }


        ,
        {
            path: '/editEccezione/:id',
            name: 'editEccezione',
            component: editEccezione,
            props: true
        }


        ,
        {
            path: '/confOrariChoice',
            name: 'confOrariChoice',
            component: confOrariChoice
        }

        ,
        {
            path: '/confOrari',
            name: 'confOrari',
            component: confOrari
        }

        ,
        {
            path: '/confOrariMedici',
            name: 'confOrariMedici',
            component: confOrariMedici
        }


        ,
        {
            path: '/editConfOrari/:id',
            name: 'editConfOrari',
            component: editConfOrari,
            props: true
        }

        ,
        {
            path: '/editConfOrariMedici/:id',
            name: 'editConfOrariMedici',
            component: editConfOrariMedici,
            props: true
        }


        ,
        {
            path: '/mailGruppiChoice',
            name: 'mailGruppiChoice',
            component: mailGruppiChoice,
            props: true
        }
        ,
        {
            path: '/mailGruppiChoicePdf',
            name: 'mailGruppiChoicePdf',
            component: mailGruppiChoicePdf,
            props: true
        }

        ,
        {
            path: '/mailGruppi',
            name: 'mailGruppi',
            component: mailGruppi,
            props: true
        }

        ,
        {
            path: '/editMailGruppi/:id',
            name: 'editMailGruppi',
            component: editMailGruppi,
            props: true
        }



        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers,
            props: true
        }

        ,
        {
            path: '/users',
            name: 'users',
            component: users,
            props: true
        }



        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }


        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }

        ,
        {
            path: '/assocOspiteSelNucleo',
            name: 'assocOspiteSelNucleo',
            component: assocOspiteSelNucleo,
            props: true
        }

        ,
        {
            path: '/assocOspitiAssocia',
            name: 'assocOspitiAssocia',
            component: assocOspitiAssocia,
            props: true
        }


        ,
        {
            path: '/cancelBookingGen/:id',
            name: 'cancelBookingGen',
            component: cancelBookingGen,
            props: true
        }

        ,
        {
            path: '/reservateForCancel/:id',
            name: 'reservateForCancel',
            component: reservateForCancel,
            props: true
        }

        ,
        {
            path: '/editBookingGen/:id',
            name: 'editBookingGen',
            component: editBookingGen,
            props: true
        }


        ,
        {
            path: '/reservateForEditBookingGen/:id',
            name: 'reservateForEditBookingGen',
            component: reservateForEditBookingGen,
            props: true
        }

        ,
        {
            path: '/eccezioniRistorazioni',
            name: 'eccezioniRistorazioni',
            component: eccezioniRistorazioni
        }

        ,
        {
            path: '/initEccezioni',
            name: 'initEccezioni',
            component: initEccezioni
        }

        ,
        {
            path: '/editEccezioneRistorazioni/:id',
            name: 'editEccezioneRistorazioni',
            component: editEccezioneRistorazioni,
            props: true
        }

        ,
        {
            path: '/confOrariRistorazione',
            name: 'confOrariRistorazione',
            component: confOrariRistorazione
        }

        ,
        {
            path: '/editConfOrariRistorazione/:id',
            name: 'editConfOrariRistorazione',
            component: editConfOrariRistorazione,
            props: true
        }

        ,
        {
            path: '/reservateAuto/:id',
            name: 'reservateAuto',
            component: reservateAuto,
            props: true
        }

        ,
        {
            path: '/reservateManageBookingGen/:id',
            name: 'reservateManageBookingGen',
            component: reservateManageBookingGen,
            props: true
        }

        ,
        {
            path: '/manageBookingGen/:id',
            name: 'manageBookingGen',
            component: manageBookingGen,
            props: true
        }

    ]
})


router.afterEach((to, from) => {


    console.log("from", from);
    console.log("going to " + to.fullPath);
  
})


export default router
