<template>
    <v-container class="w-container v-overflow editTipologiaPrenotazione"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle">{{ titolo }}</div>


            </v-col>

        </v-row>


        <v-form style="max-width:500px" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field v-model="tipologie_prenotazione.description" label="Descrizione" :rules="descRules"
                        required></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">


                    <ion-item style="max-width:260px;">

                        <ion-label style="font-size:1.2em;" position="floating">Gruppi</ion-label>

                        <ion-select id="groups" name="groups" multiple="true" :value="selGroups">

                            <ion-select-option v-for="item in gruppi" :key="item.idresources_group_name"
                                :value='item.idresources_group_name'>{{ item.name }} </ion-select-option>


                        </ion-select>

                    </ion-item>



                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <v-checkbox label="Richiede Approvazione" v-model="tipologie_prenotazione.needsapprove"
                        true-value="1" false-value="0"></v-checkbox>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-checkbox label="Abilitato" v-model="tipologie_prenotazione.enabled" true-value="1"
                        false-value="0"></v-checkbox>

                </v-col>

            </v-row>





        </v-form>

    </v-container>
</template>

<script>
/*eslint-disable no-undef*/
//import apiparenti from "../utils/profile/apiparenti";

import apitipologieprenotazione from "../utils/tipologieprenotazione/apitipologieprenotazione";


import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        console.log("GEN SETTINGS: ", window.$cookies.get("gen_settings").gruppi);

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        if (this.$route.params.id > 0) {

            setTimeout(() => {
                this.syncTipologiaPrenotazione();
            }, 100);

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Tipologia Prenotazione";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");


                    // console.log($("#groups").val());

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/tipologieprenotazione"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    data: () => ({
        titolo: "Aggiungi Tipologia Prenotazione",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        //gruppi: [],
        currAzienda: "",
        selGroups: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        tipologie_prenotazione: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        descRules: [
            (v) => !!v || "Descrizione richiesta",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        select: null,
        items: window.$cookies.get("gen_settings").nuclei,

        gruppi: window.$cookies.get("gen_settings").gruppi,


        checkbox: false,
    }),

    methods: {

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-save-smartbook.png",
                    size: 30,
                    title: "Salva"
                },
                {
                    text: "Tipologia Prenotazione",
                    icon: "mdi-arrow-left",
                    link: "/tipologieprenotazione",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                    size: 35,
                    title: "Indietro"

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var that = this;

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                that.saveData();



            }
        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            console.log("TIPO PREN: ", that.tipologie_prenotazione);

            console.log("GROUPS: ", $("#groups").val());

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apitipologieprenotazione.setTipologiaPrenotazione(
                that.tipologie_prenotazione,
                that.$route.params.id,
                v_token,
                $("#groups").val()
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setTipologiaPrenotazione", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/tipologieprenotazione"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncTipologiaPrenotazione: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apitipologieprenotazione.getTipologiaPrenotazione(
                that.$route.params.id,
                "ZmFnb3N0b25p"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologiaPrenotazione", res);

                try {

                    // this.parente = res.data.Result;

                    this.tipologie_prenotazione = res.data.Result;

                    this.selGroups = res.data.Result.groups;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.editTipologiaPrenotazione .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editTipologiaPrenotazione .mainTitle {
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 100vw;
    border-color: #de7d37;
    background-color: white;
    top: 64px;
}

.editTipologiaPrenotazione .v-input {
    font-size: 1.2em;
}

.editTipologiaPrenotazione .v-select {
    font-size: 1.2em;
}


.editTipologiaPrenotazione .v-label {
    font-size: 1em;
}


@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }


    .editTipologiaPrenotazione .mainTitle {
        color: black;
        font-weight: bold;
        padding: 4px;
        font-size: 10px;
        min-width: 100vw;
        border-color: #de7d37;
        background-color: white;
        top: 56px;
    }

}
</style>
