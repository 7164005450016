<template>
<v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">
    <v-row>
        <v-col cols="12">
            <!-- <v-img
          :src="require('@/assets/emt-logo-small.png')"
          class="my-3"
          contain
          height="100"
        /> -->
        </v-col>

        <v-col cols="6" class="mb-15" style="display:none;">
            <!--  <v-icon :to="'/timesheet'">mdi-timetable</v-icon> -->

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon id="no-background-hover" :to="getListBooking()">
                        <!--   <v-icon color="#fc2" size="80" medium>mdi-timetable</v-icon> -->

                        <v-img alt="Emt logo" width="90" class=" " contain src="@/assets/prenotazione.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Prenotazioni</div>
                </template>
                <span>Prenotazioni</span>
            </v-tooltip>

        </v-col>


        <v-col cols="6" class="mb-15" style="display:none;" >
            <!--  <v-icon :to="'/timesheet'">mdi-timetable</v-icon> -->

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon id="no-background-hover" to="/parenti">
                        <!--   <v-icon color="#fc2" size="80" medium>mdi-timetable</v-icon> -->

                        <v-img alt="Emt logo" width="90" class=" " contain src="@/assets/parenti_home.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Parenti</div>
                </template>
                <span>Parenti</span>
            </v-tooltip>

        </v-col>


        <v-col cols="6" class="mb-15" style="display:none;">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon id="no-background-hover" :to="getBooking()">
                        <v-img alt="Emt logo" width="90" class=" " contain src="@/assets/booking.png" transition="scale-transition" />
                    </v-btn>
                    <div class="btnText">Prenota</div>
                </template>
                <span>Prenota</span>
            </v-tooltip>

        </v-col>




    </v-row>
</v-container>
</template>

<script>
export default {
    name: "HelloWorld",

    methods: {
         getListBooking: function () {

             var sec = window.$cookies.get ("sector");


                if (sec == 'RSA')return "/listBookingRsa";
                if (sec == 'RISTORAZIONE')return "/listBookingGen";
         },
         getBooking: function () {

             var sec = window.$cookies.get ("sector");


                if (sec == 'RSA')return "/bookingrsa";
                if (sec == 'RISTORAZIONE')return "/bookinggen";
         }

    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },
    data: () => ({

        ecosystem: [{
                text: "vuetify-loader",
                href: "https://github.com/vuetifyjs/vuetify-loader",
            },
            {
                text: "github",
                href: "https://github.com/vuetifyjs/vuetify",
            },
            {
                text: "awesome-vuetify",
                href: "https://github.com/vuetifyjs/awesome-vuetify",
            },
        ],
        importantLinks: [{
                text: "Documentation",
                href: "https://vuetifyjs.com",
            },
            {
                text: "Chat",
                href: "https://community.vuetifyjs.com",
            },
            {
                text: "Made with Vuetify",
                href: "https://madewithvuejs.com/vuetify",
            },
            {
                text: "Twitter",
                href: "https://twitter.com/vuetifyjs",
            },
            {
                text: "Articles",
                href: "https://medium.com/vuetify",
            },
        ],
        whatsNext: [{
                text: "Explore components",
                href: "https://vuetifyjs.com/components/api-explorer",
            },
            {
                text: "Select a layout",
                href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
            },
            {
                text: "Frequently Asked Questions",
                href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
            },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
