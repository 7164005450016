<template>

    <v-container class="w-container v-overflow editParente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#de7d37">{{ titolo }}</div>

            </v-col>

        </v-row>


        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <v-select v-model="selGruppo" :items="gruppi" label="Gruppo" item-text="name"
                        item-value="idresources_group_name" v-on:change="changeGrp"></v-select>

                </v-col>

            </v-row>





        </v-form>

    </v-container>
</template>

<script>
//import apiparenti from "../utils/profile/apiparenti";

import apimedico from "../utils/medico/apimedico";
//import apiconfigurazioneorari from "../utils/configurazioneorari/apiconfigurazioneorari";
import apimailgruppi from "../utils/mailgruppi/apimailgruppi";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


        console.log("PARAMS: ", this.$route.params.id);

        console.log("GEN SETTINGS: ", window.$cookies.get("gen_settings").nuclei);

        this.cur_id = this.$route.params.id;

        this.setupButtons(false);

        setTimeout(() => {

            this.syncGruppi();

        }, 100);





        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    data: () => ({
        titolo: "Mail Gruppi",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selGruppo: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],


        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        gruppi: [],

        checkbox: false,
    }),

    methods: {

        changeGrp: function () {


            this.setupButtons(true);

        },





        // setupButtons: async function () {

        //     setTimeout(() => {

        //         var pulsantis = [{
        //             text: "Conferma",
        //             icon: "mdi-content-save",
        //             image: "https://app.emtool.eu/public/_lib/img/icon-ok-smartbook.png",
        //             link: "/timesheet",
        //             id: "btn_confirm",
        //             disabled: false,
        //             size: 30,
        //             title: "Conferma"
        //         },


        //         ];

        //         this.$root.$children[0].bnavbuttons = pulsantis;

        //         setTimeout(() => {
        //             //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
        //             //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
        //         }, 100);

        //         // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
        //     }, 100);

        // },

        setupButtons: async function (v_show) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_show) {

                    pulsantis.push({
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/icon-ok-smartbook.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        size: 30,
                        title: "Conferma"
                    });

                }



                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },

        validate() {

            var that = this;

            if (that.selGruppo == null) {

                that.$swal({
                    icon: "error",
                    text: "Per favore selezionare il gruppo",
                    showConfirmButton: false,
                    timer: 2000
                });


            }
            else {

                window.$cookies.set("sel_gruppo_mail_gruppi", that.selGruppo, "9y");

                router.push({
                    path: "/mailGruppi"
                });



            }




        },

        saveData: async function () {

            var that = this;

            console.log("ID: " + that.$route.params.id);

            var response = null;

            if (that.$route.params.id == 0) {


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apimedico.insertMedico(
                    that.medico,
                    $("#nuclei").val()
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertMedico", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/medici"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );





            }

            if (that.$route.params.id > 0) {


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apimedico.updateMedico(
                    that.medico,
                    $("#nuclei").val(),
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateMedico", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/medici"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }



        },

        syncGruppi: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimailgruppi.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getGruppi", res);

                try {


                    if (res.data.gruppi != null) {

                        window.$cookies.set("n_gruppi", res.data.gruppi.length, "9y");

                        if (res.data.gruppi.length > 1) {

                            this.gruppi = res.data.gruppi;


                        }
                        else if (res.data.gruppi.length == 1) {

                            //console.log("ID TIPOLOGIE: ", res.data.tipologie[0].id);

                            window.$cookies.set("sel_gruppo_mail_gruppi", res.data.gruppi[0].idresources_group_name, "9y");

                            router.push({
                                path: "/mailGruppi"
                            });


                        }


                    }





                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editParente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editParente .v-input {
    font-size: 1.2em;
}

.editParente .v-select {
    font-size: 1.2em;
}


.editParente .v-label {
    font-size: 1em;
}


@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
