<template>
    <div class="cont-table" style="max-width:90vw">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>
        <div class="row cont_tickets contListBookingRsa">
            <div class=" " style="margin:0 auto;">
                <div class="card">
                    <div class="card-body" style="padding:0px">
                        <div class="table-responsive" style="">
                            <table id="myTable" style="">
                                <thead>
                                    <tr>

                                        <th class='all' style='text-align:right!important;'> </th>

                                        <th class='all'>Ospite</th>

                                        <th class='desktop'>Data</th>
                                        <th class='all'>Ora</th>
                                        <th class='all'>Nucleo</th>
                                        <th class='desktop'> Nominativo </th>

                                        <th class='all'>Stato</th>

                                        <th class='desktop'> E-Mail</th>
                                        <th class='desktop'> Telefono</th>
                                        <th class='desktop'> C. Fiscale</th>
                                        <th class='none'> Note</th>
                                        <th class='desktop'> Descrizione</th>
                                        <th style="text-align:center;" class='desktop'>Device</th>
                                        <th style="min-width:10px;" class='desktop'></th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="14">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modals">

            <div class="modal fade" id="exampleModalReport" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true" style="top: -914px;
    display: none;
    margin: 0px auto;
    border: none;
    padding: 0;
    box-shadow: none;
    background:none;
    max-width:770px!important;
    max-height:700px!important">
                <div class="modal-dialog" role="document" style="min-width:380px;max-width:770px">
                    <div class="modal-content">
                        <div class="modal-header" style="padding:3px 3px 3px 3px!important">
                            <h5 class="modal-title title-table" id="exampleModalLabel" style="padding-top:7px">Modal
                                title</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                style="display:none">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <input type="hidden" id="hid_nome_file_pdf" />

                        <div
                            style="width:100%;height:30px;background-color:#96959b!important;background-image: linear-gradient(to right,#e0dee6 , #96959b)">
                            &nbsp;</div>

                        <div class="modal-body">

                            <div class="form-group" style="clear:both;max-height:450px;
          overflow-y: auto;
          min-height: 124px;" id="">

                                <iframe id="pdf" style="width:620px!important;height:519px!important"></iframe>

                            </div>

                        </div>

                        <div class="modal-footer">

                            <img style="margin:0 auto;cursor: pointer;width:28px" class="close-condition-alert"
                                src="https://app.emtool.eu/_lib/img/cancel_32.png" />

                            <img style="margin: 0 auto;cursor: pointer" class="submit-condition-alert"
                                src="https://app.emtool.eu/_lib/img/send_mail_rpt_sb.png" />

                        </div>
                    </div>
                </div>
            </div><!-- {{--Ajax Medium Modal Ends--}} -->

            <!-- {{--Ajax Medium Modal--}} -->
            <div class="modal fade" id="exampleModalReportChooseGroup" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true"
                style="top: -693px;display: none;margin: 0px auto;
                border: none;padding: 0;box-shadow: none;background:none;max-width:700px!important;max-height:600px!important">
                <div class="modal-dialog" role="document" style="min-width:380px;max-width:700px">
                    <div class="modal-content">
                        <div class="modal-header" style="padding:3px 3px 3px 3px!important">
                            <h5 class="modal-title title-table" id="exampleModalLabel" style="padding-top:7px"></h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                style="display:none">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            style="width:100%;height:30px;background-color:#96959b!important;background-image: linear-gradient(to right,#e0dee6 , #96959b)">
                            &nbsp;</div>

                        <div class="modal-body">

                            <input type="hidden" id="hid_nome_file_pdf_send" />

                            <div class="form-group" style="margin-top:15px">

                                <ion-item style="min-width:200px;transform: translateX(-6px);">
                                    <ion-label style="font-size:14px;" position="floating">@lang("app.group_dest_mail")
                                    </ion-label>
                                    <ion-select name="tipo" id="sel_gruppo_conferma" class='bookingtype' cancel-Text=""
                                        done-Text="" value="" interface="action-sheet">

                                        <!--       @foreach (  $groups_rpt as    $group   )

                    <ion-select-option value="{{ $group->idresources_group_name }}">{{  $group->name  }}</ion-select-option>

                    @endforeach -->

                                    </ion-select>
                                </ion-item>

                            </div>

                        </div>
                        <div class="modal-footer">

                            <img style="margin:0 auto;cursor: pointer;width:28px" class="close-condition-alert"
                                src="https://app.emtool.eu/_lib/img/cancel_32.png" />

                            <img style="margin: 0 auto;cursor: pointer" class="submit-condition-send-mail"
                                src="https://app.emtool.eu/_lib/img/usr__NM__icon_ok_32.png" />

                        </div>
                    </div>
                </div>
            </div>



            <div class="contpopupdate" style="display:none">

                <template>
                    <modal name="popupDate" :clickToClose="false" :width="300" :height="322">


                        <v-row>

                            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                                Data

                            </v-col>

                        </v-row>



                        <v-row>

                            <v-col cols="12" md="12" style="margin-top:10px!important">

                                <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                    offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field id="fldInpData" v-model="dataDa" label="Dal" persistent-hint readonly
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dataDa" no-title @input="showPicker = false" locale="it">
                                    </v-date-picker>
                                </v-menu>

                            </v-col>

                        </v-row>


                        <v-row>

                            <v-col cols="12" md="12" style="margin-top:10px!important">

                                <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition"
                                    offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field id="fldInpData" v-model="dataA" label="Al" persistent-hint readonly
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dataA" no-title @input="showPicker2 = false" locale="it">
                                    </v-date-picker>
                                </v-menu>

                            </v-col>

                        </v-row>



                        <v-row>

                            <v-col cols="12" md="12">

                                <div style="display:flex;justify-content:center">

                                    <v-img alt="" class="btn-cancel-date" contain src="@/assets/btn_cancel3_smartbook.png"
                                        max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                        transition="scale-transition" title="Annulla" @click="btnCancelDate" />


                                    <v-img alt="" class="btn-reset-date" contain src="@/assets/reset.png" max-width="22"
                                        max-height="22" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                        transition="scale-transition" title="Pulisci" @click="btnResetDate" />

                                    <v-img alt="" class="btn-confirm-date" contain src="@/assets/btn_confirm3_smartbook.png"
                                        max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                        title="Conferma" @click="btnConfirmDate" />

                                </div>

                            </v-col>

                        </v-row>

                    </modal>
                </template>

            </div>



            <div class="contpopupsearch" style="display:none">

                <template>
                    <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                        <v-row>

                            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                                <v-img alt="" src="@/assets/smartbook_ricerca3.png" max-width="25" max-height="25" />

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="12">

                                <v-text-field v-model="textSearch" label="">
                                </v-text-field>

                            </v-col>

                        </v-row>



                        <v-row>

                            <v-col cols="12" md="12">

                                <div style="display:flex;justify-content:center">

                                    <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_smartbook.png"
                                        max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                        transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                    <v-img alt="" class="btn-confirm-search" contain
                                        src="@/assets/btn_confirm3_smartbook.png" max-width="30" max-height="30"
                                        style="cursor:pointer" transition="scale-transition" title="Conferma"
                                        @click="btnConfirmSearch" />


                                </div>

                            </v-col>

                        </v-row>

                    </modal>
                </template>

            </div>






        </div>
        <!--endmodsal-->

    </div>
</template>

<script>
import apibookingrsa from "../utils/bookingrsa/apibookingrsa";
import apiusers from "../utils/users/apiusers";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var that = this;

        var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));

        // var namesurname = window.$cookies.get("namesurname");

        console.log("settSmartbook", settSmartbook[0]);

        //   console.log(pathLocal, pointerVue, date, $futureTime);
        window.cid = settSmartbook[0].azienda_id;
        window.settSmartbook = settSmartbook[0];

        window.curSrcText = "";


        window.curStatus = 0;

        var b = window.$cookies.get("b");
        var $rotta = "";
        if (b == "S" || b == 'Y') {
            $rotta = "/listBookingRsa";

        } else {
            $rotta = "/bookingRsa";

            //  alert ( $rotta );

            setTimeout(() => {
                router.push({
                    path: $rotta
                });

            }, 300);

            //     this.$router.go( $rotta );
            //   return false ;
        }
        console.log($rotta);

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");
                    that.isMobileApp = true;

                } catch (error) {
                    that.isMobileApp = false;
                    // alert(error);

                }

                /*   if ( that.isMobileApp )
                  {

                  } */

            }
        );

        $(document).ready(function () {


            $('body').on('click', '.calendarButton', function () {


                console.log("CALENDARBUTTON");

                var dateTest = new Date();

                dateTest = dateTest.setDate(dateTest.getDate());
                const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

                console.log("DATE: " + dateTest2) //2015-07-23

                var elements = dateTest2.split("-");


                console.log("ELEMENTS: ", elements) //2015-07-23

                var dateStart = elements[0] + "-" + elements[1] + "-01";

                console.log("DATE START: ", dateStart) //2015-07-23

                //var dateEnd = new Date(elements[0], elements[1], 0).getDate();
                var dayEnd = new Date(2022, 10, 0).getDate();

                var dateEnd = elements[0] + "-" + elements[1] + "-" + dayEnd;


                console.log("DATE END: ", dateEnd) //2015-07-23



                that.dataDa = dateStart;

                that.dataA = dateEnd;

                $(".contpopupdate").show();

                that.$modal.show('popupDate');


            });




            $('body').on('click', '.close-condition-alert', function () {
                $(".close").trigger("click");
            });

            $('body').on('click', '#export-pdf', function () {

                $(".downloadspinner").show();
                // alert ( window.cid );

                var apiexportpdf = that.getUrlDomainBase("export-grid-to-pdf-api") + "",
                    data = {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        cid: window.cid,

                    };

                $.ajax({
                    url: apiexportpdf,
                    data: data,
                    success: function (data) {

                        $(".downloadspinner").hide();

                        var res = data.Result;
                        var error_msg = data.Errore;
                        var val_url = data.ValUrl;
                        var val_content = data.ValContent;
                        var nome_file_pdf = data.NomeFilePdf;
                        console.log(error_msg);
                        if (res == "OK") {

                            window.os = getMobileOperatingSystem();

                            console.log(window.os);

                            if (window.os == 'Android' || window.os == 'iOS') // mobile
                            {

                                openPopupReport(val_content, nome_file_pdf);

                            }

                            if (window.os != 'Android' && window.os != 'iOS') {

                                window.open(val_url, '_blank');

                            }

                        }

                    }

                });

            });

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_qrcode":
                    router.push({
                        path: "/qrconfirm"
                    });
                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_info":
                    router.push({
                        path: "/info"
                    });
                    break;

                case "btn_pdf":

                    //get operating system
                    window.os = this.getMobileOperatingSystem();

                    this.createPdf(true);

                    break;

                case "btn_mail":
                    this.createPdf(false).then((res) => {

                        console.log(res);
                        this.$router.push({
                            path: "mailGruppiChoicePdf"
                        })
                    }

                    );

                    break;

                default:
                    break;
            }
        });

        this.getLastLogin();

        this.setupGrid();
        this.setupButtons();

    },

    data: () => ({

        textSearch: "",

        dataDa: null,
        dataA: null,

        showPicker2: false,

        isMobileApp: false,
        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "smartbook"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            $(".spin").show();

            window.table.ajax.reload()

        },


        btnCancelDate: function () {

            this.$modal.hide('popupDate');

        },

        btnResetDate: function () {

            this.dataDa = null;
            this.dataA = null;

        },

        btnConfirmDate: function () {

            this.$modal.hide('popupDate');

            console.log("DATA DAL: ", this.dataDa);
            console.log("DATA AL: ", this.dataA);

            window.currDay = this.dataDa;

            window.currDayTo = this.dataA;

            $(".spin").show();

            window.table.ajax.reload()


        },

        openPopupReport: function (v_content, v_nome_file_pdf) {

            $("#exampleModalReport").modal();

            setTimeout(() => {

                $("#pdf").contents().find("body").html("");

                document.querySelector('iframe').contentDocument.write(v_content)

                $("#pdf").css("width", "100%");

                $("#hid_nome_file_pdf").val(v_nome_file_pdf);

            }, 500);

        },

        getMobileOperatingSystem: function () {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/smartbook/" + nome_metodo;

        },

        getUrlDomainBase: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        setupGrid: function () {

            var that = this;
            var pathLocal = that.$withBase('/');

            var pointerVue = this;

            var dateTest = new Date();

            //console.log("DATE START: ", dateTest.getDate());

            dateTest = dateTest.setDate(dateTest.getDate());
            const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            //var elements = dateTest2.split("-");


            // console.log("ELEMENTS: ", elements) //2015-07-23

            //var dateStart = elements[0] + "-" + elements[1] + "-01";

            // console.log("DATE START: ", dateStart) //2015-07-23

            //var dateEnd = new Date(elements[0], elements[1], 0).getDate();
            //var dayEnd = new Date(2022, 10, 0).getDate();

            //var dateEnd = elements[0] + "-" + elements[1] + "-" + dayEnd;


            //  console.log("DATE END: ", dateEnd) //2015-07-23


            const date = new Date().toJSON().slice(0, 10)


            // var month = 0; // January
            // var d = new Date(2022, month, 0);

            // console.log("DATE STR: ", d.toString()); 

            // d = d.setDate(d.getDate());
            // const dateTest3 = new Date(d).toJSON().slice(0, 10);

            // console.log("DATE 2: ", new Date(2022, 2, 0).getDate()); // last day in January

            //console.log("DATE: " + date) //2015-07-23

            var $futureTime = dateTest2;

            console.log($futureTime);

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                var v_cur_cmp = window.$cookies.get("curcmp");

               
                window.perpage = 50;

                window.columns = [{
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                    // targets: 0,
                },

                {
                    data: 'namepaziente',
                    orderable: true,
                    name: 'namepaziente',
                    width: '3%'
                },
                {
                    data: 'data',
                    orderable: true,
                    name: 'data',
                    width: '6%'
                },
                {
                    data: 'booking_date_time',
                    orderable: true,
                    name: 'booking_date_time',
                    width: '6%',
                },
                {
                    data: 'settorepiano',
                    orderable: true,
                    name: 'settorepiano',
                    width: '6%'
                },
                {
                    data: 'composite',
                    orderable: true,
                    name: 'composite',
                    width: '10%'
                },
                {
                    data: 'status',
                    orderable: false,
                    name: 'status',
                    width: '3%'
                },

                {
                    data: 'email',
                    orderable: false,
                    name: 'email',
                    width: '5%'
                },
                {
                    data: 'phone',
                    orderable: false,
                    name: 'phone',
                    width: '5%'
                },
                {
                    data: 'cod_fis_ospite',
                    orderable: false,
                    name: 'cod_fis_ospite',
                    width: '6%'
                },
                {
                    data: 'note',
                    orderable: false,
                    name: 'note',
                    width: '6%'
                },
                {
                    data: 'description',
                    orderable: false,
                    name: 'description',
                    width: '6%'
                },
                {
                    data: 'tipology',
                    orderable: false,
                    name: 'tipology',
                    width: '1%'
                },

                {
                    "targets": 0,
                    "data": "id",
                    width: '1%',
                    "visible": true,
                    render: function () {
                        return '';
                    }

                },

                ];
                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,
                    processing: false,
                    paging: false,
                    serverSide: false,


                    // da cambiare per le prossime rsa

                    ajax: {

                 
                        url: pointerVue.getUrlDomain("getListingRSA") + '?v_user=' + v_token + '&id_azienda=' + v_cur_cmp,

                        data: function (valori) {

                            window.disable_scroll = true;

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start_ = window.perpage;


                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.start_ = 0;
                            valori.length_ = window.perpage;

                            valori.curSrcText = window.curSrcText;


                            // var valSearch = $("#myTable_filter input[type=search]").val();
                            // var search = {
                            //     "value": $("#myTable_filter input[type=search]").val(),
                            //     "regex": false
                            // };
                            // if (valSearch.length > 0) {
                            //     valori.search = search;
                            //     valori.columns = window.columns;
                            // }



                            window.valori = valori;
                            return valori;
                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        setTimeout(() => {
                            window.disable_scroll = false;
                        }, 100);

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            /*  var id = $(this).data("id");
                             (this).pickerOptions = {
                                 buttons: [{
                                         text: 'Cancel',
                                         role: 'cancel',
                                         cssClass: 'switch-btn-cancel',
                                     },
                                     {
                                         text: 'Done',
                                         cssClass: 'switch-btn-done',
                                         handler: columns => {
                                             console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                             var valore = columns.hour.text + ":" + columns.minute.text;

                                             console.log(valore);

                                             $.ajax({
                                                 url: pointerVue.getUrlDomain("updateHour"),
                                                 data: {
                                                     "id": id,
                                                     "hour": valore,
                                                     "v_user": "ZmFnb3N0b25p"
                                                 },
                                                 success: function () {}
                                             });

                                         }
                                     },
                                 ],
                             }; */
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");


                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                // da cambiare per le prossime rsa

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table.ajax.reload()
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });

                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {

                    if (window.disable_scroll == false) {

                        if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                            if (window.loading == 0) {
                                $(".spin").show();
                                var valori = window.valori;
                                valori.start_ = window.start_;

                                // parametri da cambiare per le prossime rsa

                                $.ajax({

                                    url: pointerVue.getUrlDomain("getListingRSA") + '?v_user=' + v_token + '&id_azienda=' + v_cur_cmp,

                                    data: window.valori,
                                    success: function (data) {
                                        $(".spin").hide();
                                        window.loading = 0;
                                        window.start_ += window.perpage;

                                        for (let [index, val] of data.data.entries()) {
                                            console.log(index, val);
                                            window.table.row.add(val).draw(false);
                                            //   window.dataset.push(val);
                                        }
                                        console.log(data.data);
                                    },
                                });
                            }
                            window.loading = 1;
                        }


                    }



                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                var sel = ` <div title="Filtro Stato"> <ion-item>
                <ion-select   data-id="0"  value="0" class="selectstatusall" interface="action-sheet" placeholder=" "><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='0'>In attesa</ion-select-option><ion-select-option  value='1'>Confermato</ion-select-option><ion-select-option  value='2'>Disdetto cliente</ion-select-option><ion-select-option  value='3'>Disdetto ammin.</ion-select-option></ion-select></ion-item> </div>
                  `;

                var sel2 = `<div title="Filtro Nucleo">  <ion-select   data-id="0"  value="-1" class="selectpianiall" interface="action-sheet" placeholder=" "><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='NUCLEO 1 A'>NUCLEO 1 A</ion-select-option><ion-select-option  value='NUCLEO 1 B'>NUCLEO 1 B</ion-select-option><ion-select-option  value='NUCLEO 2 A'>NUCLEO 2 A</ion-select-option><ion-select-option  value='NUCLEO 3 A'>NUCLEO 3 A</ion-select-option><ion-select-option  value='NUCLEO ALZHEIMER'>NUCLEO ALZHEIMER</ion-select-option><ion-select-option  value='NUCLEO TERRA B'>NUCLEO TERRA B</ion-select-option></ion-select></ion-item> </div>
`;



                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">
                    <ion-icon class='dayleft' ios="ios-arrow-dropleft" style="z-index:-2;display:none;cursor:pointer;font-size:32px;" md="md-arrow-dropleft"></ion-icon>

        
                    <ion-icon  class='dayright' style="display:none;font-size:32px;cursor:pointer;" ios="ios-arrow-dropright" md="md-arrow-dropright"></ion-icon>

                    <img src='` + pathLocal + `calendario-smartbook.png' class="calendarButton" title="Filtro Data" />

                    <img src='` + pathLocal + `refresh-smartbook.png' class="refreshButton" title="Aggiorna" />
                    ` + sel + sel2 + `

                </div>
                <div><img src='` + pathLocal + `ricerca-smartbook.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);


                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table.ajax.reload()

                });

                //  }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        //   window.table.fnPageChange(0);
                        window.table.ajax.reload()

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        // window.table.fnPageChange(0);
                        window.table.ajax.reload()

                    });

                    /*
                    var elements = jQuery('.datetimechangeday');
                    console.log ( elements , "testa" );
                    var id = $(this).data("id")  ;*/

                    window.currDay = date;
                    window.currDayTo = date;

                    // alert(window.currDay);

                    document.querySelector('.datetimechangeday').pickerOptions = {
                        buttons: [{
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'switch-btn-cancel',
                        },
                        {
                            text: 'Done',
                            cssClass: 'switch-btn-done',
                            handler: columns => {
                                var id = 0;
                                console.log("ore minuti id", columns, id);
                                var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                                window.currDay = valore;
                                document.querySelector('.datetimechangeday').value = valore;

                                window.currDayTo = valore;
                                document.querySelector('.datetimechangedayTo').value = valore;

                                $(".spin").show();
                                //  window.table.fnPageChange(0);
                                window.table.ajax.reload()
                            }
                        },
                        ],
                    };

                    document.querySelector('.datetimechangedayTo').pickerOptions = {
                        buttons: [{
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'switch-btn-cancel',
                        },
                        {
                            text: 'Done',
                            cssClass: 'switch-btn-done',
                            handler: columns => {
                                var id = 0;
                                console.log("ore minuti id", columns, id);
                                var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                                window.currDayTo = valore;
                                document.querySelector('.datetimechangedayTo').value = valore;
                                //update From

                                // window.currDay = valore ;
                                // document.querySelector('.datetimechangeday').value = valore ;

                                //    window.table.fnPageChange(0);
                                $(".spin").show();
                                window.table.ajax.reload()
                            }
                        },
                        ],
                    };

                }, 600);

                window.listBookingRsaAddedEvents = true;

            });

        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                // var pulsantis = [


                //     {
                //         text: "Mail",
                //         icon: "mdi-email-send",
                //         image: "https://app.emtool.eu/public/_lib/img/export_pdf_smartbook.png",
                //         //classs: "inverted",
                //         link: "/pdf",
                //         id: "btn_mail",
                //         disabled: false,
                //         size: 25
                //     },

                // ];


                var pulsantis = [];

                if (this.isMobileApp) {

                    pulsantis.push(

                        {
                            text: "Mail",
                            icon: "mdi-email-send",
                            image: "https://app.emtool.eu/public/_lib/img/export_pdf_smartbook.png",
                            //classs: "inverted",
                            link: "/pdf",
                            id: "btn_mail",
                            disabled: false,
                            size: 25
                        },

                    );


                }





                if (!this.isMobileApp) {

                    pulsantis.push(

                        {
                            text: "Pdf",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/pdf-smartbook.png",
                            // class: "inverted",
                            link: "/pdf",
                            id: "btn_pdf",
                            disabled: false,
                            size: 30,
                            title: "Report Prenotazioni"
                        },

                    );

                }

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        createPdf: async function (open) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            // valori.currDayTo = window.currDayTo;
            // valori.curStatus = window.curStatus;
            // valori.curPiano = window.curPiano;

            var response = await apibookingrsa.getPdfPrenotazioni(
                "ZmFnb3N0b25p",
                3165,
                window.currDay,
                window.currDayTo,
                window.curStatus,
                window.curPiano,
                ""
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPdfPrenotazioni", res);

                try {

                    console.log(res.data.Result);
                    var val_content = res.data.v_table;
                    var nome_file_pdf = res.data.Result;
                    console.log(val_content, nome_file_pdf);
                    if (window.os == 'Android' || window.os == 'iOS') // mobile
                    {
                        //this.openPopupReport(val_content, nome_file_pdf);
                        if (open)
                            window.open(res.data.Result, '_blank');
                    }

                    if (window.os != 'Android' && window.os != 'iOS') {
                        if (open)
                            window.open(res.data.Result, '_blank');
                    }

                    if (!open)
                        var pdfpath = res.data.Result;
                    window.$cookies.set("pdfpath", pdfpath, "9y");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non Ã¨ stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        }

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.contListBookingRsa #myTable {
    margin-right: 3px;
}

.contListBookingRsa table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.contListBookingRsa table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 20px !important;
}

.contListBookingRsa th {
    padding-left: 5px !important;
}

.contListBookingRsa ion-datetime.dateBook {
    margin-left: 16px;
}

.contListBookingRsa .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.contListBookingRsa #myTable_filter {
    top: 64px !important;
    position: fixed !important;
    left: 0px !important;
    min-width: 100% !important;
    z-index: 333 !important;
    min-height: 46px !important;
    height: 46px !important;
    background: #ffffff !important;
    border-color: #de7d37 !important;
}

.contListBookingRsa .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.contListBookingRsa .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/regolazione-smartbook.png);
    text-indent: -119px;
    background-size: 25px;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -2px;
}

.contListBookingRsa .selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/seleziona-piano-smartbook.png);
    text-indent: -119px;
    background-size: 26px;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -4px;
}



.contListBookingRsa .refreshButton {
    max-width: 28px;
    margin-left: 18px;
    transform: translateY(-1px);
    cursor: pointer;
    max-height: 22px;
    margin-top: 3px;
}


#myTable_wrapper {

    min-height: 84vh;
    padding-top: 49px;
}

#myTable,
#myTable_wrapper {
    max-width: 100vw;
    /* min-width: 100vw; */
}

.contListBookingRsa .calendarButton {
    cursor: pointer;
    width: 25px;
}

.contpopupdate .v-input {
    font-size: 0.9em !important;
    padding: 10px !important;
}

.contpopupsearch .v-input {
    font-size: 0.9em !important;
    padding: 10px !important;
}



@media screen and (max-width: 960px) {
    .contListBookingRsa #myTable {
        /* margin-top:54px; */
    }

    .contListBookingRsa #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .contListBookingGen .table-responsive {

        /*   min-width: 60vw;
            position: fixed;
            left:0px; */
        top: 56px;

    }

    .contListBookingRsa #myTable_filter {
        top: 56px !important;
        position: fixed !important;
        left: 0px !important;
        min-width: 100% !important;
        z-index: 333 !important;
        min-height: 46px !important;
        height: 46px !important;
        background: #ffffff !important;
        border-color: #de7d37 !important;
    }




}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .contListBookingRsa .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;

    }

    .contListBookingRsa #myTable {
        max-width: 100vw;
        margin-right: 0px;

        min-width: 100vw;
        position: relative;
        /*   left: 12px; */
        width: 100vw !important;

    }

    .contListBookingRsa #myTable_wrapper {

        min-height: 84vh;
        padding-top: 49px;
    }

}

div#exampleModalReport {
    min-width: 100vw;
}

@media only screen and (max-width: 1500px) {

    div#exampleModalReport {
        top: -15px !important;
        position: absolute !important;
    }

    div#exampleModalReportChooseGroup {
        top: -15px !important;
        position: absolute !important;
    }

    #col_group_mitt .select2 {
        max-width: 230px !important;
        min-width: 230px !important;
    }

}
</style>
