<template>
    <div>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">

                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <!-- START -->
                                <div class="sidemenu__sub-items-container" id="menu-12">
                                    <div class="sidemenu__group-container">


                                        <div v-if="viewConfigurazioneOrari == 1 || viewConfigurazione == 1 || viewEccezzioni == 1 || viewMailGruppi == 1 || viewMedici == 1 || viewParenti == 1 || viewSlider == 1"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezImpostazioni" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Impostazioni</div>

                                        </div>

                                        <div style="border-top: 1px solid #de7d37;" v-if="viewSezImpostazioni == 'S'">

                                            <ul class="group__items">

                                                <a v-if="viewConfigurazioneOrari == 1" @click="goto('/confOrariChoice')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Configurazione Orari" max-width="32" max-height="32"
                                                        class=" " contain src="@/assets/confg_orari_smartbook.png" />

                                                    <span class="subitem__text">Configurazione Orari</span>
                                                </a>

                                                <a v-if="viewConfigurazione == 1" @click="goto('/settings')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Configurazione" max-width="32" max-height="32" class=" "
                                                        contain src="@/assets/config_smartbook.png" />

                                                    <span class="subitem__text">Configurazione</span>
                                                </a>

                                                <a v-if="viewEccezzioni == 1" @click="gotoEccezzioni()"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Eccezioni" max-width="32" max-height="32" class=" "
                                                        contain src="@/assets/eccezzioni_smartbook.png" />

                                                    <span class="subitem__text">Eccezioni</span>
                                                </a>

                                                <a v-if="viewMailGruppi == 1" @click="goto('/mailGruppiChoice')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Mail Gruppi" max-width="32" max-height="32" class=" "
                                                        contain src="@/assets/gruppi_mail_smartbook.png" />

                                                    <span class="subitem__text">Mail Gruppi</span>
                                                </a>

                                                <a v-if="viewMedici == 1" @click="goto('/medici')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Medici" max-width="32" max-height="32" class=" " contain
                                                        src="@/assets/medici_smartbook.png" />

                                                    <span class="subitem__text">Medici</span>
                                                </a>

                                                <a v-if="viewParenti == 1" @click="goto('/parenti')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Prenota" max-width="32" max-height="32" class=" "
                                                        contain src="@/assets/parenti_smartbook.png" />

                                                    <span class="subitem__text">Parenti</span>
                                                </a>



                                                <a v-if="viewSlider == 1" @click="goto('/backslider')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/slider_smartbook.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Slider</span>
                                                </a>





                                            </ul>

                                        </div>




                                        <div v-if="viewConferma == 1 || viewElenco == 1 || viewPrenota == 1 || viewStatistiche == 1 || viewTipologie == 1"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezPrenotazione" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Prenotazione</div>

                                        </div>


                                        <div style="border-top: 1px solid #de7d37;" v-if="viewSezPrenotazione == 'S'">

                                            <ul class="group__items">

                                                <a v-if="viewConferma == 1" @click="goto('/qrconfirm')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Conferma" max-width="32" max-height="32" class=" "
                                                        contain src="@/assets/conferma_smartbook.png" />

                                                    <span class="subitem__text">Conferma</span>
                                                </a>


                                                <a v-if="viewElenco == 1" @click="goto('/listbooking')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Prenota" max-width="32" max-height="32" class=" "
                                                        contain src="@/assets/elenco_smartbook.png" />
                                                    <span class="subitem__text">Elenco</span>
                                                </a>

                                                <a v-if="viewPrenota == 1" @click="goto('/prenota')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Prenota" max-width="32" max-height="32" class=" "
                                                        contain src="@/assets/prenota_smartbook.png" />

                                                    <span class="subitem__text">Prenota</span>
                                                </a>

                                                <a v-if="viewStatistiche == 1" @click="goto('/summary')"
                                                    mid="mnu_gest_summary" class="sidemenu__subitem" href="#">

                                                    <v-img alt="Statistiche" max-width="32" max-height="32" class=" "
                                                        contain src="@/assets/statistiche_smartbook.png" />

                                                    <span class="subitem__text">Statistiche</span>
                                                </a>

                                                <a v-if="viewTipologie == 1" @click="goto('/tipologieprenotazione')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Tipologie Prenotazione" max-width="32" max-height="32"
                                                        class=" " contain src="@/assets/tipologie_smartbook.png" />
                                                    <span class="subitem__text">Tipologie</span>
                                                </a>

                                            </ul>


                                        </div>



                                        <div v-if="viewUtenti == 1" class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezUtenti" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Utenti</div>

                                        </div>

                                        <div style="border-top: 1px solid #de7d37;" v-if="viewSezUtenti == 'S'">

                                            <ul class="group__items" v-if="!username.startsWith('guest')">


                                                <a v-if="viewUtenti == 1" @click="goto('/filterusers')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/utenti_smartbook.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Utenti</span>
                                                </a>

                                            </ul>

                                        </div>









                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div class="group__text" style="border-color: rgb(238, 120, 29);display:flex;justify-items: center;height: 40px;padding-top: 10px;">Applicazione
                                        </div>
                                        <ul class="group__items">



                                            <a @click="goto('/dash')" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Home Logo" class="  " contain
                                                    src="@/assets/home_smartbook.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Home</span>
                                            </a>


                                            <a @click="goto('/info')" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/info_smartbook.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Info</span>
                                            </a>


                                            <a @click="logout" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Logout" class="  " contain src="@/assets/esci_smartbook.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Esci</span>
                                            </a>




                                        </ul>
                                    </div>
                                </div>

                                <!-- END -->

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>
</template>

<script>

/*eslint-disable no-undef*/
import _ from 'lodash';
import router from ".././router";
export default {
    name: "menubottom",
    props: {},

    mounted: function () {

        this.setGlobal();
        var sec = window.$cookies.get("sector");

        var username = window.$cookies.get("username");
        if (username != null && username !== undefined)
            this.username = username;
        console.log("SEC: ", sec);

        this.sec = sec;
        //  alert ( sec );
        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        //console.log("SEC: ", sec);
        console.log("A: ", a);
        console.log("B: ", b);

        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
            this.isAdmin = 1;

        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }



        console.log("IS ADMIN: ", this.isAdmin);
        console.log("IS SU: ", this.isSU);

        // if ((this.sec == "RSA") && (this.isAdmin == 1)) {
        //     this.enableMenu = 1;
        //     this.enableMenuCond = 1;
        // }

        // if ((this.sec == "RISTORAZIONE" || sec == 'SERVIZI') && (this.isAdmin == 1)) {
        //     this.enableMenuCond = 1;
        // }

        if (this.isSU == 0) {

            this.viewConfigurazioneOrari = this.getEnableConf("config_orari_smartbook");
            this.viewConfigurazione = this.getEnableConf("configurazione_smartbook");
            this.viewEccezzioni = this.getEnableConf("eccezzioni_smartbook");
            this.viewMailGruppi = this.getEnableConf("mail_gruppi_smartbook");
            this.viewMedici = this.getEnableConf("medici_smartbook");
            this.viewParenti = this.getEnableConf("parenti_smartbook");
            this.viewSlider = this.getEnableConf("slider_smartbook");
            this.viewConferma = this.getEnableConf("conf_prenot_smartbook");
            this.viewElenco = this.getEnableConf("elenco_prenot_smartbook");
            this.viewPrenota = this.getEnableConf("prenota_smartbook");
            this.viewStatistiche = this.getEnableConf("statistiche_smartbook");
            this.viewTipologie = this.getEnableConf("tipologie_smartbook");
            this.viewProfilo = this.getEnableConf("profilo_smartbook");
            this.viewUtenti = this.getEnableConf("utenti_smartbook");


        }
        else {

            this.viewConfigurazioneOrari = 1;
            this.viewConfigurazione = 1;
            this.viewEccezzioni = 1;
            this.viewMailGruppi = 1;
            this.viewMedici = 1;
            this.viewParenti = 1;
            this.viewSlider = 1;
            this.viewConferma = 1;
            this.viewElenco = 1;
            this.viewPrenota = 1;
            this.viewStatistiche = 1;
            this.viewTipologie = 1;
            this.viewProfilo = 1;
            this.viewUtenti = 1;

        }


    },
    data() {

        return {

            viewSezImpostazioni: "N",
            viewSezPrenotazione: "N",
            viewSezUtenti: "N",

            viewConfigurazioneOrari: 0,
            viewConfigurazione: 0,
            viewEccezzioni: 0,
            viewMailGruppi: 0,
            viewMedici: 0,
            viewParenti: 0,
            viewSlider: 0,
            viewConferma: 0,
            viewElenco: 0,
            viewPrenota: 0,
            viewStatistiche: 0,
            viewTipologie: 0,
            viewProfilo: 0,
            viewUtenti: 0,



            username: "",
            sec: "",
            isAdmin: 0,
            isSU: 0,
            sheetAziende: false,
            currentFile: null,
            selecting: 0,
            currItem: {},
            currGruppo: {},
            switch1: true,
            nomeCorrente: "",
            titolo: "",
            body: "",
            aziende: [],
            gruppi: [],
            globalArrAziende: [],
            dipendenti: [],
            search: '',
            sheetGruppi: false,
            sheetEmpty: false,
            sheetDipendenti: false,
            searchDipendenti: '',
            searchGruppi: '',
            enableMenu: 0,
            enableMenuCond: 0,

        };
    },

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },
    created() {
        this.intervalHandle = setInterval(this.tick, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalHandle);
    },
    methods: {

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },


        forceUpdate() {
            var that = this;
            setTimeout(() => {

                that.$forceUpdate();
            }, 300);
        },


        gotoEccezzioni: function () {

            var sec = window.$cookies.get("sector");

            var link = "";

            if (sec == 'RSA') {

                link = "/eccezioni";

            }

            if (sec == 'RISTORAZIONE') {

                link = "/eccezioniRistorazioni";

            }


            $(".v-bottom-navigation button").show();

            console.log(router);
            this.sheetAziende = false;

            this.$router.push({
                path: link
            });


        },




        goto: function (link) {

            var sec = window.$cookies.get("sector");
            if (link == '/listbooking') {

                if (sec == 'RSA') link = "/listBookingRsa";
                if (sec == 'RISTORAZIONE' || sec == 'SERVIZI') link = "/listBookingGen";

            }

            if (link == '/prenota') {
                if (sec == 'RSA') link = "/bookingrsa";
                if (sec == 'RISTORAZIONE' || sec == "SERVIZI") link = "/bookinggen";

            }

            //before going

            $(".v-bottom-navigation button").show();
            //router.push( { path: link   }  );
            console.log(router);
            this.sheetAziende = false;

            this.$router.push({
                path: link
            });
            //this.$root.$children[0].$router.push({ path: link   }  );

            //  this.$router.push('/profile')

        },
        setGlobal: function () {

            this.aziende = this.$root.$children[0].aziende;
            this.dipendenti = this.$root.$children[0].dipendenti;
            this.gruppi = this.$root.$children[0].gruppi;
            this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        },

        openmenu: function () {

            this.setGlobal();

            this.sheetAziende = !this.sheetAziende;

            try {

                if (this.sheetAziende) {
                    $(".v-bottom-navigation button").hide();
                } else {
                    $(".v-bottom-navigation button").show();
                }

                $(".bottomBtnMenu").show();

            } catch (error) {
                console.log(error);
            }
        },
        manageClose: function () {

            $(".v-bottom-navigation button").show();
            // this.setupButtons();
            this.sheetAziende = false;
            // this.sheetEmpty = false;
            this.sheetDipendenti = false;
            // this.sheetGruppi = false;

        },

        logout: function () {
            this.sheetAziende = false;
            this.$root.$children[0].logout();
        },


    }
};
</script>

<style scoped>
/****** MENU ******/
.menu-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.menu__item .title,
.menu-icon .title {
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .05em;
}

.menu__settings-icon {
    height: 26px !important;
    width: 26px !important;
    margin-bottom: 4px !important;
    margin-top: 2px !important;
}

#sidemenu {

    z-index: 2;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    position: fixed;
    top: 54px;
    left: 0;
    background: transparent;
    bottom: 56px;
    padding: 28px 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

#sidemenu.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

#sidemenu .sidemenu__inner-wrapper {
    overflow: hidden;
    background: white;
    height: 100%;
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
}

.sidemenu__top-level-item {
    width: 83px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #efefef;
    opacity: 1;
}

sidemenu__top-level-item a {
    width: 80px;
}

.sidemenu__top-level-item>a {
    height: 80px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.sidemenu__top-level-item>a:before {
    position: absolute;
    top: 0;
    left: -3px;
    height: 100%;
    width: 3px;
    z-index: 1;
    content: '';
    display: block;
}

.sidemenu__top-level-item>a:hover {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    opacity: .7;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    display: block;
    margin-bottom: 4px;
    width: 30px;
}

.sidemenu__top-level-item:hover {
    background-color: #f9f9f9;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__icon {
    -webkit-filter: none;
    filter: none;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__text {
    opacity: 1;
}

/*
  .sidemenu__sub-items-container {
    z-index: -1;
    position: absolute;
    background: white;
    overflow-y: auto;
    top: 28px;
    left: 100%;

    width: 359px;
    bottom: 28px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    border: 1px solid #efefef;

    text-align: left;

  }*/
.sidemenu__sub-items-container .group__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1px;
    position: relative;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem,
.sidemenu__top-level-item>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    width: 84px
        /* 84px */
    ;
    height: 80px;
    position: relative;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
}

.sidemenu__subitem {
    width: 120px !important;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem:hover {
    background: #f9f9f9;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem img,
.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    width: 28px;
    margin-bottom: 20px;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    margin-bottom: 20px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem .subitem__text,
.sidemenu__top-level-item .sidemenu__top-level-item__text {
    position: absolute;
    top: 48px;
    max-width: 80px;
    font-size: 9.5px;
    line-height: 1.05em;
    font-weight: 500;
    color: #404040;
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    top: 50px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    line-height: 1.1em;
    font-weight: 600;
    font-size: 9px;
}

.sidemenu__sub-items-container .group__text {
    background-color: #f9f9f9;
    font-weight: 700;
    padding: 20px 16px 6px 16px;
    padding: 1px;

    font-size: 12px;
    color: rgba(0, 0, 0, 0.78);
    letter-spacing: 0px;
    border-bottom: 2px solid #f3f3f3;
    padding-left: 4px;
}

.sidemenu__sub-items-container.hidden {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.sidemenu__sub-items-container {
    z-index: 1;

    position: absolute;
    position: fixed;
    background: white;
    overflow-y: auto;
    width: 100vw;
    bottom: 0px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: inherit;


    justify-content: start;

    bottom: 56px;

    max-height: calc(100vh - 120px);

}

.sidemenu__group-container ul {
    padding-left: 0px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container img {
    max-width: 26px;
}

.v-image__image.v-image__image--contain {
    transform: translateY(-6px);
}

.sidemenu__group-container .v-image.v-responsive.theme--light {
    margin-bottom: 22px;
}
</style>

<style>
.clRowSectionMenu {
    display: flex;
    justify-items: center;
    height: 40px;
    padding-top: 7px !important;
    border-color: #f9f9f9;
}

.v-input--switch__track {
    border-radius: 3px !important;
    width: 35px !important;
    height: 7px !important;
    top: 10px !important;
    left: 6px !important;
}

.v-input--switch__thumb {
    left: 6px !important;
    height: 15px !important;
    width: 15px !important;
    top: 5px !important;
}

.custom-switch .v-input__slot .v-label {
    left: 6px !important
}

.v-input--selection-controls__ripple {
    height: 0 !important;
    width: 0 !important;
}
</style>