import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIBookingRSA {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getInfoPrenotazioni(v_user, v_id)
  {

    let formData = new FormData();

    formData.append("v_user",  v_user);
    formData.append("v_id",  v_id);

    return axios.post(this.getUrlDomain("getInfoPrenotazioni") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateBooking(v_user, v_id, v_new_val_num_pers, v_old_val_num_pers)
  {

    let formData = new FormData();

    formData.append("v_user",  v_user);
    formData.append("v_id",  v_id);
    formData.append("v_new_val_num_pers",  v_new_val_num_pers);
    formData.append("v_old_val_num_pers",  v_old_val_num_pers);

    return axios.post(this.getUrlDomain("updateBookingGen") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteBooking(v_user, v_id)
  {

    let formData = new FormData();

    formData.append("v_user",  v_user);
    formData.append("v_id",  v_id);

    return axios.post(this.getUrlDomain("deleteBookingGen") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



}

export default new APIBookingRSA({
  url: "http://emtool.local/public/api/smartbook/"
})
