<template>
    <div class="mainbookinggen" style="">

        <div class="cont-tables booking_gen" style=" ">

            <div class="spin">
                <ion-spinner color="primary" name="lines-small" v-pre></ion-spinner>
            </div>

            <div class="row cont_booking_rsa cont_book_pasq2">

                <div class="swiper-button-next" title="Avanti"></div>
                <div class="swiper-button-prev" title="Indietro"></div>


                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group centered">
                            <button type="button" id="btn-back2" class="btn-back2"><img
                                    src="https://app.emtool.eu/_lib/img/icon-back-smartbook.png" title="Indietro"
                                    style="width:35px" /></button>
                        </div>
                    </div>
                </div>


                <div class="col-md-12">
                    <div class="cards swiper-container mySwiper">
                        <div class="swiper-pagination"></div>

                        <div>
                        </div>
                        <div class="card-bodys swiper-wrapper" style="position:relative;">


                            <div class="one table-responsive  swiper-slide two" style="display:block">

                                <div class="row smallTitle" style="display:none;" styles="background:#ec771e;color:white;
                                                height: 20px;
                                                padding: 0;
                                                margin-left: -16px;
                                                min-width: 100px;
                                                padding-left: 15px;margin-bottom:5px">

                                    <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                                        Dati Prenotazione

                                    </div>
                                </div>
                                <div class="grid">
                                    <table id="myTableBook" class="table  ">
                                        <thead>
                                            <tr>
                                                <!-- <th class='all' style='text-align:right!important;'> </th> -->
                                                <th class='all'> &nbsp;</th>
                                                <th class='all'> Ora </th>
                                                <th class='all'> </th>
                                                <th class='all'> </th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th colspan="4">
                                                    <div class="table_paginate"> </div>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group centered">
                                            <button type="button" id="btn-confirm2" class="btn-confirm2"><img
                                                    src="https://app.emtool.eu/_lib/img/icon-ok-smartbook.png"
                                                    title="Conferma" style="width:30px" /></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label><b> </b></label>

                                            <div class="form-rows" style="display:none;">
                                                <div class="col-md-12 mb-2"
                                                    style="display: flex;align-content: center;align-items: center;">
                                                    <input readonly="readonly" placeholder="Data ora prenotazione"
                                                        type="text" id="booking_date_time" name="booking_date_time"
                                                        class="form-control" />
                                                </div>
                                            </div>

                                            <!--  @php
                                        $futureTime = strtotime("+365 days");
                                        $futureTime = date('Y-m-d' , $futureTime )
                                        @endphp -->

                                            <div class="form-rows" style="display:none;padding-left: 14px;">
                                                <div class="col-md-12 mb-2"
                                                    style="display: flex;align-content: center;align-items: center;">
                                                    <ion-datetime style="display:none;" cancel-Text="" done-Text=""
                                                        class='datetimechangeday' value='2022-02-02'></ion-datetime>

                                                    <ion-item>
                                                        <ion-label position="floating">Data prenotazione</ion-label>
                                                        <ion-datetime class='datebooking' cancel-Text="" done-Text=""
                                                            display-format="YYYY-MM-DD" min="2021-01-01"
                                                            :max="futureTime"></ion-datetime>
                                                    </ion-item>

                                                    <ion-item>
                                                        <ion-label position="floating">Ora </ion-label>
                                                        <ion-datetime class='hourbooking' cancel-Text="" done-Text=""
                                                            display-format="HH:mm"></ion-datetime>
                                                    </ion-item>

                                                    @php
                                                    $status = ['Giorno' => 'Giorno', 'Pomer./Sera' => 'Pomer./Sera'];
                                                    @endphp
                                                    <ion-item>
                                                        <ion-label position="floating">Periodo </ion-label>
                                                        <ion-select v-for="item in status" :key="item.id" id="period"
                                                            name="period" class='periodbooking' cancel-Text=""
                                                            done-Text="" value="Giorno">

                                                            <ion-select-option :value='item.key'>{{ item.value }}
                                                            </ion-select-option>

                                                        </ion-select>
                                                    </ion-item>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="one table-responsive  swiper-slide swipeAnagrafica swipeAnagraficaGeneric"
                                style="display:block">
                                <!--    <div class="title-table"> Prenotazione</div> -->

                                <div class="row smallTitle" styles="background:#ec771e;color:white;
                                                height: 20px;
                                                padding: 0;
                                                margin-left: -16px;
                                                min-width: 100px;
                                                padding-left: 15px;margin-bottom:5px">

                                    <div class="titleAnagrafica" style="">
                                        Prenotazione
                                    </div>
                                </div>

                                <div class="contanagrafica">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group" style="padding: 18px;">



                                                <label><b>Data</b></label>
                                                <div class="form-rows">
                                                    <div class="col-md-12 mb-2" style="display: flex;
                                                align-content: center;
                                                align-items: center;">
                                                        <input autocomplete="off" readonly
                                                            placeholder="Data prenotazione" type="text" id="data"
                                                            name="data" class="form-control" />

                                                    </div>
                                                    <div id="suggesstion-box"></div>

                                                </div>

                                                <label><b>Giorno</b></label>
                                                <div class="form-rows">
                                                    <div class="col-md-12 mb-2" style="display: flex;
                                                align-content: center;
                                                align-items: center;">
                                                        <input autocomplete="off" readonly
                                                            placeholder="Giorno prenotazione" type="text" id="giorno"
                                                            name="giorno" class="form-control" />

                                                    </div>
                                                    <div id="suggesstion-box"></div>

                                                </div>


                                                <label><b>Ora</b></label>
                                                <div class="form-rows">
                                                    <div class="col-md-12 mb-2" style="display: flex;
                                                align-content: center;
                                                align-items: center;">
                                                        <input autocomplete="off" readonly
                                                            placeholder="Ora prenotazione" type="text" id="ora"
                                                            name="ora" class="form-control" />

                                                    </div>
                                                    <div id="suggesstion-box"></div>

                                                </div>



                                                <label><b>Nome e cognome</b></label>
                                                <div class="form-rows">
                                                    <div class="col-md-12 mb-2" style="display: flex;
                                                align-content: center;
                                                align-items: center;">
                                                        <input autocomplete="off" placeholder="Nome e cognome"
                                                            :readonly="checkRo('nomeCognomeParente')" type="text"
                                                            id="name" name="name" class="form-control"
                                                            :value="nomeCognomeParente" />

                                                    </div>
                                                    <div id="suggesstion-box"></div>

                                                </div>

                                                <div class="form-rows" style="display:none;">
                                                    <div class="col-md-12 mb-2"
                                                        style="display: flex;align-content: center;align-items: center;">
                                                        <input placeholder="Cognome" type="text" id="surname"
                                                            name="surname" class="form-control" />
                                                    </div>

                                                </div>

                                                <div class="form-rows" style="margin-top:-15px">
                                                    <label><b>Telefono</b></label>
                                                    <div class="col-md-12 mb-2"
                                                        style="display: flex;align-content: center;align-items: center;">
                                                        <input :readonly="checkRo('phone')"
                                                            oninput="validity.valid||(value='');" placeholder="Phone"
                                                            step="1" min="0" max="12131231231231123" type="number"
                                                            :value="phone" id="phone" name="phone"
                                                            class="form-control" />
                                                    </div>
                                                </div>

                                                <div class="form-rows" style="margin-top:-15px">
                                                    <label><b>Mail</b></label>
                                                    <div class="col-md-12 mb-2"
                                                        style="display: flex;align-content: center;align-items: center;">
                                                        <input :readonly="checkRo('email')" placeholder="Email"
                                                            type="email" id="email" name="email" :rules="emailRules"
                                                            class="form-control" :value="email" />
                                                    </div>
                                                    <small style="display:none;margin-left:10px;" id="emailhelp"
                                                        class="text-danger">
                                                        Mail not valid.
                                                    </small>
                                                </div>

                                                <div class="form-rows" style="">
                                                    <label><b>Numero persone</b></label>
                                                    <div class="col-md-12 mb-2"
                                                        style="display: flex;align-content: center;align-items: center;">
                                                        <input oninput="validity.valid||(value='');" min="1" max="100"
                                                            placeholder="Numero persone" type="number" id="num_persons"
                                                            name="num_persons" class="form-control" />
                                                    </div>
                                                </div>

                                                <div v-if="isAdmin()" class="form-rows" style="">

                                                    <label><b>Postazione</b></label>
                                                    <div class="col-md-12 mb-2"
                                                        style="display: flex;align-content: center;align-items: center;">
                                                        <input placeholder="Postazione" type="text" id="postazione"
                                                            name="placement" class="form-control" />
                                                    </div>
                                                </div>

                                                <div class="form-rows">
                                                    <label><b>Note</b></label>
                                                    <div class="col-md-12 mb-2"
                                                        style="display: flex;align-content: center;align-items: center;">
                                                        <textarea style="min-height: 160px;" placeholder="Note"
                                                            id="note" name="note" class="form-control"></textarea>

                                                    </div>

                                                    <small style="display:none;margin-left:10px;" id="emailhelp"
                                                        class="text-danger">

                                                    </small>

                                                </div>

                                                <div class="form-rows" style="height: 155px;">&nbsp;</div>


                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group centered">
                                                <button type="button" id="btn-confirm" class="btn-confirm"><img
                                                        src="https://app.emtool.eu/_lib/img/icon-ok-smartbook.png"
                                                        title="Conferma" /></button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="buttons">
                <div class="con" style="max-width: 100px;
            text-align: center;">
                </div>
            </div>

        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" style="    top: -519px;
                display: none;
                margin: 0px auto;
                border: none;
                padding: 0;
                box-shadow: none;
                background:none;
                max-width:420px!important">
            <div class="modal-dialog" role="document" style="min-width:400px;">
                <div class="modal-content">
                    <div class="modal-header" style="padding:3px 3px 3px 3px!important">
                        <h5 class="modal-title title-table" id="exampleModalLabel" style="padding-top:7px">Modal title
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            style="display:none">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div
                        style="width:100%;height:30px;background-color:#96959b!important;background-image: linear-gradient(to right,#e0dee6 , #96959b)">
                        &nbsp;</div>
                    <div class="itemselected" style="width:100%;height:17px;padding-left:13px;padding-top:5px"></div>

                    <div class="projectsassociated modal-body"
                        style="max-height:150px;overflow-y: auto;min-height: 150px;">
                    </div>
                    <div class="modal-footer">
                        <img style="margin: 0 auto;cursor: pointer" class="submit-condition"
                            src="https://app.smart-book.it/_lib/img/usr__NM__icon_ok_32.png" />
                    </div>
                </div>
            </div>
        </div>



        <div class="contpopupdate" style="display:none">

            <template>
                <modal name="popupDate" :clickToClose="false" :width="300" :height="204">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Data

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="valData" label="" persistent-hint v-on="on"
                                        readonly></v-text-field>
                                </template>
                                <v-date-picker :first-day-of-week="1" day-name-format="dddd" v-model="valData"
                                    @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date" contain src="@/assets/btn_cancel3_smartbook.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelDate" />


                                <v-img alt="" class="btn-reset-date" contain src="@/assets/reset.png" max-width="22"
                                    max-height="22" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetDate" />

                                <v-img alt="" class="btn-confirm-date" contain src="@/assets/btn_confirm3_smartbook.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmDate" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>





    </div>
</template>

<script>
import apibookingrsa from "../utils/bookingrsa/apibookingrsa";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        this.$root.$children[0].viewUserInfo = false;



        $(".wrap_buttons_footer").addClass("wrap_buttons_footer2");


        setTimeout(() => {

            $("#btn_left").addClass("clButtonLeft");

            $("#btn_middle2").addClass("clButtonMiddle2");

        }, 500);






        $("#btn_right").css("visibility", "hidden");

        window.uname = window.$cookies.get("username");

        window.divSection = 0; // 0 griglia, 1 daati input prenotazione


        window.hourSelect = "";

        window.idPeriodSel = "";

        //alert ( window.uname ) ;

        var that = this;

        var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));
        console.log("settSmartbook", settSmartbook[0]);

        var is_admin_company = window.$cookies.get("b");

        if (is_admin_company == 'Y' || is_admin_company == 'S')
            this.$is_admin_company = 1;
        else
            this.$is_admin_company = 0;

        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);


        // $("#btn_middle2").css("visibility", "hidden");


        $(document).ready(

            function () {
                $("body").off();

                // var uname = "fagostoni";

                window.uname = window.$cookies.get("username");
                var uname = window.uname;




                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '.calendarButton', function () {


                $(".contpopupdate").show();

                that.$modal.show('popupDate');


            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        if (bus !== undefined)
            bus.$on("bottomIconClickEvent", (data) => {
                //this.header = data;
                console.log(data);
                switch (data) {

                    case "btn_exit":
                        router.push({
                            path: "/dash"
                        });
                        break;

                    case "btn_info":
                        router.push({
                            path: "/info"
                        });
                        break;

                    case "btn_pdf":

                        this.createPdf();

                        break;

                    default:
                        break;
                }
            });

        this.setupButtons();

        this.setupGrid();

        this.instanceTable();

        setTimeout(() => {
            this.mainInit();


        }, 500);


        $(".bkc_over").css("opacity", "0");


        if (window.innerWidth <= 768) {


            $(".v-toolbar__content").css("background-image", "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(https://trattoriadapasqualino.it/wp-content/uploads/2019/04/restaurant-hero-01.jpg)");
            $(".v-toolbar__content").css("background-size", "cover");
            $(".v-toolbar__content").css("height", "317px");
            $(".v-toolbar__content").css("align-items", "start");
            $(".curDipTop").css("margin-top", "18px");

        }
        else {


            $(".v-toolbar__content").css("background-image", "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(https://trattoriadapasqualino.it/wp-content/uploads/2019/04/restaurant-hero-01.jpg)");
            $(".v-toolbar__content").css("background-size", "cover");
            $(".v-toolbar__content").css("height", "514px");
            $(".v-toolbar__content").css("align-items", "start");
            $(".curDipTop").css("margin-top", "18px");

        }


        $(".clTitle").remove();

        $(".v-toolbar__content").append("<div class='clTitle'><div>Prenotazione</div></div>");


        $(".bottomBtnMenu").remove();

        // $("#no-background-hover").removeAttr('href');

        this.$root.$children[0].disableHome = true;


    },
    data() {
        var dt = new Date();
        dt.setDate(dt.getDate() + 3);

        dt = dt.toISOString().slice(0, 10).replace('T', ' ');

        var $status = [{
            'Giorno': 'Giorno'
        }, {
            'Pomer./Sera': 'Pomer./Sera'
        }];

        return {

            giorniSettimana: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],

            valData: null,

            is_admin_company: 0,
            status: $status,
            futureTime: dt,
            selSector: null,
            selSector2: null,
            settoridb: [],

            phone: "",
            tipi: [],
            is_2_ospite: "",
            ospite: {
                nome: "",
                codicefiscale: "",
                cognome: "",
            },
            ospite2: {
                nome: "",
                codicefiscale: "",
                cognome: "",
            },
            nomeCognomeParente: "",
            showPicker: false,
            profile: {},
            valid: true,
            initials: "",
            namesurname: "",
            iban: "",
            cellulare: "",
            codicefiscale: "",
            codicefiscaleRules: [
                (v) => !!v || "Codice fiscale is required",
                (v) =>
                    (v && v.length <= 16) ||
                    "Codice fiscale must be less than 16 characters",
            ],
            cellulareRules: [
                (v) => !!v || "Phone number is required",
                (v) =>
                    (v && v.length <= 30) || "Phone number must be less than 15 characters",
            ],
            genRules: [(v) => !!v || "Field required"],
            ibanRules: [
                (v) => !!v || "Iban is required",
                (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
            ],
            email: "",
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            select: null,
            items: ["Item 1", "Item 2", "Item 3", "Item 4"],
            checkbox: false,
        }
    },

    methods: {

        getDescDay() {

            var v_desc_day = "";

            var v_date = new Date(window.currDay);

            var indiceGiorno = v_date.getDay();

            v_desc_day = this.giorniSettimana[indiceGiorno];

            return v_desc_day;

        },



        handleInput(e) {
            const date = e.target.value;
            if (new Date(date).isValid()) {
                this.valData = date;
            }
        },

        checkBookingHour: async function (v_id, v_data) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibookingrsa.checkBookingHour(
                v_id,
                v_data
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkBookingHour", res);


                setTimeout(
                    function () {

                        // var dataora = window.currDay + " " + window.hourSelect;
                        // $("#data").val(dataora);

                        $("#data").val(window.currDay);

                        $("#ora").val(window.hourSelect);

                        $("#giorno").val(that.getDescDay());

                    }
                    , 600);




                setTimeout(function () {


                    $("#btn_middle2").css("visibility", "hidden");

                    $(".swiper-button-next").trigger("click");

                    //    $(".two").hide();
                    //    $(".swipeAnagrafica").show();


                }, 200);






                // setTimeout(
                //     function () {

                //         var dataora = $("tr.active ion-datas").text() + " " + $("tr.active ion-items").text();
                //         $("#data").val(dataora);
                //     }
                //     , 600);




                // setTimeout(function () {
                //     $(".swiper-button-next").trigger("click");
                // }, 200);






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        checkBookingHourOld: async function (v_id, v_data) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibookingrsa.checkBookingHour(
                v_id,
                v_data
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkBookingHour", res);


                if (res.data.Result == "OK") {

                    setTimeout(
                        function () {

                            var dataora = $("tr.active ion-datas").text() + " " + $("tr.active ion-items").text();
                            $("#data").val(dataora);
                        }
                        , 600);




                    setTimeout(function () {
                        $(".swiper-button-next").trigger("click");
                    }, 200);

                }
                else {


                    that.$swal

                        .fire({
                            title: "Attenzione",
                            html: res.data.ErrDetails,
                            icon: "warning",
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_smartbook.png' />",
                            cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_smartbook.png' />",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            customClass: {
                                cancelButton: 'order-1',
                                confirmButton: 'order-2',
                            }

                        })
                        .then(async function (result) {

                            if (result.value == true) {

                                console.log("ok");

                            }

                        });




                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        btnCancelDate: function () {

            this.$modal.hide('popupDate');

        },

        btnResetDate: function () {

            this.valData = null;

        },

        btnConfirmDate: function () {


            console.log("DATA: ", this.valData);

            var error = "";
            if (this.valData == null) {
                error = "Specificare una data";
            }
            else {
                if (this.valData == "") {
                    error = "Specificare una data";
                }
            }


            if (error == "") {

                this.$modal.hide('popupDate');


                $("#btn_middle2").css("visibility", "hidden");


                window.currDay = this.valData;

                window.lastDateSel = this.valData;

                $("#valDataSel").html(window.currDay);

                $("#valDescDataSel").html(this.getDescDay());

                $(".spin").show();

                window.table._fnDraw();
                disableNext();


            }
            else {


                this.$swal({
                    icon: 'error',
                    text: error,
                    timer: 2000,
                    showConfirmButton: false
                });


            }



        },



        isAdmin: function () {
            var b = this.$cookies.get("b");
            if (b == 'Y' || b == 'S') return true;
            else return false;

        },
        checkRo: function (field) {
            console.log(field);
            return false;
            /*   var b = this.$cookies.get("b");
              if (b == 'Y' || b == 'S') return false;

              console.log(field); */
        },

        instanceTable: function () {
            var that = this;

            var pathLocal = that.$withBase('/');

            var $mindaystobook = "";
            var date = new Date();
            var $curday = null;
            console.log($curday);
            if ($mindaystobook) {

                //$curday  = date('Y-m-d' , strtotime("+$mindaystobook days") );
                $curday = date.toISOString().slice(0, 10).replace('T', ' ');
            } else {
                //$curday = date('Y-m-d') ;
                $curday = date.toISOString().slice(0, 10).replace('T', ' ');
            }

            //init filters
            window.currDay = $curday;
            window.currDayTo = $curday;

            console.log("LAST DATE: ", window.lastDateSel);

            if (window.lastDateSel != undefined) {

                window.currDay = window.lastDateSel;
                window.currDayTo = window.lastDateSel;


            }




            window.curStatus = "0";

            that.valData = $curday;

            // alert(window.currDay);

            //init dattable

            var $status = [{
                '-1': 'Tutti'
            }, {
                '0': 'Libero'
            }, {
                1: 'Occupato'
            }];
            var $sel = '<ion-item style="visibility:visible;display:none;"><ion-select   data-id="0 value="0" class="selectstatusall" interface="action-sheet" placeholder=" ">';
            $status.forEach(element => {
                console.log(element)
                $sel += "<ion-select-option  value='" + element + "'>$st</ion-select-option>";
            });

            /*   foreach ($status as $k => $st) {
                  $sel += "<ion-select-option  value='$k'>$st</ion-select-option>";
              } */
            $sel += '</ion-select></ion-item>';

            console.log($sel);

            var basepath = this.getBasePath();
            window.basepath = this.getBasePath();

            window.table = $('#myTableBook').dataTable({
                responsive: true,
                processing: false,
                paging: false,
                serverSide: true,
                ajax: {
                    url: basepath + 'listAvailBookingGenericApi',
                    data: function (valori) {

                        valori.username = window.uname;
                        valori.cid = window.cid;
                        valori.is_su = window.is_su;
                        valori.bookingtype = $("#bookingtype").val();
                        valori.currDay = window.currDay;
                        valori.currDayTo = window.currDayTo;
                        valori.curStatus = window.curStatus;

                        return valori;
                    }
                },
                language: {
                    lengthMenu: `<label>Mostra <select name="myTableBook_length" aria-controls="myTableBook" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">@lang('app.tutti')</option></select> @lang('app.inserimenti')</label>`,
                    paginate: {
                        next: "<img style='height:15px;border:none' src='{{ url('/_lib/img/next2.png') }}'>",
                        previous: "<img style='height:15px;border:none' src='{{ url('/_lib/img/previous2.png') }}'>"
                    },
                    "sSearch": " <img title='Search' class='img_search' style='height:26px' src='{{ url('/_lib/img/search.png') }}'>",
                    processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                },
                "info": "",
                "fnDrawCallback": function (oSettings) {
                    console.log("oSettings", oSettings);
                    $("body").tooltip({
                        selector: '[data-toggle="tooltip"]'
                    });
                },
                order: [
                    [1, 'ASC']
                ],
                columns: [
                    {
                        data: 'sel_row',
                        orderable: false,
                        name: 'sel_row',
                        width: '10%'
                    },
                    {
                        data: 'composite',
                        orderable: false,
                        name: 'composite',
                        width: '20%'
                    },
                    // {
                    //     data: 'booking_date_time',
                    //     orderable: false,
                    //     name: 'booking_date_time',
                    //     width: '20%',
                    // },

                    {
                        data: 'tipo',
                        orderable: false,
                        name: 'tipo',
                        width: '10%',
                    },

                    {
                        data: 'messaggio',
                        orderable: false,
                        name: 'messaggio',
                        width: '20%'
                    },

                ],
                "initComplete": function (settings) {
                    console.log(settings);
                    $("#myTableBook_wrapper").show();
                    $(".spin").hide();
                },
                "drawCallback": function (settings) {
                    console.log("settings", settings);

                    try {
                        var expcetion = settings.json.exception;

                        if (expcetion != undefined) {
                            //show message label
                            console.log("expcetion", expcetion);
                            //For Pasqualino
                            //if azienda id
                            var pasq = "<div class='staffpasq'>Lo staff di trattoria da Pasqualino</div>";

                            var div = `  <div class="expetion"><div class="wrapexpetion"> ` + "<div>" + expcetion + "</div> " + pasq + `</div></div>`;
                            $("#myTableBook_wrapper").prepend(div);

                        }
                        else {
                            $(".expetion").remove();
                        }

                    } catch (error) {
                        console.log(error);
                    }
                    $(".spin").hide();
                    // return false ;
                    // alert(1) ;
                    var elements = jQuery('.datetimeBook');
                    elements.each(function () {
                        //console.log ( $(this).data("id") );
                        var id = $(this).data("id");
                        (this).pickerOptions = {
                            buttons: [{
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'switch-btn-cancel',
                            },
                            {
                                text: 'Done',
                                cssClass: 'switch-btn-done',
                                handler: columns => {
                                    console.log("ore minuti id", columns.hour
                                        .text, columns.minute.text, id);
                                    var valore = columns.hour.text + ":" +
                                        columns.minute.text;
                                    $.ajax({
                                        url: "{{ route('smartbook.updateHour') }}",
                                        data: {
                                            "id": id,
                                            "hour": valore
                                        },
                                    });
                                }
                            },
                            ],
                        };
                    });

                    setTimeout(function () {

                    }, 300);

                    //   @include("common.datatable");
                    $(".spin").hide();
                    //   $('.p_valore').number(true, 1, ',', '.');
                    //   $('.p_totale').number(true, 2, ',', '.');



                    // setTimeout(() => {

                    //     $("#valDataSel"),html(window.currDay);

                    // }, 600);




                }

            });
            window.table = table;

            var newDate = new Date(date.setMonth(date.getMonth() + 5));
            var $futureTime = newDate.toISOString().slice(0, 10).replace('T', ' ');

            var oggi = new Date();
            oggi = oggi.toISOString().slice(0, 10).replace('T', ' ');

            console.log(oggi);

            // alert ( oggi  );

            console.log($futureTime);
            //  $futureTime = strtotime('+5 months') ;
            //get value from settgins
            var $maxdaysaftertodaytobook = 0;

            if ($maxdaysaftertodaytobook) {
                $futureTime = strtotime("+$maxdaysaftertodaytobook days");
            }

            var search = $(".dataTables_length").clone(true);
            search.attr("id", "final_search");
            $(".table_paginate").prepend(search);
            $("#myTableBook_wrapper .row .col-md-6").first().remove();
            $("#myTableBook_wrapper .row .col-md-6").first().removeClass("col-md-6");




            $("#myTableBook_filter").prepend(`
                <div class="cont_buttons">
            
                 
                    <ion-icon class='dayleft' ios="ios-arrow-dropleft" style="display:none;cursor:pointer;font-size:32px;" md="md-arrow-dropleft"></ion-icon>

                
                    <img src='` + pathLocal + `calendario-smartbook.png' class="calendarButton" style="width:25px!important" title="Cambia Data" />



                 
                    

                    <div class='clDateValue' id='valDataSel'>` + window.currDay + `</div>

                    <div class='clDescDateValue' id='valDescDataSel'>` + this.getDescDay() + `</div>


                    <ion-icon  class='dayright' style="display:none;font-size:32px;cursor:pointer;" ios="ios-arrow-dropright" md="md-arrow-dropright"></ion-icon>

                    <img style="display:none;" src='/public/_lib/img/refresh-smartbook.png' class="refreshButton" />

                </div>
            `);





            $('body').on('click', '.icon-filter', function () {
                var url = "{{ route('admin.filter-piano-orario') }}";
                location.href = url;
            });

        },

        mainInit: function () {

            // part grid Start

            var vpointer = this;
            var pointerVue = this;

            function checkAnag() {
                var isOk = true;

                if (!isEmail($("#email").val())) {
                    isOk = false;
                    //  disableNext();
                } else {
                    enableNext();
                    isOk = true;
                }

                $("#name , #surname , #phone , #emailREMOVED").each(function () {
                    if ($(this).val() == '') {
                        // $(this).parent().effect('shake', {times: 3}, 50).find('.verdiv').addClass('error');
                        isOk = false;
                    }
                });

                if (isOk) enableNext();
                else disableNext();

            }

            function disableNext(params) {
                console.log(params);
                window.swiper.allowSlideNext = false;
                window.swiper.allowTouchMove = false
                $(".swiper-button-next").addClass("swiper-button-disabled");
            }

            function enableNext(params) {
                console.log(params);
                window.swiper.allowSlideNext = true;
                window.swiper.allowTouchMove = true
                $(".swiper-button-next").removeClass("swiper-button-disabled");
            }

            function isEmail(email) {
                //return email;
                if (email.length == 0) return true;
                //eslint-disable-next-line
                var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                return regex.test(email);
            }

            function updateRowTasksResources(id) {
                var dateFrom = $(".date_from[data-id='" + id + "']").val();
                var dateTo = $(".date_to[data-id='" + id + "']").val();
                var max = $(".contInputs[data-id='" + id + "'] input").val();

                let checked = true /* $(this).is(':checked') */;
                var table = $("#resTable").DataTable();
                var res_id = table.rows().nodes().to$().find('input[type="checkbox"]:checked').data("id");
                $.ajax({
                    url: "{{ route('prjweb.createRowTaskResource') }}",
                    type: "POST",
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: {
                        /*   "taskid": $(this).data("id"), */
                        "res_id": res_id,
                        "checked": checked,
                        "maxexpense": max,
                        "dateFrom": dateFrom,
                        "dateTo": dateTo,
                        "taskid": id
                    },
                    success: function (response) {
                        console.log(response);
                    }
                });

            }

            function initDatePicker() {
                try {
                    $('.datetimepicker').datetimepicker({
                        /* defaultDate: new Date(), */
                        format: '{{ $date_picker_format }}',
                        locale: '{{ $settings->locale }}',
                        allowInputToggle: true,
                        icons: {
                            time: "fa fa-clock-o",
                            date: "fa fa-calendar",
                            up: "fa fa-arrow-up",
                            down: "fa fa-arrow-down"
                        }
                    }).on("dp.change", function (e) {
                        console.log(e);
                        var dataId = $(this).data("id");
                        updateRowTasksResources(dataId);

                        $(".contDate").parent().addClass("overf");
                    });
                } catch (error) {
                    console.log(error);

                }
            }

            $(document).ready(function () {

                $("body").on("ionChange", "#bookingtype", function (opt) {

                    console.log(opt);
                    var choice = this.value;
                    if (choice > 0) {
                        //$(".spin").show();
                        $(".spin").show();
                        window.table._fnDraw();

                        enableNext();
                        setTimeout(
                            function () {

                                $(".swiper-button-next").trigger("click");

                            }, 1000);
                    }

                });

                //  $(".cont_ticketss").hide();
                setTimeout(function () {

                    window.swiper = new Swiper(".mySwiper", {
                        noSwipingClass: 'swiper-no-swiping',
                        centeredSlides: true,
                        slidesPerView: 'auto',
                        simulateTouch: false,
                        spaceBetween: 0,
                        effect: "flip",
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        pagination: {
                            el: ".swiper-paginations",
                            clickable: true
                        },
                    });
                    //  $("body").height(100);

                    disableNext();

                    $(".swiper-button-prev").hide();
                    $(".btn-back2").hide();

                    swiper.on('onTouchStart', function () {
                        alert(1);
                    });

                    swiper.on('slideChange', function () {
                        //   checkPages();
                        var index = swiper.activeIndex;
                        $(".progress-indicator li").removeClass("completed info");
                        $('.progress-indicator li').each(function (ind) {
                            //do stuff
                            if (ind <= index)
                                $(".progress-indicator li").eq(ind).addClass("completed info");
                        });
                        if (swiper.activeIndex > swiper.previousIndex) {
                            // alert( 'right' );
                        } else {
                            //  $(".swiper-button-prev").trigger("click");
                            //alert( 'left' );
                        }
                        if (index == 0) {
                            // $(".swiper-button-prev").hide();
                            // $(".swiper-button-next").show();

                            $(".swiper-button-prev").hide();
                            $(".swiper-button-next").hide();

                            if ($("#bookingtype").val() > 0) {
                                enableNext();
                            }

                        }
                        if (index == 1) {

                            // $(".swiper-button-prev").show();
                            // $(".swiper-button-next").show();

                            $(".swiper-button-prev").hide();
                            $(".swiper-button-next").hide();


                            $(".btn-back2").show();


                            if ($("tr.active").length == 0)
                                disableNext();
                        }

                        if (index == 1) {
                            $('#btn_middle').css("visibility", "visible");

                            setTimeout(() => {

                                $("#btn_middle").addClass("clButtonMiddle");


                            }, 200);


                            //   $("#btn_middle").css("transform", "translateX(-31px)");


                        } else
                            $('#btn_middle').css("visibility", "hidden");

                    });

                    //  $(".cont_ticketss").show("slow");
                }, 500)

            });

            $('#email').on('keydown keyup', function (e) {

                // var valore = $(this).val();

                if (e.keyCode == 9) {
                    return false;

                }
            })

            $("body").on("blur", "#email", function () {

                if (!isEmail($(this).val())) {
                    $("#emailhelp").show();

                    disableNext();
                } else {
                    enableNext();
                    $("#emailhelp").hide();
                }
                console.log("phone blur");
            });

            $("body").on("blur", "#name , #surname , #phone , #email", function () {
                //alert(1);
                checkAnag();
                console.log("exited");
            });

            // part grid End

            //Start

            $("body").on("change", ".allCompanies", function (event) {
                console.log(event);
                $('#resTable').DataTable().ajax.reload();
            });

            /*   var tableTasks;
              var resTable;
              var trNew; */
            $(document).ready(function () {

                function setDayName() {

                    $(".daySelected").remove();
                    var year = $(".picker-opt-selected").eq(0).text();
                    var month = $(".picker-opt-selected").eq(1).text();
                    var dnumber = $(".picker-opt-selected").eq(2).text();

                    var day = new Date(year + "-" + month + "-" + dnumber).toLocaleString('it-it', { weekday: 'long' }).slice(0, 3);


                    /*  var d = new Date(year);
                     var dayName = days[d.getDay()]; */

                    $(".picker-opt-selected").eq(2).append("<div class='daySelected'> " + day + "</div>");


                    /*     var tot = $(".daySelected").length;
                        if(tot==0)
                      $(".picker-wrapper").eq(0).prepend("<div class='daySelected'> "  + day  +"</div>");
                      else
                      {
                        $(".daySelected").html(day);
                      } */
                }

                var uname = window.uname;
                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user", uname);
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

                $('body').on('click', 'tr', function () {

                    $("#btn_right").css("visibility", "hidden");

                    $("tr").removeClass("active-row");
                    $(this).addClass("active-row");


                    var v_radio = $(this).find(".val_select");

                    console.log("RADIO: ", v_radio);


                    v_radio.prop("checked", true);

                    var v_exc = v_radio.attr("excp-pranzo-cena");

                    window.hourSelect = v_radio.attr("v-ora");

                    window.idPeriodSel = v_radio.attr("data-id");


                    console.log("EXC: ", v_exc);

                    if (v_exc == 0) {


                        $("#btn_middle2").css("visibility", "visible");



                    }


                    // $(".val_select").trigger("click");



                    // var idx = window.table.api()
                    //     .row(this)
                    //     .index();
                    // var total = window.table.api().data().count();
                    // console.log(idx, total);
                    // var avail = $(this).find("[data-status='1']").length;


                    // var avail2 = $(this).find("[excp-pranzo-cena='0']").length;


                    // console.log(avail);

                    // console.log(avail2);

                    // if (avail == 0) return false;

                    // //if (avail2 == 0) return false

                    // $(".swiper-button-next").hide();

                    // // if (avail2 == 1) {

                    // // $(".swiper-button-next").show();

                    // //alert( 'Row index: '+ window.table.api().row( this ).index() );
                    // $("tr").removeClass("active");
                    // $(this).addClass("active");
                    // enableNext();

                    // $(".btn_mail").removeClass("btn-disabled");
                    // $(".btn_edit").removeClass("btn-disabled");
                    // $(".btn_delete").removeClass("btn-disabled");
                    // var id_row = $("tr.active").find(".id_row").attr("data-id");

                    // console.log("VAL ID: " + id_row);
                    // $(".btn_mail").attr("data-id", id_row);
                    // $(".btn_edit").attr("data-id", id_row);
                    // $(".btn_delete").attr("data-id", id_row);

                    // try {
                    //     //var dataora = $("tr.active ion-datas").text() + " " + $("tr.active ion-items").text();
                    //     var dataora = window.currDay + " " + $("tr.active ion-items").text();

                    //     $("#data").val(dataora);

                    // } catch (err) {
                    //     console.log(err);
                    // }

                    // //  }



                    // $("#btn_left").hide();
                    // $("#btn_right").hide();




                });

                //main instance table
                //instanceTable
                //main instance table END

                //    var dates = new Date();
                initDatePicker();


                //setup event for datetime
                //   var days = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];



                $('body').on('click touchstart touchend', '.picker-opt', function () {

                    setTimeout(function () {

                        setTimeout(setDayName, 100);

                        setTimeout(setDayName, 2000);

                        setTimeout(setDayName, 3000);

                        setTimeout(setDayName, 4000);

                        setTimeout(setDayName, 5000);
                        setTimeout(setDayName, 6000);
                        setTimeout(setDayName, 7000);
                        setTimeout(setDayName, 8000);
                        setTimeout(setDayName, 9000);


                    }, 1000);




                });





                setTimeout(
                    function () {

                        //Set up currDay
                        document.querySelector('.datetimechangeday').pickerOptions = {
                            buttons: [



                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done<span class="test">DONE</span>',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        var id = 0;
                                        console.log("ore minuti id", columns, id);
                                        var valore = columns.year.text + "-" + columns.month.text +
                                            "-" + columns.day.text;
                                        window.currDay = valore;
                                        document.querySelector('.datetimechangeday').value = valore;
                                        //$(".spin").show();
                                        $(".spin").show();

                                        window.table._fnDraw();
                                        disableNext();
                                    }
                                },
                            ],
                        };

                        document.querySelector('.datetimechangeday').addEventListener('ionPickerColChange', async (event) => {
                            console.log(1, event)
                        });



                        $(".datetimechangeday").on("ionPickerColChange", function (opt) {
                            console.log(opt);

                        });




                        /*   var target = document.querySelector('picker-opt-selected');
                          var observer = new MutationObserver(function(mutations) {
                              console.log(target.innerText);   
                          });
*/

                        $('.picker-opt-selected').on('DOMSubtreeModified', function () {

                            console.log('changed');


                        });


                        /*  setTimeout( function() {
                         $(".picker-toolbar").append("CIAO");

                         } ,300); */

                        $(".datetimechangeday").on("ionChange", function (opt) {
                            console.log(opt);

                        });



                        $(".selectstatusall").on("ionChange", function (opt) {

                            console.log(opt);
                            $(".spin").show();
                            window.curStatus = this.value;
                            window.table._fnDraw();
                            disableNext();
                        });

                    }, 10);

                //    $('.datetimepicker').val( new Date());
                var countChecked = function ($table, checkboxClass) {
                    if ($table) {
                        // Find all elements with given class
                        var chkAll = $table.find(checkboxClass);
                        // Count checked checkboxes
                        var checked = chkAll.filter(':checked').length;
                        // Count total
                        var total = chkAll.length;
                        // Return an object with total and checked values
                        return {
                            total: total,
                            checked: checked
                        }
                    }
                }
                console.log(countChecked);

                //$('body').on('click', '.right', function() {
                //   $('body').on('click', '.swiper-button-next', function() {

                // $("p:last")

                $('body').on('click', '.swiper-button-next', function () {

                    var completed = $(".completed").length;
                    console.log(completed);

                });

                //mange delete button

                $('body').on('click', '.cancelBooking', function () {
                    var username = window.$cookies.get("username");

                    var is_admin = 0;
                    if (window.is_admin == 'Y' || window.is_admin == 'S') is_admin = 1;

                    //                            var errore = "";

                    //   var $is_admin_company = is_admin;

                    var basepath = pointerVue.getBasePath();

                    var sb_periods_id = $(this).attr("data-id");
                    var canproceed = $(this).attr("data-canproceed");

                    /*  alert(canproceed);
                     return false ; */

                    var b_type = $("#bookingtype").val();

                    if (canproceed == 0) {

                        vpointer.$swal({
                            icon: 'error',
                            title: 'Termini prenotazione soppraggiunti',
                            text: "Non è possibile disdire  questa prenotazione ! ",
                            timer: 3500,
                            buttons: false
                        });

                        return false;

                    }

                    // alert(sb_periods_id);

                    vpointer.$swal({
                        title: "Disdici",
                        text: "Vuoi disdire questa prenotazione" + " ?",
                        icon: "warning",
                        showCancelButton: true,

                        confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_smartbook.png' />",
                        cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_smartbook.png' />",
                        customClass: {
                            cancelButton: 'order-1',
                            confirmButton: 'order-2',
                        },

                        dangerMode: true,
                    }).then((result) => {
                        if (result.value == true) {
                            //  if (isConfirm) {

                            $(".spin").show();

                            var apidest = basepath + "cancelAppointmentApi";
                            var apidestNotify = basepath + "sendNotifyCancelAppointmentApi";

                            $.ajax({
                                url: apidest,
                                data: {
                                    "id": sb_periods_id,
                                    "v_b_type": b_type,
                                    "cid": window.cid,
                                    "is_admin": is_admin,
                                    "username": username,
                                },
                                success: function (data) {

                                    //To manage
                                    var res = data.Result;
                                    var error_msg = data.Errore;
                                    if (res == "OK") {

                                        $.ajax({
                                            url: apidestNotify,
                                            data: {
                                                "id": sb_periods_id,
                                                "v_b_type": b_type,
                                                "cid": window.cid,
                                                "is_admin": is_admin,
                                                "username": username,

                                            },
                                            success: function (data) {
                                                var res = data.Result;
                                                var error_msg = data.Errore;

                                                console.log(error_msg);

                                                $(".spin").hide();

                                                if (res == "OK") {

                                                    vpointer.$swal({
                                                        icon: 'success',
                                                        text: "Prenotazione disdetta" +
                                                            " !",
                                                        timer: 2500,
                                                        showConfirmButton: false
                                                    });
                                                    window.table._fnDraw();
                                                    //$.unblockUI();
                                                    $(".spin").hide();

                                                }

                                            }

                                        });

                                    } else {
                                        vpointer.$swal({
                                            icon: 'error',
                                            text: error_msg,
                                            timer: 1500,
                                            showConfirmButton: false
                                        });
                                    }
                                }
                            });
                        }
                    });

                });

                $('body').on('click', '.checkAll', function () {
                    /*    $('input:checkbox').not(this).prop('checked', this.checked);
                       let checked = $(this).is(':checked'); */
                    let dest = $(this).data("id");
                    // alert (dest);
                    $('#' + dest).DataTable()
                        .column(0)
                        .nodes()
                        .to$()
                        .find('input[type=checkbox]')
                        .prop('checked', this.checked);
                    //    $('#taskTable').DataTable().ajax.reload();

                    /*   $.ajax (
                          {
                              url: "{{ route('mailing.mlupdatecompanyAll') }}",
                              data: { checked: checked }
                          }
                      ); */
                });

                $('#prjTable').on('click', '.val_status',
                    function () {
                        $('#taskTable').DataTable().ajax.reload();
                    });

                $('#resTable').on('click', '.val_status', function () {
                    var v_from_packages = 0;
                    let checked = $(this).is(':checked');
                    let id = $(this).data("id");
                    console.log(id);

                    if (v_from_packages == 0) {
                        var dest = "resTable";
                        $('#' + dest).DataTable()
                            .column(0)
                            .nodes()
                            .to$()
                            .find('input[type=checkbox]')
                            .prop('checked', false);
                    }

                    this.checked = checked;

                    $('#prjTable').DataTable().ajax.reload();
                    //       $('#prjTable').dataTable( ).api().ajax.reload();

                    setTimeout(
                        function () {
                            $('#taskTable').DataTable().ajax.reload();
                        }, 1500)

                });

                // loadTasks();
                //init sec table START

                $('body').on('click', '.icon-filter', function () {
                    var url = "admin.filter-company";
                    location.href = url;
                }

                );

                $('body').on('click', '.icon-add', function () {
                    var url = "{{ route('admin.company.create') }}";
                    location.href = url;
                }

                );

                $('body').on('click', '.icon-excel-import', function () {
                    var url = "{{ route('admin.company-import') }}";
                    location.href = url;
                }

                );

                $('body').on('click', '.nextStep', function () {

                    var countchecked = $("#taskTable").DataTable().rows().nodes().to$().find('input[type="checkbox"]:checked').length;

                    if (countchecked > 0) {

                        $(".zero").hide("slow");
                        $(".one").hide("slow");
                        $(".two").hide("slow");
                        $(".three").show("slow");

                    }

                }

                );

                // idinvoice_banca
                $('body').on('change', '#idinvoice_banca', function () {
                    var v_iban = "";

                    if (this.value != "") {
                        v_iban = $('option:selected', this).attr('iban-value');
                    }

                    $("#iban").val(v_iban);

                }

                );

                $('body').on('click', '.icon-copia', function () {

                    let ids = [];

                    $("#prjTable").DataTable().rows().nodes().to$().find('input[type="checkbox"]:checked').each(function () {
                        ids.push($(this).data("id"));
                    }

                    );

                    var errore = "";

                    if (ids.length == 0) {
                        errore = "@lang('app.sel_riga_one') !";
                    }

                    if (ids.length > 1) {
                        errore = "@lang('app.sel_one_riga') !";
                    }

                    if (errore == "") {

                        var val_colli_sel = ids.join(",");
                        console.log(val_colli_sel);

                        //console.log(val_colli_sel);

                        //start
                        vpointer.$swal({
                            title: "@lang('app.select_number_item')",
                            text: "<input type='text'>",
                            icon: "warning",
                            type: "input",
                            showCancelButton: true,
                            closeOnConfirm: false,
                            animation: "slide-from-top",
                            inputPlaceholder: "Write something",
                            dangerMode: false,
                        }

                        ).then(function (isConfirm) {
                            if (isConfirm) {
                                /*   $.ajax({
                       url: "{{ route('admin.azienda-delete') }}",
                       data: {"id" : ids },
                       success: function(data){
                           var res = data.Result;
                           var error_msg = data.Errore;
                           if (res == "OK")
                           {
                               swal({
                                   icon: 'success',
                                   title: "@lang('app.successo')",
                                   text: "@lang('app.conf_delete')" + " !",
                                   timer: 500,
                                   buttons: false
                               });
                               $.unblockUI();
                               table._fnDraw();
                           }
                           else
                           {
                               swal({
                                   icon: 'error',
                                   title: 'Error',
                                   text: error_msg,
                                   timer: 1500,
                                   buttons: false
                               });
                           }
                       }
                   });*/
                            }
                        }

                        );
                        //end
                        var img = "{{ url('/_lib/img/icon-ok-smartbook.png') }}";
                        $(".swal-text").html("<input min='1' type='number' id='num_colli' text='num_colli' value='1' />");
                        $(".swal-button-container").html("<img class='copyConfirm' src='" + img + "' />");

                        return false;

                    } else {

                        vpointer.$swal({
                            icon: 'error',
                            title: "Errori",
                            text: errore,
                            buttons: false,
                            timer: 1500
                        }

                        );
                    }

                }

                );

                $('body').on('click', '.copyConfirm', function () {
                    let ids = [];

                    $("#prjTable").DataTable().rows().nodes().to$().find('input[type="checkbox"]:checked').each(function () {
                        ids.push($(this).data("id"));
                    }

                    );
                    var val_colli_sel = ids.join(",");

                    $.ajax({

                        url: "{{ route('prjweb.warehouse-item-packages-copy') }}",
                        data: {
                            "v_id_colli": val_colli_sel,
                            "times": $("#num_colli").val()
                        }

                        ,
                        success: function (data) {
                            //  swal.close();
                            console.log(data);
                            $('#prjTable').DataTable().ajax.reload();

                            vpointer.$swal({
                                icon: 'successo',
                                title: '@lang("app.successo")',
                                text: "Copia effettuata",
                                buttons: false,
                                timer: 1500
                            }

                            );
                            // location.href = "{{ route('prjweb.warehouse-packages-init-from-load-carrier') }}/";
                        }
                    }

                    );

                }

                );

                $('body').on('click', '.confirm-form-print', function () {
                    var errore = "";

                    $(".inp_qta_print").each(function () {
                        if ($(this).val() == "") {
                            errore = "@lang('app.spec_quantity')";
                        }

                    }

                    );

                    if (errore == "") {

                        var val_pack = "";

                        $(".inp_qta_print").each(function () {

                            //console.log($(this).attr("data-id"));
                            if (val_pack != "") {
                                val_pack = val_pack + "|";
                            }

                            val_pack = val_pack + $(this).attr("data-id") + "," + $(this).val();
                        }

                        );

                        //  console.log(val_pack);

                        $(".spin").show();

                        $.ajax({

                            url: "{{ route('prjweb.warehouse-item-packages-print-label') }}",
                            data: {
                                "v_val_package": val_pack
                            }

                            ,
                            success: function (data) {
                                $(".spin").hide();

                                var file_path = data.ListPath;
                                var a = document.createElement('A');
                                a.href = file_path;
                                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);

                                // var v_list_path = data.ListPath;
                                // console.log(v_list_path);
                                // var elements = v_list_path.toString().split("|");
                                // for (i = 0; i < elements.length; i++)
                                // {

                                //     var v_url = elements[i];

                                //     var file_path = v_url;
                                //     var a = document.createElement('A');
                                //     a.href = file_path;
                                //     a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                                //     document.body.appendChild(a);
                                //     a.click();
                                //     document.body.removeChild(a);

                                // }

                            }
                        }

                        );

                    } else {

                        vpointer.$swal({
                            icon: 'error',
                            title: '@lang("app.errore")',
                            text: errore,
                            buttons: false,
                            timer: 1500
                        }

                        );

                    }

                }

                );

            }

            );

            /* function resetAll() {
                $(".progress-indicator li").removeClass("completed info");
                $(".progress-indicator li").eq(0).addClass("completed info");
                swiper.slideTo(0);
                $("#tipologia").val("").trigger("change");
                $("input").val("");
            } */

            function resetForAdmin() {
                $("#name , #postazione , #num_persons , #phone , #email , #settorepiano , #note , #cod_fis_ospite_2 , #settorepiano_2  ").val("");
                swiper.slideTo(0);
                enableNext();

                $(".spin").show();
                window.table._fnDraw();

                // $(".swiper-button-prev").trigger("click");

            }

            /*  $('body').on('click', '.icon-udc', function () {
                     var id_item = $(this).attr("data-id");
                     $(".spin").show();

                     $.ajax({

                             url: "{{ route('prjweb.warehouse-item-packages-item-assoc') }}",
                             data: {
                                 "v_id_item": id_item
                             }

                             ,
                             success: function (data) {
                                 $(".spin").hide();

                                 if (data.Result == "OK") {

                                     $('.modal-title').text('@lang("app.items_associated")');
                                     jQuery(".itemselected").empty();

                                     var UdcAssoc = data.UdcAssoc;
                                     jQuery(".projectsassociated").empty();
                                     var num = 0;
                                     console.log(num);
                                     var txtHtml = '<table border=0 style="width:100%"><tr><td style="font-size:10px"><b>Part Number</b></td><td style="font-size:10px"><b>Description</b></td></tr>';

                                     $.each(UdcAssoc, function (index, g) {
                                             num += 1;

                                             txtHtml = txtHtml + '<tr><td style="font-size:10px">' + g.name + '</td><td style="font-size:10px">' + g.quantity + '</td></tr>';

                                         }

                                     );
                                     txtHtml = txtHtml + '</table>';

                                     jQuery(".projectsassociated").append(txtHtml);

                                     $("#exampleModal").modal();

                                 }

                             }

                         }

                     );

                 }

             ); */

            $('body').on('click', '.submit-condition', function () {
                $(".close").trigger("click");
            }

            );


            $('body').on('click', '.btn-back2', function () {


                $("#btn_left").css("visibility", "hidden");

                history.back();


                // $(".swiper-button-prev").trigger("click");

            });



            $('body').on('click', '.btn-confirm2', function () {

                console.log("BTN_CONFIRM2");

                pointerVue.checkBookingHour(67, "09-10-2023");


                // setTimeout(
                //     function () {

                //         var dataora = window.currDay + " " + window.hourSelect;
                //         $("#data").val(dataora);
                //     }
                //     , 600);




                // setTimeout(function () {


                //     $("#btn_middle2").css("visibility", "hidden");

                //     $(".swiper-button-next").trigger("click");
                // }, 200);


                // var dataora = window.currDay + " " + window.hourSelect;
                // $("#data").val(dataora);

                // $("#btn_middle2").css("visibility", "hidden");

                // $(".swiper-button-next").trigger("click");
                // $(".swiper-button-next").trigger("click");
                // $(".swiper-button-next").trigger("click");
                // $(".swiper-button-next").trigger("click");
                // $(".swiper-button-next").trigger("click");



            });


            // $('body').on('click', '#btn_middle2', function () {

            //     console.log("BTN_MIDDLE2");

            //     setTimeout(
            //         function () {

            //             var dataora = window.currDay + " " + window.hourSelect;
            //             $("#data").val(dataora);
            //         }
            //         , 600);




            //     setTimeout(function () {


            //         $("#btn_middle2").css("visibility", "hidden");

            //         $(".swiper-button-next").trigger("click");
            //     }, 200);


            // });




            $('body').on('click', '.btn-confirm', function () {

                var username = window.$cookies.get("username");

                var is_admin = 0;
                if (window.is_admin == 'Y' || window.is_admin == 'S') is_admin = 1;

                var errore = "";

                var $is_admin_company = is_admin;



                if ($("#name").val() == "" || $("#name").val() == " ") {
                    errore = "Inserire Nome e cognome";

                }

                if ($("#phone").val() == "" || $("#phone").val() == " ") {
                    errore = "Inserire telefono";
                }


                if ($("#email").val() == "" || $("#email").val() == " " || !isEmail($("#email").val())) {
                    errore = "Inserire email";
                }


                if ($("#num_persons").val() == "") {
                    errore = "Inserire numero persone";
                }

                // if ($("tr.active").length == 0) {
                //     errore = "Selezionare data e ora della prenotazione nello step precedente";
                // }

                var maxbookingperweek = window.settSmartbook.maxbookingperweek;
                // alert ( maxbookingperweek  );

                // return false ;

                /* resetAll();
                return false; */
                if (errore == "") {

                    //   var timePart = $(".datebooking").val().split('T')[1];
                    // var dataora = $(".datebooking").val().substring(0, 10) + " " + timePart.substring(0, 5);
                    /* resetAll ();
                return false ; */

                    var settorepiano = $("#settorepiano").val();
                    var namepaziente = $("#namepaziente").val();

                    var settorepiano_2 = "";

                    if ($("#settorepiano_2").length > 0) {
                        settorepiano_2 = $("#settorepiano_2").val();
                    }

                    var namepaziente_2 = "";

                    if ($("#namepaziente_2").length > 0) {
                        namepaziente_2 = $("#namepaziente_2").val();
                    }

                    // var sb_periods_id = $("tr.active .val_statuses").attr("data-id");
                    // var dataora = $("tr.active ion-datas").text() + " " + $("tr.active ion-items").text();

                    var sb_periods_id = window.idPeriodSel;
                    var dataora = window.currDay + " " + window.hourSelect;



                    var b_type = $("#bookingtype").val();

                    //  alert(b_type);

                    //  alert(dataora);
                    //   return false ;
                    //$(".spin").show();

                    $(".spin").show();

                    var dataToPass = {
                        "maxbookingperweek": maxbookingperweek,
                        "settorepiano": settorepiano,
                        "namepaziente": namepaziente,
                        "sb_periods_id": sb_periods_id,
                        "name": $("#name").val(),
                        "note": $("#note").val(),
                        "period": $("#period").val(),
                        "surname": $("#surname").val(),
                        "phone": $("#phone").val(),
                        "email": $("#email").val(),
                        "num_persons": $("#num_persons").val(),
                        "booking_date_time": dataora,
                        "booking_type": b_type,
                        "cod_fis_ospite": $("#cod_fis_ospite").val(),
                        "cid": window.cid,
                        "is_admin": is_admin,
                        "username": username,
                        "asker": "bookinggen"

                    };
                    console.log("DataToPass", dataToPass);

                    //smartbook.checkMaxPerWeek
                    var endPointCheck = window.basepath + "checkMaxPerWeekApi";

                    //  alert (  endPoint  ) ;

                    //Check if can do
                    $.ajax({

                        url: endPointCheck,
                        data: dataToPass,
                        error: function (data) {
                            var er = data.responseJSON.msg;

                            vpointer.$swal({
                                icon: 'error',
                                text: er + " !",
                                showConfirmButton: false,
                                timer: 3500
                            }

                            );
                            $(".spin").hide();

                        }

                        ,
                        success: function (data) {
                            console.log(data);
                            //Here call te send emaul function

                            var endPointBook = window.basepath + "createPdfSendMailApi";
                            var passData = {

                                "maxbookingperweek": maxbookingperweek,
                                "settorepiano": settorepiano,
                                "namepaziente": namepaziente,
                                "sb_periods_id": sb_periods_id,
                                "name": $("#name").val(),
                                "note": $("#note").val(),
                                "period": $("#period").val(),
                                "surname": $("#surname").val(),
                                "phone": $("#phone").val(),
                                "email": $("#email").val(),
                                "num_persons": $("#num_persons").val(),
                                "booking_date_time": dataora,
                                "booking_type": b_type,
                                "cod_fis_ospite": $("#cod_fis_ospite").val(),

                                "settorepiano_2": settorepiano_2,
                                "namepaziente_2": namepaziente_2,
                                "cod_fis_ospite_2": $("#cod_fis_ospite_2").val(),
                                "cid": window.cid,
                                "is_admin": is_admin,
                                "username": username,

                            };

                            $.ajax({

                                url: endPointBook,
                                data: passData,
                                error: function (data) {
                                    var er = data.responseJSON.msg;

                                    //  console.log ( data ) ;
                                    vpointer.$swal({
                                        icon: 'error',
                                        text: er + " !",
                                        showConfirmButton: false,
                                        timer: 3500
                                    }

                                    );
                                    $(".spin").hide();

                                }

                                ,
                                success: function (data) {

                                    // $(".spin").hide();
                                    var res = data.Result;
                                    // var res = "OK" ;
                                    var error_msg = data.Errore;
                                    var v_url = data.ValUrl;

                                    console.log(error_msg, v_url);

                                    if (res == "OK") {


                                        var endPointNotify = window.basepath + "sendNotifyApi";
                                        // call method send notify
                                        $.ajax({

                                            url: endPointNotify,
                                            data: {

                                                "maxbookingperweek": maxbookingperweek,
                                                "settorepiano": settorepiano,
                                                "namepaziente": namepaziente,
                                                "sb_periods_id": sb_periods_id,
                                                "name": $("#name").val(),
                                                "note": $("#note").val(),
                                                "period": $("#period").val(),
                                                "surname": $("#surname").val(),
                                                "phone": $("#phone").val(),
                                                "email": $("#email").val(),
                                                "num_persons": $("#num_persons").val(),
                                                "booking_date_time": dataora,
                                                "booking_type": b_type,
                                                "cod_fis_ospite": $("#cod_fis_ospite").val(),
                                                "settorepiano_2": settorepiano_2,
                                                "namepaziente_2": namepaziente_2,
                                                "cod_fis_ospite_2": $("#cod_fis_ospite_2").val(),
                                                "azienda_id": window.settSmartbook.cid,
                                                "cid": window.cid,
                                                "is_admin": is_admin,
                                                "username": username,
                                                "sector": window.sector,

                                            }

                                            ,
                                            error: function (data) {
                                                var er = data.responseJSON.msg;
                                                //  console.log ( data ) ;
                                                vpointer.$swal({
                                                    icon: 'error',
                                                    text: er + " !",
                                                    showConfirmButton: false,
                                                    timer: 3500
                                                }

                                                );
                                                $(".spin").hide();
                                            },
                                            success: function (data) {

                                                console.log(data);

                                            }

                                        }

                                        );




                                        //before sending notify we give a msg to the user
                                        $(".spin").hide();
                                        // var res = "OK";
                                        if (res == "OK") {
                                            vpointer.$swal({
                                                icon: 'success',
                                                text: "La prenotazione è stata inviata correttamente !",
                                                showConfirmButton: false,
                                                timer: 3000
                                            });
                                            //what to do after
                                            if ($is_admin_company != 1) {
                                                var cid = window.cid;
                                                if (cid == 168) {
                                                    var dest = "https://trattoriadapasqualino.it"
                                                    setTimeout(
                                                        function () {

                                                            console.log(dest);

                                                            window.location.href = dest;
                                                        }, 3000);
                                                } else {
                                                    resetForAdmin();
                                                }
                                                /* resetAll();
                
                                                var compRed = "/listBooking";
                                                if (window.sector == "RSA") compRed = "/listBookingRsa";
                                                // var appLoginAfter = "{{ route('smartbook.listBooking') }}";
                                                // window.location.href = appLoginAfter;
                                                setTimeout(() => {
                                                    router.push({
                                                        path: compRed
                                                    });
                
                                                }, 300); */
                                            }

                                            if ($is_admin_company == 1) {
                                                resetForAdmin();
                                            }

                                        }
                                        //end process

                                        console.log("SEND_NOTIFY_API");



                                    } else {
                                        $(".spin").hide();
                                        //   console.log (  res ) ;
                                        res = data.msg;

                                        vpointer.$swal({
                                            icon: 'error',
                                            text: res + " !",
                                            showConfirmButton: false,
                                            timer: 3500
                                        }

                                        );

                                        return false;
                                    }

                                    // if (res == "OK") {
                                    //     swal({
                                    //         icon: 'success',
                                    //         title: "@lang('app.successo')",
                                    //         text: "La prenotazione è stata inviata all'utente specificato" +
                                    //             " !",
                                    //         buttons: false,
                                    //         timer: 3000
                                    //     });
                                    //     resetAll();
                                    //     var appLoginAfter = "{{ route('smartbook.listBooking') }}";
                                    //     window.location.href = appLoginAfter;

                                    // }

                                }
                            }

                            );

                            //END

                        }
                    }

                    );

                } else {
                    vpointer.$swal({
                        icon: 'error',
                        text: errore + " !",
                        showConfirmButton: false,
                        timer: 1500
                    }

                    );

                }

            }

            );
            //End

        }

        ,

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/smartbook/" + nome_metodo;

        }

        ,

        getBasePath: function () {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result;

        },

        setupAdminCompany: function () {

            var pointerVue = this;
            var basepath = pointerVue.getBasePath();

            $(document).ready(function () {
                window.sending = 0;

                if (window.sending == 0) $("#name").keyup(function () {

                    if ($(this).is('[readonly]')) {
                        return false;
                    }

                    window.sending = 1;

                    $.ajax({

                        type: "GET",
                        url: basepath + 'readOspitiApi',
                        data: 'keyword=' + $(this).val(),
                        beforeSend: function () {
                            $("#name").css("background", "#FFF url({{ url('/public/_lib/img/LoaderIcon.gif') }}) no-repeat 165px");
                        }

                        ,
                        success: function (data) {

                            console.log(data);

                            window.sending = 0;
                            $("#suggesstion-box").show();
                            $("#suggesstion-box").html(data);
                            $("#name").css("background", "#FFF");
                        }
                    }

                    );
                }

                );

                //From pazienti get opsiti
                $("#namepaziente").keyup(function () {
                    window.sending = 1;

                    if ($(this).is('[readonly]')) {
                        return false;
                    }

                    $.ajax({

                        type: "GET",
                        url: basepath + 'getParentiApi',

                        data: 'keyword=' + $(this).val(),
                        beforeSend: function () {
                            $("#namepaziente").css("background", "#FFF url({{ url('/public/_lib/img/LoaderIcon.gif') }}) no-repeat 165px");
                        }

                        ,
                        success: function (data) {
                            window.sending = 0;
                            $("#suggesstion-box-two").show();
                            $("#suggesstion-box-two").html(data);
                            $("#namepaziente").css("background", "#FFF");
                        }
                    }

                    );
                }

                );

            }

            );

            $("body").on("click", ".choiceOspite", function (event) {
                var val = $(this).text();
                console.log(event);
                var settorepiano = $(this).data("settorepiano");
                $("#settorepiano").val(settorepiano);

                var namepaziente = $(this).data("nomeospite");
                $("#namepaziente").val(namepaziente);

                var cod_fis_ospite = $(this).data("codicefiscaleospite");
                $("#cod_fis_ospite").val(cod_fis_ospite);

                var phone = $(this).data("telefono");
                $("#phone").val(phone);

                var email = $(this).data("mail");
                $("#email").val(email);

                $("#name").val(val);
                $("#name").css("background", "none");

                $("#suggesstion-box").hide();

                var namepaziente_2 = $(this).data("nomeospite_2");

                if (namepaziente_2 != "") {
                    $("#row_nome_ospite_2").css('display', '');
                    $("#row_nome_ospite_2").css('margin-top', '-15px');
                    $("#namepaziente_2").val(namepaziente_2);
                } else {
                    $("#row_nome_ospite_2").css('display', 'none');
                }

                var codicefiscaleospite_2 = $(this).data("codicefiscaleospite_2");

                if (codicefiscaleospite_2 != "") {
                    $("#row_cf_ospite_2").css('display', '');
                    $("#row_cf_ospite_2").css('margin-top', '-15px');
                    $("#cod_fis_ospite_2").val(codicefiscaleospite_2);
                } else {
                    $("#row_cf_ospite_2").css('display', 'none');

                }

                var settorepiano_2 = $(this).data("settorepiano_2");

                if (settorepiano_2 != "") {
                    $("#item_nucleo_2").css('display', '');
                    $("#row_cf_ospite_2").css('margin-top', '-15px');
                    $("#settorepiano_2").val(settorepiano_2);
                } else {
                    $("#item_nucleo_2").css('display', 'none');

                }

            }

            );

            $("body").on("click", ".choicePaziente", function (event) {
                console.log(event);
                var val = $(this).text();

                var settorepiano = $(this).data("settorepiano");
                $("#settorepiano").val(settorepiano);

                var namepaziente = $(this).data("nomeospite");
                $("#namepaziente").val(namepaziente);

                var nameparente = $(this).data("nomeparente");
                $("#name").val(nameparente);

                var cod_fis_ospite = $(this).data("codicefiscaleospite");
                $("#cod_fis_ospite").val(cod_fis_ospite);

                var phone = $(this).data("telefono");
                $("#phone").val(phone);

                var email = $(this).data("mail");
                $("#email").val(email);

                $("#namepaziente").val(val);
                $("#namepaziente").css("background", "none");

                $("#suggesstion-box-two").hide();

                var namepaziente_2 = $(this).data("nomeospite_2");

                if (namepaziente_2 != "") {
                    $("#row_nome_ospite_2").css('display', '');
                    $("#row_nome_ospite_2").css('margin-top', '-15px');
                    $("#namepaziente_2").val(namepaziente_2);
                } else {
                    $("#row_nome_ospite_2").css('display', 'none');

                }

                var codicefiscaleospite_2 = $(this).data("codicefiscaleospite_2");

                if (codicefiscaleospite_2 != "") {
                    $("#row_cf_ospite_2").css('display', '');
                    $("#row_cf_ospite_2").css('margin-top', '-15px');
                    $("#cod_fis_ospite_2").val(codicefiscaleospite_2);
                } else {
                    $("#row_cf_ospite_2").css('display', 'none');

                }

                var settorepiano_2 = $(this).data("settorepiano_2");

                if (settorepiano_2 != "") {
                    $("#item_nucleo_2").css('display', '');
                    $("#row_cf_ospite_2").css('margin-top', '-15px');
                    $("#settorepiano_2").val(settorepiano_2);
                } else {
                    $("#item_nucleo_2").css('display', 'none');

                }

            }

            );

        }

        ,
        setupGrid: function () {

            $(".spin").hide();

            var that = this;
            // that.tipi = [1,2];
            var pathLocal = that.$withBase('/');

            var pointerVue = this;

            var dateTest = new Date();
            dateTest = dateTest.setDate(dateTest.getDate() + 365);
            const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            const date = new Date().toJSON().slice(0, 10) //console.log("DATE: " + date) //2015-07-23

            var $futureTime = dateTest2;
            var username = window.$cookies.get("username");

            var is_su = window.$cookies.get("a");
            window.is_su = is_su;

            var is_admin = window.$cookies.get("b");
            window.is_admin = is_admin;

            var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));

            var namesurname = window.$cookies.get("namesurname");

            console.log("settSmartbook", settSmartbook[0]);

            console.log(pathLocal, pointerVue, date, $futureTime);
            window.cid = settSmartbook[0].azienda_id;
            window.settSmartbook = settSmartbook[0];

            //alert (window.cid);
            //Vue
            console.log(namesurname);

            if (window.is_admin == 'Y' || window.is_admin == 'S') {

                this.nomeCognomeParente = "";
            } else
                //  this.nomeCognomeParente = namesurname;
                this.nomeCognomeParente = "";

            $(document).ready(function () {

                var basepath = pointerVue.getBasePath();

                // $('body').on('click', '.val_statuses2', function () {

                //     console.log("VAL_STATUSES");

                //     var v_id = $(this).attr("data-id");
                //     var v_data = $(this).attr("date-value");

                //     console.log("V_ID: ", v_id);
                //     console.log("V_DATA: ", v_data);

                //     pointerVue.checkBookingHour(v_id, v_data);





                // });


                $('body').on('click', '.val_select', function () {

                    console.log("VAL_SELECT");



                    var v_exc = $(this).attr("excp-pranzo-cena");

                    window.hourSelect = $(this).attr("v-ora");

                    window.idPeriodSel = $(this).attr("data-id");


                    console.log("EXC: ", v_exc);

                    if (v_exc == 0) {


                        $("#btn_middle2").css("visibility", "visible");


                    }







                });




                $('body').on('click', '.val_statuses_disable', function () {

                    console.log("VAL_STATUSES_DISABLE");

                    // var v_id = $(this).attr("data-id");
                    // var v_data = $(this).attr("date-value");

                    // console.log("V_ID: ", v_id);
                    // console.log("V_DATA: ", v_data);

                    // pointerVue.checkBookingHour(v_id, v_data);





                });

                $.ajax({

                    type: "GET",
                    url: basepath + "bookingApi",
                    data: {

                        "settSmartbook": settSmartbook[0],
                        "username": username,
                        "is_su": is_su,
                        "cid": settSmartbook[0].azienda_id,

                    }

                    ,
                    success: function (resultData) {
                        console.log(resultData);
                        /*that.tipi = [1,2];*/

                        var ospite = {
                            "nome": "",
                            "cognome": "",
                            "settore_piano": ""
                        };
                        console.log("ciao", resultData.ospite);
                        if (resultData.ospite !== null) ospite = resultData.ospite;
                        var ospite2 = {
                            "nome": "",
                            "cognome": "",
                            "settore_piano": ""
                        };
                        if (resultData.ospite2 !== null) ospite2 = resultData.ospite2;

                        if (resultData.parente) {
                            pointerVue.phone = resultData.parente.telefono;
                            pointerVue.email = resultData.parente.mail;
                            //  alert (pointerVue.email);
                        }

                        if (ospite != null) {
                            pointerVue.selSector = ospite.settore_piano;

                        }

                        if (ospite2 != null) {
                            pointerVue.selSector2 = ospite2.settore_piano;
                        }

                        // console.log(ospite, ospite2);

                        pointerVue.ospite = ospite;
                        pointerVue.ospite2 = ospite2;

                        if (ospite2 && ospite2.nome) {
                            pointerVue.is_2_ospite = 1;
                        }

                        pointerVue.tipi = resultData.tipi;
                        console.log(resultData.tipi);

                        /*                         var ArOk = [];
                                    for (const [key, value] of Object.entries(resultData.tipi)) {
                                        console.log("ciaciao", key, value);
                                        ArOk.push({
                                            "key": value.id,
                                            "valore": value
                                        });
                                    }

                                    console.log("resultData.tipi", resultData.tipi, ArOk); */

                        pointerVue.settoridb = resultData.settoridb;
                        window.sector = resultData.sector;

                    }
                }

                );

                //new $.fn.dataTable.FixedHeader(window.table);

                if (window.listBookingRsaAddedEvents === undefined) {

                    console.log("Passed");

                }

                //setup ionic calendar top
                setTimeout(function () { }

                    , 600);

                window.listBookingRsaAddedEvents = true;

            }

            );

        }

        ,

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                // var pulsantis = [{
                //         text: "Dashboard",
                //         icon: "mdi-home-circle",
                //         link: "/dash",
                //         id: "btn_exit",
                //         disabled: false,
                //     }

                //     ,

                //     {
                //         text: "Info",
                //         icon: "mdi-home-circle",
                //         image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                //         class: "inverted",
                //         link: "/info",
                //         id: "btn_info",
                //         disabled: false,
                //     }

                //     ,

                // ];
                var pulsantis = [];
                pulsantis.push(

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "leftbutton",
                        link: "/info",
                        id: "btn_left",
                        disabled: false,
                    }

                );

                pulsantis.push(

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "leftbutton",
                        link: "/info",
                        id: "btn_middle",
                        disabled: false,
                    }

                );


                pulsantis.push(

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icon-ok-smartbook.png",
                        class: "leftbutton",
                        link: "/info",
                        id: "btn_middle2",
                        disabled: false,
                        size: 30,
                    }

                );

                pulsantis.push(

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "leftbutton",
                        link: "/info",
                        id: "btn_right",
                        disabled: false,
                    }

                );

                setTimeout(() => {

                    var element = $('.swiper-button-next').detach();
                    //var element2 = $('.swiper-button-prev').detach();

                    $('#btn_right .v-btn__content').replaceWith(element);
                    //  $('#btn_left .v-btn__content').replaceWith(element2);

                    $('#btn_right').css("visibility", "hidden");



                    var element3 = $('#btn-confirm').detach();

                    $('#btn_middle .v-btn__content').replaceWith(element3);

                    $('#btn_middle').css("visibility", "hidden");



                    var element4 = $('#btn-confirm2').detach();

                    $('#btn_middle2 .v-btn__content').replaceWith(element4);

                    $('#btn_middle2').css("visibility", "hidden");


                    var element5 = $('#btn-back2').detach();

                    $('#btn_left .v-btn__content').replaceWith(element5);




                }, 300);

                //swiper-button-prev

                /*
                                        if (this.su == 'Y' || this.aa == 'Y') {
                                            pulsantis.push({
                                                    text: "Settings",
                                                    icon: "mdi-home-circle",
                                                    image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                                                    class: "inverted",
                                                    link: "/settings",
                                                    id: "btn_settings",
                                                    disabled: false
                                                }

                                            );
                                        } */

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => { }

                    , 100);

            }

                , 100);
        }

        ,

        syncProfile: async function () {

            //Sync profile
            /*  var token = window.$cookies.get("token");
        var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        }

        ,

        btn_add_tms_click() {
            console.log(1);
        }

        ,
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        }

        ,

        reset() {
            this.$refs.form.reset();
        }

        ,
        resetValidation() {
            this.$refs.form.resetValidation();
        }

        ,

        createPdf: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            // valori.currDayTo = window.currDayTo;
            // valori.curStatus = window.curStatus;
            // valori.curPiano = window.curPiano;

            var response = await apibookingrsa.getPdfPrenotazioni("ZmFnb3N0b25p",
                3165,
                window.currDay,
                window.currDayTo,
                window.curStatus,
                window.curPiano,
                ""

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPdfPrenotazioni", res);

                try {

                    console.log(res.data.Result);

                    window.open(res.data.Result, '_blank');

                } catch (error) {
                    console.log(error);
                }

            }

            ).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                }

                );
                console.log("ERrori", "Non è stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        }

    }

    ,
}

    ;
</script>

<style>
.clButtonMiddle2 {}

.clButtonLeft {
    float: left;
}

.wrap_buttons_footer2 {
    min-width: 100vw !important;
    text-align: center !important;
}



.mainbookinggen input[type="radio"] {
    accent-color: #f77002 !important;
}

.clDateValue {
    margin-left: 13px;
    font-weight: bold;
    transform: translateX(0px);
}


.clDescDateValue {
    margin-left: 13px;
    font-weight: bold;
    transform: translateX(0px);
}


.clDateLabel {
    font-weight: bold;
    transform: translateX(736px);
}

.val_select {
    width: 10px;
}


/* #btn_middle {
    transform: translateX(23px) !important;
} */

/* #btn_middle2 {
    transform: translateX(-31px) !important;
} */

/* #btn_left {
    transform: translateX(47px) !important;
} */


.mainbookinggen .spin {
    top: 75% !important;
    z-index: 99999 !important
}


.clTitle {
    min-width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: -211px !important;
    min-height: 100% !important;
}

.clTitle div {
    font-family: 'Playfair Display', Georgia, "Times New Roman", serif !important;
    font-size: 73px !important;
    color: #ffffff !important;
    line-height: 1.2em !important;
}

header {
    background: transparent !important;
}

.mainbookinggen {
    padding-top: 450px;
}

.headerTop {
    background: transparent !important;
    background-color: transparent !important;
}

.swal2-container {
    z-index: 100000;
}


.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.cont_booking_rsa .table-responsive {}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);

}

.frmSearch {
    border: 1px solid #a8d4b1;
    background-color: #c6f7d0;
    margin: 2px 0px;
    padding: 40px;
    border-radius: 4px;
}

#search-box {
    padding: 10px;
    border: #a8d4b1 1px solid;
    border-radius: 4px;
}

#country-list {
    float: left;
    list-style: none;
    margin-top: -3px;
    padding: 0;
    width: 190px;
    position: absolute;
    z-index: 1;
}

#country-list li {
    padding: 10px;
    background: #f0f0f0;
    border-bottom: #bbb9b9 1px solid;
}

#country-list li:hover {
    background: #ece3d2;
    cursor: pointer;
}

.swiper-container {
    max-width: 100vw !important;

    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.swiper-slide {

    overflow-y: auto;
    overflow-x: hidden;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.datatable tbody tr:hover {
    background-color: #f5f5f5;
}

.sorting_1 input[type="checkbox"] {
    top: 0px;
}

.contDate {
    display: flex;
}

.overf {
    overflow: visible !important;
}

.contDate input {
    max-width: 98px !important;
    min-width: auto;
}


.cont_book_pasq2 table thead th {
    border-bottom: transparent !important;
}

.cont_book_pasq2 table td {
    border-top: transparent !important;
    border-bottom: transparent !important;
}


.cont_book_pasq2 .dataTable tbody tr,
.cont_book_pasq2 .dataTable tbody tr td {
    vertical-align: middle !important;
    padding: 6px !important;
}

.contInputs {
    display: flex;
}

.contInputs input,
.p_total_ordinario {
    max-width: 60px;
    min-width: 95px;
    font-weight: 800;
    text-align: right;
}

/*         .table-responsive {
                    min-width: 500px;
                    max-height: 65vh;
                } */
.step {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    list-style: none;
    margin: .2rem 0;
    width: 100%;
}

.step .step-item a {
    color: #5755d9;
    display: inline-block;
    padding: 20px 10px 0;
    text-decoration: none;
}

.con {

    display: flex;
    text-align: center;
}

.buttons {
    display: flex;
    justify-content: center;
}

.bootstrap-datetimepicker-widget {
    /*   top: 20;
              right: 20;
              left:300; */
    z-index: 99999 !important;
    border-radius: 4px;
    right: 400px;
}

.dataTable tbody tr td {
    overflow: visible !important;
}

@media only screen and (max-width: 768px) {

    /*  .swiper-container {
                max-width: 500px;
            } */
    .cont_booking_rsa .table-responsive {
        padding: 0px 26px;
        /* background:yellow;*/
        margin: 0;
    }
}
</style>

<style>
.clButtonMiddle {}

/* #myTableBook .cont_buttons {
    position: relative;
} */

#myTableBook .text {
    padding-left: 4px;
    font-size: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
}

#myTableBook_filter {
    position: relative;
    background-image: inherit !important;
    background: #ee781d;
    border: 1px solid black;
    align-items: center;
    display: flex;
    justify-content: space-between;

}

#myTableBook_filter label {
    display: none;
}

#myTableBook_filter {
    min-height: 46px;
}

.vcentered {
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 79vh;
    justify-content: center;

    background-repeat: no-repeat;
}

[data-status="0"] img {
    cursor: default !important;
}

.active {
    background: #efebeb;
}

.val_statuses2 img {
    max-width: 26px;
    transform: translateX(-5px);
    /* cursor: pointer; */
}

.val_statuses_disable img {
    max-width: 26px;
    transform: translateX(-5px);
}



.swiper-slide {
    width: 100% !important;
}

.gj-picker-md [role=switch],
.gj-picker-md [role=header] {
    background: #f3832d !important;
}

#booking_date_time {
    background-color: white;
    min-width: 100%;
}

.gj-datepicker-md {
    min-width: 100%;
}

.gj-datepicker-md [role=right-icon] {
    top: 4px !important;
}

.form-rows {
    margin-bottom: 24px;
}

#notifiche,
.clsecurity,
#footer-info {
    display: none !important;
}

.smallTitle {
    color: white;
    background: #ec771e;
    color: white;

    min-width: 100%;
    padding-left: 15px;
    margin-bottom: 5px
}

.top-bar {
    z-index: 2;
}

.lang_cont,
#item_supp,
#item_sett {
    display: none !important;
}

/* .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: -7px;
    color: #ec771e;

    zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.8);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    -webkit-transform-origin: 0 0;
    transform: scale(0.8);

    transform-origin: 0 0;

}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: -7px;
    color: #ec771e;

    zoom: 0.8;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.8);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    -webkit-transform-origin: 0 0;
    transform: scale(0.8);

    transform-origin: 0 0;

} */

.swiper-cube-shadow {
    display: none;
}

.mcontent {
    padding-bottom: 0;
}

.page-link {
    display: none !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: -5px;
}

.swal-text input {
    max-width: 46px;
    border: 1px solid #d0c9c9;
    text-align: center;
    font-size: 12px;

}

.swal-button-container {
    cursor: pointer;
}

.swal-title {
    font-size: 16px;
}

.cont_ticketss {
    max-width: 580px;
    /*   max-height: 60vh; */
    margin: 0 auto;
    position: relative;
}

#myTableBook_wrapper {
    max-height: 83vh !important;
}

@media only screen and (min-width: 900px) {

    .grid {
        max-height: 63vh;
    }
}

@media only screen and (max-width: 650px) {

    /*  .cont_booking_rsa    .swiper-container {
        min-width: 100vw;
        transform: translate(-3.8vw, -21px);
        padding: 0;
        margin: 0;

    }

   .cont_booking_rsa  .swiper-wrapper,
   .cont_booking_rsa  .table-responsive {
        padding: 0;
        margin: 0;
        padding: 0 !important;
        margin: 0 !important;
    }

  .cont_booking_rsa   .table-responsive {
        padding: 0px 26px;

        margin: 0;
    } */
}

.numRow {
    padding: 10px;
}

.text {
    padding-left: 4px;
    font-size: 10px;
    position: absolute;
    top: -1px;
    left: 10px;
}
</style>
<style>
.title-table {
    text-align: left;
    padding-left: 10px;
}

.two {
    min-height: 600px;
}

.three {
    min-height: 550px;
}

.four {
    min-height: 550px;
}

.fifth {
    min-height: 95vh;
}

.six {
    min-height: 1100px;
}

.cont_buttons {
    /* display: inline-block; */
    float: left;
    min-width: 10%;
}

.cont_booking_rsa .table-responsive {
    min-height: 65vh;
    overflow-x: hidden;
    padding: 0px 10px;
    box-shadow: none;
    padding-bottom: 150px;
    /* min-width: 70vw; */
}

.sel_banca .select2 {
    min-width: 200px;
    max-width: 200px;
}
</style>
<style>
.cont_action {
    display: none;
}

div.dataTables_wrapper div.dataTables_filter input {
    padding: 0;
    padding-left: 10px;
}

.container .select2-container {
    min-width: 100%;
}

.container .select2-container .select2-selection--single {
    min-width: 90px;
}

.exitall {
    display: none;
}

@media only screen and (max-width: 768px) {
    .cont_booking_rsa .table-responsive {
        /*   max-width: 72vw !important;
                        min-width: 72vw !important; */
        /*padding: 0px 30px;*/
        /*   overflow-x: auto; */
    }

    .right {
        right: -48px;
    }

    .left {
        left: -48px;
    }

    .clDateValue {
        font-weight: bold;
        transform: translateX(0px);

    }

    .clDateLabel {
        font-weight: bold;
        transform: translateX(-102px);
    }

    .wrap_buttons_footer2 {
        display: block !important;
        text-align: center !important;
        min-width: 100vw !important;
    }

    .clButtonMiddle2 {
        transform: translateX(-39px);
    }


    .clButtonMiddle {
        transform: translateX(19px);
    }


}

.cont_book_pasq2 #myTableBook {

    width: auto !important;
    min-width: 400px !important;
    margin: 0 auto !important;
    margin-top: 49px !important;
}

.cont_book_pasq2 #myTableBook_filter {
    left: 0px;
    position: absolute;
    top: 0px;
    max-width: 100%;
    min-width: calc(100% - 0px);
    ;
}

.booking_gen .calendarButton {
    cursor: pointer !important;
    width: 25px !important;
    margin-left: -180px !important;
}

@media screen and (max-width: 768px) {

    .cont_booking_rsa #myTableBook {

        min-width: 90vw !important
    }

    #myTablebook_wrapper {
        box-sizing: border-box;
        overflow-x: auto;
        margin: 0 auto;
    }


    .booking_gen .calendarButton {
        cursor: pointer !important;
        width: 25px !important;
        /* margin-left: -203px !important; */
    }

    .mainbookinggen {
        padding-top: 261px;
    }

    .clTitle {
        min-width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin-left: -211px !important;
        min-height: 100% !important;
    }

    .clTitle div {
        font-family: 'Playfair Display', Georgia, "Times New Roman", serif !important;
        font-size: 50px !important;
        color: #ffffff !important;
        line-height: 1.2em !important;
    }



    #btn_left {
        transform: translateX(10px) !important;
    }


    .cont_book_pasq2 .cont_buttons {
        display: flex !important;
        align-items: center !important;
        min-width: 321px !important;
        position: relative !important;
        justify-content: center !important;
    }


}

#myTableBook {
    max-width: 300px;
}

.cont_booking_rsa #myTableBook_wrapper {
    margin-top: 1px;
}

#myTableBook {
    min-width: 300px;
}

.form-group.centered {
    min-width: 100%;
    text-align: center;
}

.swipeAnagrafica {
    padding-top: 20px !important;

    padding-bottom: 150px !important;
    overflow-y: auto;
    max-height: 100vh;

}

.swipeAnagrafica .form-group label {
    padding-left: 16px;
    margin: 0px;
}

.swipeAnagrafica .form-control {
    font-size: 12px;
}

.contanagrafica {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 16px;
}

.cont_booking_rsa .smallTitle {
    min-width: 100vw;

    min-width: 100%;
    border: 1px solid black;
    max-width: 100%;
    position: absolute;
    top: 13px;
    min-height: 46px;
    left: 12px;

}

.titleAnagrafica {
    font-size: 12px;
    padding-left: 3px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.cont_book_pasq2 .cont_buttons {
    display: flex;
    align-items: center;
    min-width: 321px;
    position: relative;
    justify-content: center;
    margin-left: 15px;;
}


.cont_book_pasq2 .text {
    top: 2px;
}

.daySelected {
    position: absolute;
    font-size: 11px;
    display: flex;
    justify-content: center;
    min-width: 100%;
    top: -18px;
}


.expetion {
    z-index: 3;
    position: fixed;
    /* background: white; */
    min-width: 100%;
    min-height: 45vh;
    display: flex;
    justify-content: center;
    font-size: 14px;
    /*  background: red; */
    margin-top: 7px;
    left: 0px;
    padding-top: 10px;
    align-items: flex-end;
}

.wrapexpetion {
    text-align: center;
}

.staffpasq {
    font-size: 12px;
}
</style>
