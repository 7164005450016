import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIUsers {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async getAziende(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("getAziende"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getPrefix()
  {

    return axios.get(this.getUrlDomain("getPrefix"), { 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getUtenti(user, company)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("company",  company);

    return axios.post(this.getUrlDomain("getUtenti"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async createUtenteApi(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("createUserApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getUtente(id, user)
  {

    return axios.get(this.getUrlDomain("getUserApi") + "/" + id + "?api=1&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  // async deleteUtente(id, user)
  // {


  //   return axios.get(this.getUrlDomain("delUserApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }

  async deleteUtente(id, user)
  {


    return axios.get(this.getUrlDomain("delUserApi") + "?id=" + id + "&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




  async insertUtente(utente, gruppi, user)
  {

    let formData = new FormData();

    console.log("UTENTE 4", utente);

    console.log(user);


    var selected_model = "";

    for (var i = 0; i < gruppi.length; i++)
    {

        if ((gruppi[i] !== undefined) && (gruppi[i].checked !== undefined))
        {

            if (gruppi[i].checked != null)
            {
                
                if (selected_model != "")
                {
                  selected_model = selected_model + ",";
                }
                selected_model = selected_model + gruppi[i].checked;

            }

        }

    }

    formData.append("username",  user);
    formData.append("selected_model",  selected_model);

    for ( var key in utente ) {
      formData.append(key, utente[key]);
   }


    return axios.post(this.getUrlDomain("insUserApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateUtente(utente, gruppi, user, id)
  {

    let formData = new FormData();

    var selected_model = "";

    for (var i = 0; i < gruppi.length; i++)
    {

        if ((gruppi[i] !== undefined) && (gruppi[i].checked !== undefined))
        {

            if (gruppi[i].checked != null)
            {
                
                if (selected_model != "")
                {
                  selected_model = selected_model + ",";
                }
                selected_model = selected_model + gruppi[i].checked;

            }

        }

    }

    formData.append("username",  user);
    formData.append("selected_model",  selected_model);

    for ( var key in utente ) {
      formData.append(key, utente[key]);
   }

    return axios.post(this.getUrlDomain("updUserApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }





  async registerLoginNew(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("registerLogin"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async checkLogoutNew(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("checkLogoutNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async registerLogoutNew(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("registerLogout"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getLastLoginNew(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("getLastLogin"), formData, { 'Content-Type': 'multipart/form-data' });

  }











}

export default new APIUsers({
  url: "http://emtool.local/public/api/smartbook/"
})
