<template>
<v-container class="w-container v-overflow editParente" style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

    <v-row>

        <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

            <div class="mainTitle" style="color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#de7d37">{{ titolo }}</div>


        </v-col>

    </v-row>

    <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

        <v-row>

            <v-col cols="12" md="12">

                <v-select v-model="conf_orario.giorno" :items="items" label="Giorno" :rules="giornoRules"></v-select>

            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">

                <div style="font-size:13px">Ora</div>
                <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55" style="border-bottom: 1px solid #ced4da;padding: 4px" id="dalle" name="dalle" class='hourbooking'  cancel-Text="" done-Text=""  display-format="HH:mm"></ion-datetime>


               
            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">

                <v-textarea v-model="conf_orario.description" label="Descrizione"></v-textarea>

            </v-col>

        </v-row>

        <v-col cols="12" md="12">

            <v-checkbox label="Abilitato" v-model="conf_orario.enabled" true-value="S" false-value="N"></v-checkbox>

        </v-col>



    </v-form>

</v-container>
</template>

<script>

import apiconfigurazioneorari from "../utils/configurazioneorari/apiconfigurazioneorari";

import {
    bus
} from "../main";

import router from ".././router";


export default ({
    mounted() {

        /*eslint-disable no-undef*/

      


        console.log("PARAMS: ", this.$route.params.id);

        console.log("GEN SETTINGS: ", window.$cookies.get("gen_settings").nuclei);

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        if (this.$route.params.id > 0) {

            setTimeout(() => {
                this.syncConfOrario();
            }, 100);

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Configurazione Orario";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/confOrari"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    computed: { 


        mailMinutesFromTime() {

            var strTime = "";

            if (this.conf_orario.dalle != null)
            {

                strTime = new String(this.conf_orario.dalle);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);
 
            }

            return strTime;

        },




    },

    data: () => ({
        titolo: "Aggiungi Configurazione Orario",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        singleMode: false,

        timeStart: null,

        selNuclei: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        conf_orario: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        giornoRules: [
            (v) => !!v || "Giorno richiesto",
        ],

        oraRules: [
            (v) => !!v || "Ora richiesta",
        ],

        // nucleiRules

        select: null,
        items: ['lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato', 'domenica'],
        checkbox: false,
    }),

    methods: {

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon-save-smartbook.png",
                        size: 30,
                        title: "Salva"
                    },
                    {
                        text: "Parenti",
                        icon: "mdi-arrow-left",
                        link: "/parenti",
                        id: "btn_back",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                        size: 35,
                        title: "Indietro"

                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var that = this;


            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

               // console.log("DALLE: ", $("#dalle").val());

                var errore = "";

                if ($("#dalle").val() == "")
                {

                    errore = "Specificare l'ora";

                }
                else
                {



                    var str_time = $("#dalle").val().toString();

                    if (str_time.length > 8)
                    {
                        str_time = str_time.substr(11, 5);
                    }
                    else
                    {
                        str_time = str_time.substr(0, 5);
                    }

             

                    that.conf_orario.dalle = str_time;

                }

                console.log("CONF ORARIO: ", that.conf_orario);

                if (errore == "") {

                   
                   that.saveData();

                } else {

                    that.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }

                // console.log("DALLE: ", $("#dalle").val());

                // console.log("CONF ORARIO: ", that.conf_orario);

                // //that.saveData();


           
            }
        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");

            var v_tipologia = window.$cookies.get("sel_tip_pren_conf_orari");

            var that = this;

            console.log("ID: " + that.$route.params.id);

            var response = null;

            if (that.$route.params.id == 0) {

                
                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiconfigurazioneorari.insertConfOrario(
                    that.conf_orario,
                    v_tipologia,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertConfOrario", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/confOrari"
                        });

                    }, 200);

                }).catch(err => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err);
                        var msg = err.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("ERrori", "Non è stato possibile salvare i dati");
                        console.log("response", response);

                    }

                );

            }

            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiconfigurazioneorari.updateConfOrario(
                    that.conf_orario,
                    v_tipologia,
                    v_token,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateConfOrario", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/confOrari"
                        });

                    }, 200);

                }).catch(err => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err);
                        var msg = err.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("ERrori", "Non è stato possibile salvare i dati");
                        console.log("response", response);

                    }

                );

            }

        },

        syncConfOrario: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfigurazioneorari.getConfOrario(
                that.$route.params.id

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getConfOrario", res);

                try {

                    this.conf_orario = res.data.config_orario;


                    $("#dalle").val(res.data.config_orario.dalle);


                    //console.log("ok");

                    //this.medico = res.dasLEO 1 A', 'NUCLEO 3 A'];

                    //this.selNuclei = arra_piani;

                    //$("#nuclei").val(arra_piani);

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        },

        updateSumDay: async function () {

        },

        sumHours: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");

                var disabled = event.target.attributes.getNamedItem('disabled');

                /*       console.log("PO", this.piano_orario);
                      console.log("curDay", this.curDay); */

                //  var strDay = new Date(this.curYear, this.curMonth - 1, this.curDay);
                var strDay = "";

                var lista_orari = this.$root.$children[0].lista_orari;
                console.log("lista_orari", lista_orari, dataid, strDay, operation);

                if (disabled == null) {

                    var newIndex = 0;

                    for (var ind in lista_orari) {

                        if (dataid == 'startHour' && operation == '+') {
                            if (lista_orari[ind] == this.timeStart) {

                                newIndex = parseInt(ind) + 1;
                            }

                        }

                        if (dataid == 'startHour' && operation == '-') {
                            if (lista_orari[ind] == this.timeStart) {

                                newIndex = parseInt(ind) - 1;
                            }

                        }

                        if (dataid == 'endHour' && operation == '+') {
                            if (lista_orari[ind] == this.timeEnd) {

                                newIndex = parseInt(ind) + 1;
                            }

                        }

                        if (dataid == 'endHour' && operation == '-') {
                            if (lista_orari[ind] == this.timeEnd) {

                                newIndex = parseInt(ind) - 1;
                            }

                        }

                    }

                    if (dataid == 'endHour') this.timeEnd = lista_orari[newIndex.toString()];
                    if (dataid == 'startHour') this.timeStart = lista_orari[newIndex.toString()];

                    console.log("NewIndex", newIndex);

                }
                //console.log ("dw", strDay.getDay() );
                /*   var mapDay = {
                      "0": "domenica",
                      "1": "lunedi",
                      "2": "martedi",
                      "3": "mercoledi",
                      "4": "giovedi",
                      "5": "venerdi",
                      "6": "sabato"
                  }; */

                /*                 var po = this.piano_orario[0];
                                var dayLiteral = mapDay[strDay.getDay()];

                                var oretoday = parseFloat(po[dayLiteral]);

                                var max = 10;
                                max = oretoday;
                                var index = 0;
                                var newQuant;
                                if (dataid == 'Ordinari') index = 0;
                                if (dataid == 'Straordinari') index = 1;
                                if (dataid == 'Assenza') index = 2; */

                //   console.log("quantita", this.forecast[index].quantity);

                if (disabled == null) {

                    /*           if (operation == '+')
                                  newQuant = parseFloat(this.forecast[index].quantity) + 0.5;
                              else
                                  newQuant = parseFloat(this.forecast[index].quantity) - 0.5;

                              if (newQuant <= max && operation == '+') {
                                  this.forecast[index].quantity = newQuant.toString();

                              }

                              if (newQuant >= 0 && operation == '-') {
                                  this.forecast[index].quantity = newQuant.toString();

                              } */

                    /*    var diff = 0;
                       //adapta absence with ordinary , user cliecked ordinari so check if more than usual
                       if (dataid == 'Ordinari') {
                           if (newQuant <= oretoday) {
                               diff = oretoday - newQuant;
                               console.log("Diff:", diff);
                               //needed to set absense
                               if (diff > oretoday) diff = oretoday;
                               this.forecast[2].quantity = diff.toString();
                           }
                       } */

                    /*    if (dataid == 'Assenza') {
                           if (newQuant <= oretoday) {
                               diff = oretoday - newQuant;
                               console.log("Diff:", diff);
                               //needed to set absense
                               if (diff > oretoday) diff = oretoday;
                               this.forecast[0].quantity = diff.toString();
                           }
                       } */

                    // this.updateSumDay();
                }

            }

        },

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editParente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editParente .v-input {
    font-size: 1.2em;
}

.editParente .v-select {
    font-size: 1.2em;
}

.editParente .v-label {
    font-size: 1em;
}

.conSelect {
    display: flex;

}

.selectHour {
    padding-left: 25px;
    font-size: 12px !important;
}

.addbuttons {
    display: flex;
    margin-left: 5px;
    align-items: center;
    font-size: 20px;
    padding-bottom: 12px;

    /* border: 1px solid; */
}

.minus {
    background: #f5cf2e;
    border: 1px solid;
    padding: 0px 4px;
    cursor: pointer;
    max-height: 24px;
    min-width: 24px;
    text-align: center;

}

.plus {
    background: #f5cf2e;
    border: 1px solid;
    padding: 0px 4px;
    cursor: pointer;
    max-height: 24px;
    min-width: 24px;
    text-align: center;
    margin-right: 6px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
