<template>
    <v-container class="w-container-users v-overflow editUtenteSb"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:700px;" v-model="valid" ref="form" lazy-validation>

            <div class="tabs">
                <v-tabs v-model="tab" centered next-icon="mdi-arrow-right-bold-box-outline"
                    prev-icon="mdi-arrow-left-bold-box-outline" show-arrows style="max-width:100vw;font-size:10px;">

                    <!-- s -->
                    <v-tabs-slider color="#ee781d"></v-tabs-slider>
                    <v-tab v-for="item in items" :key="item">
                        {{ item }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">

                    <v-tab-item key="Informazioni">

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.name" label="Nome" required :rules="nameRules">
                                </v-text-field>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.login" label="Username" required :rules="usernameRules">
                                </v-text-field>

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                                <v-select v-model="utente.idcompany_logo" :items="aziende" label="Azienda"
                                    item-text="fld_name" item-value="companyid" required :rules="aziendaRules">
                                </v-select>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.email" label="E-Mail" required :rules="emailRules">
                                </v-text-field>

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                                <v-select v-model="utente.prefix_mobile" :items="prefissi" label="Prefisso"
                                    item-text="prefix" item-value="prefix"></v-select>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.mobile" label="Telefono"></v-text-field>

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.codice_fiscale" label="Codice Fiscale"></v-text-field>

                            </v-col>

                            <v-col v-if="enableChangePassword" cols="12" md="6" xs="6" style="display: flex;
    align-items: center;">
                                <router-link :to="'/changepassword/' + this.$route.params.id">
                                    <img style="max-width:24px;"
                                        src="https://app.emtool.eu/_lib/img/usr__NM__bg__NM__new_mnu_change_password.png"
                                        title="Cambia Password" />
                                </router-link>
                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                            </v-col>

                        </v-row>

                    </v-tab-item>

                    <v-tab-item key="Gruppo">

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                                <v-list-tile v-for="(gruppo, index) in gruppi" :key="gruppo.id">

                                    <v-list-tile-content>
                                        <v-checkbox :value="gruppo.id" :key="gruppo.id" :label="gruppo.name"
                                            v-model="gruppi[index].checked">
                                        </v-checkbox>
                                    </v-list-tile-content>

                                </v-list-tile>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                            </v-col>

                        </v-row>

                    </v-tab-item>

                    <!-- <v-tab-item key="Autenticazione">

                    <v-row>

                        <v-col cols="12" md="6" xs="6">

                            <v-checkbox label="Utente E Password" v-model="utente.auth_user_pwd" true-value="S" false-value="N"></v-checkbox>

                        </v-col>

                        <v-col cols="12" md="6" xs="6">

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="6" xs="6">

                            <v-checkbox label="Mail E Password" v-model="utente.auth_email_pwd" true-value="S" false-value="N"></v-checkbox>

                        </v-col>

                        <v-col cols="12" md="6" xs="6">

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="6" xs="6">

                            <v-checkbox label="Face ID" v-model="utente.auth_faceid" true-value="S" false-value="N"></v-checkbox>

                        </v-col>

                        <v-col cols="12" md="6" xs="6">

                        </v-col>

                    </v-row>

                </v-tab-item>

                <v-tab-item key="QR Code">

                    <v-row>

                        <v-col cols="12" md="6" xs="6">

                            <v-select v-model="utente.type_qrcode" :items="tipo_qrcode" label="Tipo" item-text="value" item-value="id"></v-select>

                        </v-col>

                        <v-col cols="12" md="6" xs="6">

                        </v-col>

                    </v-row>

                </v-tab-item> -->

                </v-tabs-items>

            </div>

        </v-form>

    </v-container>
</template>

<script>
//import apiparenti from "../utils/profile/apiparenti";

//import apimedico from "../utils/medico/apimedico";
import apiusers from "../utils/users/apiusers";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


        window.$cookies.get("aziende_user")

        this.setupButtons();

        if (this.$route.params.id == 0) {

            setTimeout(() => {

                this.syncAziende();

            }, 100);

        }

        if (this.$route.params.id > 0) {


            this.enableChangePassword = true;

            setTimeout(() => {
                this.syncUtente();
            }, 100);

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Utente";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/users"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    data: () => ({

        enableChangePassword: false,

        titolo: "Aggiungi Utente",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-save-smartbook.png",
                    size: 30,
                    title: "Salva"
                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                    size: 35,
                    title: "Indietro"

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("UTENTE 1: ", that.utente);

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                console.log("GRUPPI: ", that.gruppi);

                var grpChecked = 0;

                for (var i = 0; i < that.gruppi.length; i++) {

                    if ((that.gruppi[i] !== undefined) && (that.gruppi[i].checked !== undefined)) {

                        if (that.gruppi[i].checked != null) {
                            grpChecked = grpChecked + 1;
                        }

                    }

                }

                console.log("GTP CHCK: ", grpChecked);

                if (grpChecked == 0) {

                    that.$swal({
                        icon: "error",
                        text: "Per favore specificare i gruppi",
                        showConfirmButton: false,
                        timer: 2000
                    });

                } else {

                    that.saveData();

                }

            }
        },

        saveData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("UTENTE 2: " + that.utente);

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiusers.insertUtente(
                    that.utente,
                    that.gruppi,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertUtente", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/users"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiusers.updateUtente(
                    that.utente,
                    that.gruppi,
                    v_token,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateUtente", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/users"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    var grp_selected = res.data.curr_user.models.split(",");

                    console.log("GRP SEL: ", grp_selected);

                    if (grp_selected.length > 0) {

                        for (var i = 0; i < this.gruppi.length; i++) {

                            if (this.gruppi[i] !== undefined) {

                                for (var x = 0; x < grp_selected.length; x++) {

                                    console.log("GRP SEL 2: ", grp_selected[x]);

                                    if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                        this.gruppi[i].checked = this.gruppi[i].id;

                                    }

                                }

                            }

                        }

                    }

                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteApi", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.editUtenteSb .mainTitle {
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 100vw;
    border-color: #de7d37 !important;
    top: 64px
}




.w-container-users {
    width: 700px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editUtente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editUtente .v-input {
    font-size: 1.2em;
}

.editUtente .v-select {
    font-size: 1.2em;
}

.editUtente .v-label {
    font-size: 1em;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }


    .editUtenteSb .mainTitle {
        background-color: white;
        color: black;
        font-weight: bold;
        padding: 4px;
        font-size: 10px;
        min-width: 100vw;
        border-color: #de7d37 !important;
        top: 56px
    }


}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
