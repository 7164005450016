<template>

    <v-container class="w-container v-overflow editParente" style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#de7d37">{{ titolo }}</div>

            </v-col>

        </v-row>


    <v-form   style="max-width:500px;" v-model="valid" ref="form" lazy-validation>


        <v-row>

            <v-col cols="12" md="12">

                <v-text-field v-model="medico.cognome" label="Cognome" :rules="surnameRules" required></v-text-field>

            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">

                <v-text-field v-model="medico.nome" label="Nome" :rules="nameRules" required></v-text-field>

            </v-col>

        </v-row>
    
        <v-row>

            <v-col cols="12" md="12">

                <v-text-field v-model="medico.descrizione" label="Descrizione"></v-text-field>

            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">

           

                <ion-item style="max-width:260px;">

                    <ion-label style="font-size:1.2em;" position="floating">Nuclei Associati</ion-label>

                    <ion-select id="nuclei" name="nuclei" multiple="true" value="selNuclei">

                        <ion-select-option v-for="item in items" :key="item.nome" :value='item.nome'>{{ item.nome }} </ion-select-option>


                    </ion-select>    
                    
                </ion-item>    



            </v-col>

        </v-row>



    </v-form>

</v-container>
</template>

<script>
//import apiparenti from "../utils/profile/apiparenti";

import apimedico from "../utils/medico/apimedico";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


        console.log("PARAMS: ", this.$route.params.id);

        console.log("GEN SETTINGS: ", window.$cookies.get("gen_settings").nuclei);

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        if (this.$route.params.id > 0) {

            setTimeout(() => {
                this.syncMedico();
            }, 100);

        }


                if (this.$route.params.id > 0) {

            this.titolo = "Modifica Medico";



        }


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    data: () => ({
        titolo: "Aggiungi Medico",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selNuclei: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

 
        // nucleiRules

        select: null,
        items: window.$cookies.get("gen_settings").nuclei,
        checkbox: false,
    }),

    methods: {

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon-save-smartbook.png",
                        size: 30,
                        title: "Salva"
                    },
                    {
                        text: "Parenti",
                        icon: "mdi-arrow-left",
                        link: "/parenti",
                        id: "btn_back",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                        size: 35,
                        title: "Indietro"

                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var that = this;

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {


                //var errore = "";


                if ($("#nuclei").val().length == 0)
                {

                    that.$swal({
                        icon: "error",
                        text: "Per favore specificare i nuclei",
                        showConfirmButton: false,
                        timer: 2000
                    });

                }
                else
                {


                    that.saveData();

                }



               // console.log("NUCLEI: ", $("#nuclei").val());




                // if (that.$route.params.id == 0) {

                //     that.saveData();

                // }

                // if (that.$route.params.id > 0) {



                //     that.$swal
                //         .fire({
                //             title: "ATTENZIONE",
                //             html: "I dati del parente verranno aggiornati, anche le relative prenotazioni presenti verranno aggiornate",
                //             icon: "warning",
                //             showCancelButton: false,
                //             confirmButtonColor: "#3085d6",
                //             cancelButtonColor: "#d33",
                //             confirmButtonText: "Ok",
                //         })
                //         .then(async function (result) {

                //             console.log("RES: ", result);

                //             if (result.value == true) {

                //                 that.saveData();

                //             }

                //         });

                // }

            }
        },

        saveData: async function () {

            var that = this;

            console.log("ID: " + that.$route.params.id);

            var response = null;

            if (that.$route.params.id == 0)
            {


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apimedico.insertMedico(
                    that.medico,
                    $("#nuclei").val()
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertMedico", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/medici"
                        });

                    }, 200);

                }).catch(err => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err);
                        var msg = err.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("ERrori", "Non è stato possibile salvare i dati");
                        console.log("response", response);

                    }

                );





            }

            if (that.$route.params.id > 0)
            {


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apimedico.updateMedico(
                    that.medico,
                    $("#nuclei").val(),
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateMedico", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/medici"
                        });

                    }, 200);

                }).catch(err => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err);
                        var msg = err.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("ERrori", "Non è stato possibile salvare i dati");
                        console.log("response", response);

                    }

                );
                
            }

         

        },

        syncMedico: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimedico.getMedico(
                that.$route.params.id

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getMedico", res);

                try {

                    this.medico = res.data.medico;

                    console.log("NUCLEI: ", res.data.medico.settori_piani, this.items);

                    //this.selNuclei = res.data.medico.settori_piani;

                    var arra_piani = res.data.medico.settori_piani.split(",");

                   // var array = ['NUCLEO 1 A', 'NUCLEO 3 A'];

                   //this.selNuclei = arra_piani;

                    $("#nuclei").val(arra_piani);

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        }

    }

})
</script>

<style>

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editParente .v-form {
    margin-top: 41px;
    padding-left: 10px; 
    padding-right: 10px;
}

.editParente .v-input {
    font-size: 1.2em;
}

.editParente .v-select {
    font-size: 1.2em;
}


.editParente .v-label {
    font-size: 1em;
}


@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

</style>
