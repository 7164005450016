import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIMedico {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async insertMedico(medico, nuclei)
  {

    let formData = new FormData();

    formData.append("nuclei_selezionati",  nuclei);

    for ( var key in medico ) {
       formData.append(key, medico[key]);
    }

    return axios.post(this.getUrlDomain("insMedicoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getMedico(id)
  {


    //let formData = new FormData();

    //formData.append("api",  1);


    return axios.get(this.getUrlDomain("getMedicoApi") + "/" + id + "?api=1",{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateMedico(medico, nuclei, id)
  {

    let formData = new FormData();

    formData.append("nuclei_selezionati",  nuclei);

    for ( var key in medico ) {
       formData.append(key, medico[key]);
    }

    return axios.post(this.getUrlDomain("updMedicoApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteMedico(id)
  {


    return axios.get(this.getUrlDomain("delMedicoApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




  async getNucleiMediciApi(id_medico)
  {

    let formData = new FormData();

    formData.append("id_medico",  id_medico);

    return axios.post(this.getUrlDomain("getNucleiMediciApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getOspitiApi(id_nucleo, id_medico)
  {

    let formData = new FormData();

    formData.append("id_medico",  id_medico);
    formData.append("id_nucleo",  id_nucleo);

    return axios.post(this.getUrlDomain("getOspitiApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updAssocGuest(v_sel, id_guest, id_nucleo, id_medico)
  {

    let formData = new FormData();

    formData.append("v_sel",  v_sel);
    formData.append("v_id",  id_guest);

    formData.append("v_id_nucleo",  id_nucleo);
    formData.append("v_id_medico",  id_medico);

    return axios.post(this.getUrlDomain("updOspitiAssociatiApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getInfoMedico(id_medico)
  {

    let formData = new FormData();

    formData.append("v_id_medico",  id_medico);

    return axios.post(this.getUrlDomain("getInfoMedicoApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updAssocGuestAll(v_sel, id_nucleo, id_medico)
  {

    let formData = new FormData();

    formData.append("v_sel",  v_sel);

    formData.append("v_id_nucleo",  id_nucleo);
    formData.append("v_id_medico",  id_medico);

    return axios.post(this.getUrlDomain("updAllOspitiAssociatiApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APIMedico({
  url: "http://emtool.local/public/api/smartbook/"
})
