<template>
    <v-container class="w-container " id="cont_assoc_osp_sel_nucleo" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle">{{ titolo }}<div style="margin-left:24px">{{ nome_medico }}</div>
                </div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_id_nucleo" style="cursor:pointer">

                        <ion-item style="min-width:200px;transform: translateX(-6px);">
                            <ion-label style="font-size:12px;color:gray!important;opacity:1" position="floating"> Nucleo
                            </ion-label>
                            <ion-select id="nucleo" name="nucleo" class='nucleo' cancel-Text="Chiudi" done-Text=""
                                :value="nucleoSelected" interface="action-sheet" disabled readonly>
                                <ion-select-option v-for="item in nuclei" :key="item.id" :value='item.nome'> {{
                                        item.nome
                                }}</ion-select-option>
                            </ion-select>
                        </ion-item>

                    </div>

                </v-col>

            </v-row>




        </v-form>


        <div class="contnuclei">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetNuclei" class="vnucleisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetNuclei" fixed style="position:fixed;">
                                Nuclei {{ sheetNuclei }}
                                <v-btn class='closeSheet' icon @click="sheetNuclei = !sheetNuclei">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchNuclei"></v-text-field>

                                </v-toolbar>

                            </div>


                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredNuclei" :key="item.id"
                                        @click="manageClickNuclei(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemNucleo'>{{ item.nome }}</v-list-item-title>
                                        <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline
                                        </v-icon>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


    </v-container>
</template>

<script>

import apimedico from "../utils/medico/apimedico";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        var pointerVue = this;

        console.log("PARAMS: ", this.$route.params.id);

        this.cur_id = this.$route.params.id;

        this.setupButtons(false);

        this.titolo = "Associa Ospiti";



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });


        $('body').on('click', '#cont_id_nucleo', function (event) {
            console.log(event);

            pointerVue.sheetNuclei = true;

        });


        setTimeout(() => {
            this.syncNuclei();

            this.syncInfoMedico();
        }, 100);





    },

    computed: {

        filteredNuclei() {

            return _.orderBy(this.nuclei.filter(item => {
                if (!this.searchNuclei) return this.nuclei;
                return (item.nome.toLowerCase().includes(this.searchNuclei.toLowerCase()));
            }), 'headline')
        },



    },

    data: () => ({

        nucleoSelected: null,

        sheetNuclei: false,
        searchNuclei: "",

        nuclei: [],

        currentFile: null,
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Attività",
        nome_medico: "",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        attivita: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        select: null,
        checkbox: false,

        // argomenti: JSON.parse(localStorage.getItem('info_global')).todo_utenti,


    }),

    methods: {

        manageClickNuclei: async function (nucleo) {

            console.log("TIPO SEL: ", nucleo);

            this.sheetNuclei = false;

            this.nucleoSelected = nucleo.id;

            console.log("N SEL: ", this.nucleoSelected);

            this.setupButtons(true);

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },


        setupButtonsOld: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/icon-ok-smartbook.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    size: 30,
                    title: "Conferma"
                },

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                    size: 35,
                    title: "Indietro"
                },


                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },



        setupButtons: async function (v_show) {





            setTimeout(() => {

                var pulsantis = [];

                if (v_show) {

                    pulsantis.push({
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/icon-ok-smartbook.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        size: 30,
                        title: "Conferma"
                    });


                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                    size: 35,
                    title: "Indietro"
                });


                this.$root.$children[0].bnavbuttons = pulsantis;

                // var pulsantis = [{
                //     text: "Conferma",
                //     icon: "mdi-content-save",
                //     image: "https://app.emtool.eu/public/_lib/img/icon-ok-smartbook.png",
                //     link: "/timesheet",
                //     id: "btn_confirm",
                //     disabled: false,
                //     size: 30,
                //     title: "Conferma"
                // },

                // {
                //     text: "Indietro",
                //     icon: "mdi-arrow-left",
                //     link: "/confOrariChoice",
                //     id: "btn_back",
                //     disabled: false,
                //     image: "https://app.emtool.eu/public/_lib/img/icon-back-smartbook.png",
                //     size: 35,
                //     title: "Indietro"
                // },


                // ];

                // this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },




        validate() {

            var that = this;

            console.log("USER SEL: ", that.nucleoSelected);

            if (that.nucleoSelected == null) {

                that.$swal({
                    icon: "error",
                    text: "Per favore selezionare il nucleo per l'associazione",
                    showConfirmButton: false,
                    timer: 2000
                });


            }
            else {

                window.$cookies.set("assoc_osp_sel_nucleo", that.nucleoSelected, "9y");

                // router.push({
                //     path: "/pianificazione"
                // });

                that.syncOspiti();


            }



        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");

            console.log(v_token);

            // var that = this;

            // var response = null;

            // if (that.$route.params.id == 0)
            // {

            //     that.showSpinner = true;
            //     that.$root.$children[0].showProgress = true;

            //     response = await apiattivita.insertAttivita(
            //         that.attivita,
            //         v_token,              
            //     ).then((res) => {

            //         that.$root.$children[0].showProgress = false;
            //         console.log("res from insertAttivita", res);

            //         that.$swal({
            //             icon: "success",
            //             text: "Dati salvati correttamente",
            //             showConfirmButton: false,
            //             timer: 2000
            //         });



            //         setTimeout(() => {

            //             that.dialogMsg = false;
            //             that.setupButtons();

            //             router.push({
            //                 path: "/attivita"
            //             });

            //         }, 200);







            //     }).catch(err => {
            //             that.$root.$children[0].showProgress = false;
            //             console.log(err);
            //             var msg = err.response.data.Error;

            //             that.$swal({
            //                 icon: "error",
            //                 text: msg,
            //                 showConfirmButton: false,
            //                 timer: 8000
            //             });
            //             console.log("ERrori", "Non è stato possibile salvare i dati");
            //             console.log("response", response);

            //         }

            //     );


            // }

            // if (that.$route.params.id > 0)
            // {


            //     that.showSpinner = true;
            //     that.$root.$children[0].showProgress = true;

            //     response = await apiattivita.updateAttivita(
            //         that.attivita,
            //         that.$route.params.id,              
            //     ).then((res) => {

            //         that.$root.$children[0].showProgress = false;
            //         console.log("res from updateAttivita", res);

            //         that.$swal({
            //             icon: "success",
            //             text: "Dati salvati correttamente",
            //             showConfirmButton: false,
            //             timer: 2000
            //         });



            //         setTimeout(() => {

            //             that.dialogMsg = false;
            //             that.setupButtons();

            //             router.push({
            //                 path: "/attivita"
            //             });

            //         }, 200);







            //     }).catch(err => {
            //             that.$root.$children[0].showProgress = false;
            //             console.log(err);
            //             var msg = err.response.data.Error;

            //             that.$swal({
            //                 icon: "error",
            //                 text: msg,
            //                 showConfirmButton: false,
            //                 timer: 8000
            //             });
            //             console.log("ERrori", "Non è stato possibile salvare i dati");
            //             console.log("response", response);

            //         }

            //     );





            // }




        },

        syncNuclei: async function () {

            var v_id_medico = window.$cookies.get("assoc_osp_sel_doctor");

            console.log("ID MED: ", v_id_medico);

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimedico.getNucleiMediciApi(
                v_id_medico
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getNucleiMediciApi", res);

                try {

                    console.log("ok");

                    this.nuclei = res.data.nuclei;


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncInfoMedico: async function () {

            var v_id_medico = window.$cookies.get("assoc_osp_sel_doctor");

            console.log("ID MED: ", v_id_medico);

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimedico.getInfoMedico(
                v_id_medico
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoMedico", res);

                try {


                    window.$cookies.set("sele_doctor_name", res.data.medico.cognome, "9y");

                    this.nome_medico = "Medico: " + res.data.medico.cognome;


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncOspiti: async function () {

            var v_id_nucleo = window.$cookies.get("assoc_osp_sel_nucleo");
            var v_id_medico = window.$cookies.get("assoc_osp_sel_doctor");

            console.log("ID NUCLEO: ", v_id_nucleo);
            console.log("ID MEDICO: ", v_id_medico);

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimedico.getOspitiApi(
                v_id_nucleo,
                v_id_medico
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getOspitiApi", res);

                try {

                    console.log("ok");

                    router.push({
                        path: "/assocOspitiAssocia"
                    });



                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }






    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

#cont_assoc_osp_sel_nucleo .mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
    display: flex;
    color: black;
    background-color: white;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 100vw;
    border-color: #de7d37;
    top: 64px
}

.headerSheetNuclei {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}


.itemNucleo {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}



@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }


    #cont_assoc_osp_sel_nucleo .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 500px;
        z-index: 1;
        top: 67px;
        border: 1px solid black;
        display: flex;
        color: black;
        background-color: white;
        font-weight: bold;
        padding: 4px;
        font-size: 10px;
        min-width: 100vw;
        border-color: #de7d37;
        top: 56px
    }


}
</style>
